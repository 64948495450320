import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalPanel from "./index";
import { useDispatch, useSelector } from "react-redux";
import { modalKey, showForgotUsernameModal } from "../../redux/counterSlice";
import { login } from "../../redux/loginSlice";
import { showForgotPasswordModal } from "../../redux/counterSlice"; 
import Verify from '../../assets/images/images/logo/verify_account.svg'
import swal from "sweetalert";
import { toast } from "react-toastify";
import axios from "axios";
import Cookies from "js-cookie";

export function LoginModal({ setShow }) {
  const initialValues = {
    username: "",
    password: "",
    remember: false,
  };
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialValues);
  const [formError, setFormError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [verifyAccount, setVerifyAccount]= useState(0) 
  const [currentUserId, setCurrentUserId]=useState(null)
  const [otpInput, setOtpInput]=useState(null)
  



  useEffect(() => {
    const savedUsername = localStorage.getItem('username');
    const savedPassword = localStorage.getItem('password');
    const savedRememberMe = localStorage.getItem('rememberMe') === 'true';

    if (savedRememberMe) {
      setFormData({username: savedUsername,password: savedPassword,remember: savedRememberMe})
    }
  }, []);

  const onBlurHandler = (event) => {
    const fieldName = event.target.name;
    const fieldValue = formData[fieldName];
    let err = { ...formError };
    if (fieldValue === "") {
      err[fieldName] = "Required!";
    } else if (fieldName === "username") {
      if (fieldValue.length < 6) {
        err[fieldName] = "Username should contain atleast 6 character";
      }
    } else {
      delete err[fieldName];
    }
    setFormError({ ...err });
  };
  const handleOnchange = (e) => {
    const fieldName = e.target.name;
    const fielValue = e.target.value;
    let err = { ...formError };
    delete err[fieldName];

    if ((fieldName === "username" || fieldName === "password") && fielValue.includes(" ")) {
      return false;
  }

    if (fieldName === "password" && fielValue.length > 16) {
      return;
    }
    if (fieldName === "username" && fielValue.includes("@")) {
      return false;
    }
    setFormData((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));
    setFormError({ ...err });
  };
  const validateForm = () => {
    let err = {};
    if (formData.username === "") {
      err.username = "Username required!";
    }
    if (formData.password === "") {
      err.password = "Password required!";
    }

    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = validateForm();
    const userDetail = {
      username: formData.username,
      password: formData.password,
    };
    if (isValid) {
      // dispatch(login(userDetail));
      hanleLoginRequest()

      if (formData.remember) {
        localStorage.setItem('username', formData.username);
        localStorage.setItem('password', formData.password);
        localStorage.setItem('rememberMe', formData.remember);
      } else {
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        localStorage.removeItem('rememberMe');
      }
    }
  };

  const handleShowpassword = () => {
    setShowPassword(!showPassword);
  };

  const hanldeResendCode = async (id) => { 
    
    try { 
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/user/resend-otp`,
      { userId: id }
    );
    if(response?.status===200){
      toast.success("OTP sent") 
      setVerifyAccount(1)
    }
    } catch (error) {
      let message = error?.response?.data?.errors[0]?.description.split("_");
      let updatedMessage = message.join(" ");
      toast.error(updatedMessage);
    }
  };
  
   
  const hanleLoginRequest = async()=>{ 
    const userDetails = {
      username: formData.username,
      password: formData.password,
    }; 
      try {
        const API_URL =  `${process.env.REACT_APP_BASE_URL}/user/login`;
        const response = await axios.post(API_URL, userDetails);
        if (response.status === 200) {
          const accessToken = response.data.data.accessToken;
          Cookies.set("accessToken", accessToken);
          window.location.reload();
        }
      } catch (error) { 
        setCurrentUserId(error?.response?.data?.errors[0]?.fields?.user_id)
        let message = error?.response?.data?.errors[0]?.description.split("_").join(" ")

        if(error?.response?.data?.errors[0]?.description==="USER_NOT_VERIFIED"){
            
        swal({
          title: `${message}`,
          text:"To verify your account, click on the ‘Get OTP’ button and receive the OTP on your registered phone number.",
          icon: "warning",
          type: "warning",
          showCancelButton: false,
          confirmButtonClass: "",
          confirmButtonText: "Ok",
          buttons:{
            button: "Get OTP"
          },
          closeOnConfirm: false,
        }).then((willDelete) => {
          if (willDelete) { 
            hanldeResendCode(error?.response?.data?.errors[0]?.fields?.user_id)
          }
        });
          
        }
        if(error?.response?.data?.errors[0]?.description!=="USER_NOT_VERIFIED"){
            
          swal({
            title: `${message}`,
            icon: "warning",
            type: "warning",
            showCancelButton: false,
            confirmButtonClass: "",
            confirmButtonText: "Ok", 
            closeOnConfirm: false,
          }).then((willDelete) => {
            if (willDelete) {
              window.location.reload(); 
            }
          });
            
          }
      
      }
      
  }

  
  const handleVerifyAccountApi = async (otp) => { 
    const apiBody = {
      code: otp,
      userId: currentUserId && currentUserId,
    };
    const API_URL =  `${process.env.REACT_APP_BASE_URL}/user/verify-account`;

    try {
      const response = await axios.post(API_URL, apiBody);
      if (response?.status === 200) {
        swal({
          title: "Account Verified",
          icon: "success",
          type: "success",
          showCancelButton: false,
          confirmButtonClass: "",
          confirmButtonText: "Ok",
          closeOnConfirm: false,
        }).then((willDelete) => {
          if (willDelete) {
            window.location.reload();
          }
        });
      }
    } catch (error) {
      let message = error?.response?.data?.errors[0]?.description.split("_"); 
      let updatedMessage = message.join(" "); 
      toast.error(updatedMessage);
    }
  };
  
  const handleOtpInput = (e)=>{
    if(isNaN(e.target.value)){
      return false
    }
    setOtpInput(e.target.value)
  }

  return (
    <>
      <div className="row justify-content-center loginScreen flex-column popClr loginModal">
       {verifyAccount===0 && <div className="col-11 col-md-6 sign-in customSingInSignup">
          <div className="welcome mt-3">
            <p className="welcome-text">
              <span>
                <i className="fa-solid fa-file-lines signIcon"></i>
              </span>{" "}
              <span className="text-white"> Sign In</span>
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="user-input mt-2">
              <input
                className="form-control user-input input_bg_color"
                type="text"
                placeholder="Username"
                aria-label="Username"
                onChange={handleOnchange}
                onBlur={onBlurHandler}
                maxLength={20}
                autoComplete="off"
                name="username"
                value={formData.username}
              />
              <span className="errorMsg">{formError.username}</span>
            </div>
            <div className="password-input mt-2">
              <input
                className="form-control user-input mt-3 input_bg_color"
                type={`${showPassword === true ? "text" : "password"}`}
                placeholder="Password"
                aria-label="password"
                onChange={handleOnchange}
                onBlur={onBlurHandler}
                autoComplete="off"
                name="password"
                value={formData.password}
              />
              <span className="errorMsg">{formError.password}</span>
              <i
                className={`${
                  showPassword ? "bi bi-eye-slash" : "bi bi-eye"
                }  toggle-password`}
                onClick={handleShowpassword}
              />
            </div>
            <div className=" form-check">
              <div className="col d-flex justify-content-between mt-3">
                <div className="rememberme">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="checkbox"
                    checked={formData.remember}
                    onChange={handleOnchange}
                    name="remember"
                  />
                  <label
                    className="form-check-label heading-text"
                    htmlFor="checkbox"
                  >
                    Remember me
                  </label>
                </div>
              </div>
            </div>
            <div className="d-grid gap-2 signin-btn">
              <button className="btn btn-sign text-white" type="submit">
                Sign in
              </button>
            </div>
            <div className="copyright-text formFontWeigth">
              Don't have an account?{" "}
              <a
                href="#"
                className="brand-name formFontWeigth"
                onClick={() => dispatch(modalKey("profile"))}
              >
                Join Now
              </a>
            </div>
            <div className="forgot mt-1">
              <a
                className="forgotDetails forgetPasswordLable formFontWeigth"
             onClick={()=>setShow(false)}
                
              >
                Forgot <span onClick={()=>dispatch(showForgotUsernameModal(true))}>Username</span>/<span onClick={()=> dispatch(showForgotPasswordModal(true))}>Password</span>?
              </a>
            </div>
          </form>
        </div>}
       {verifyAccount===1 && <div className="col-11 col-md-6 sign-in customSingInSignup">
       <div className="col-12 mx-auto">
       <div className="row m-0">
         <div className="col-md-12 col-lg-12 d-flex justify-content-start align-items-center mx-auto">
           <div className="heading-text mx-auto">
             <div className="d-flex justify-content-center align-items-center mb-3">
               <div className="titleWithIcon">
                 <img style={{width:"100%"}} src={Verify}/>
               </div>
               <p className="p mb-0 section-heading customPopupsHeading transactionTitle">
                 Verify Your Account
               </p>
             </div>
           </div>
         </div>
       </div>
       <p className="text-white confirm-text mt-4">
       To continue, please enter 4-digit verification code we sent to your
       phone number.
     </p>
     <div className="password-input mt-2">
     <input
       className="form-control user-input mt-3"
       type="text"
       placeholder="Enter Verification Code"
       aria-label="verification"
       name="verificationCode"   
       maxLength={4}
       value={otpInput}
       onChange={handleOtpInput}
     /> 
   </div> 
   
   <div className="d-grid gap-2 signin-btn">
   <button
     className="btn btn-sign ipadHeaderBtn"
     data-bs-target="#signuppassdModal"
     data-bs-toggle="modal"
     type="button" 
     onClick={()=>handleVerifyAccountApi(otpInput)}  disabled={!otpInput}
   >
     Verify
   </button>
 </div>
     </div>
        </div>}
      </div>
    </>
  );
}
const Login = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showVerify, setShowVerify]= useState(false)  
  const dispatch = useDispatch();
  const handleShow = () => {
    setShow(true);
    dispatch(modalKey("home"));
  };
  return (
    <>
      <Button
        variant=""
        onClick={handleShow}
        className="btn header-btn sign text-nowrap me-2 headerBtn ipadHeaderSingInBtn ipadHeaderBtn"
      >
        Sign In
      </Button>
      <Modal show={show} onHide={handleClose} size="xl" className="login-modal">
        <Modal.Header closeButton className="btn-close-white"></Modal.Header>
        <Modal.Body>
          <ModalPanel show={show} setShow={setShow} />
        </Modal.Body>
      </Modal>
      
    </>
  );
};

export default Login;
