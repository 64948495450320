import "./assets/css/master.css";
import "./assets/css/responsive.css";
import "react-bootstrap-v5";
import { Routes, Route, HashRouter, BrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Operators from "./pages/Operators";
import Navbar from "./components/Navbar/Navbar";
import Notifications from "./pages/Notifications";
import { ContactUs } from "./pages/ContactUs";
import Faq from "./pages/Faq";
import EditProfile from "./components/UserProfile/EditProfile";
import MyOperators from "./components/MyOperator/MyOperators";
import Deativate from "./components/UserProfile/Deativate";
import ChangePassword from "./components/UserProfile/ChangePassword";
import LastActivity from "./components/MyOperator/LastActivity";
import ChangeNumber from "./components/UserProfile/ChangeNumber";
import Redeem from "./components/MyOperator/Redeem"; 
import TransactionDataTable from "./components/MyOperator/TransactionDataTable";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFound from "./pages/NotFound";
import Cart from "./components/MyOperator/Cart";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { io } from "socket.io-client";
import { useEffect } from "react";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop ";
import Chatbot from "./components/chatbot/Chatbot";
import TransferToFriend from "./components/MyOperator/TransferToFriend";  
import { useSelector } from "react-redux";
 

function App() {
  const { isShowNavbar } = useSelector((state) => state.counter);
  
const accessToken = Cookies.get("accessToken")
  const disableInspect = process.env.REACT_APP_BASE_URL;

  if (!disableInspect.includes("dev-api")) {
    document.addEventListener("keydown", (event) => {
      if (
        event.keyCode === 123 ||
        (event.ctrlKey && event.shiftKey && event.keyCode === 73)
      ) {
        event.preventDefault();
      }
    });

    document.addEventListener("contextmenu", (event) => event.preventDefault());
  }

  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route
            path="/operators"
            element={<ProtectedRoute Component={Operators} />}
          />
          <Route
            path="/notifications"
            element={<ProtectedRoute Component={Notifications} />}
          />
          <Route
            path="/editProfile"
            element={<ProtectedRoute Component={EditProfile} />}
          />
          <Route
            path="/changeNumber"
            element={<ProtectedRoute Component={ChangeNumber} />}
          />
          <Route
            path="/myOperators"
            element={<ProtectedRoute Component={MyOperators} />}
          />
          <Route
            path="/deactivate"
            element={<ProtectedRoute Component={Deativate} />}
          />
          <Route
            path="/changePassword"
            element={<ProtectedRoute Component={ChangePassword} />}
          />
          <Route
            path="/lastActivity"
            element={<ProtectedRoute Component={LastActivity} />}
          />
          <Route
            path="/myOperators"
            element={<ProtectedRoute Component={MyOperators} />}
          />
          <Route
            path="/transactions"
            element={<ProtectedRoute Component={TransactionDataTable} />}
          />
          <Route
            path="/redeem"
            element={<ProtectedRoute Component={Redeem} />}
          />
          <Route path="/cart" element={<ProtectedRoute Component={Cart} />} />
          <Route
            path="/contactus"
            element={<ProtectedRoute Component={ContactUs} />}
          />
          {/*<Route
          path="/transferToFriend"
          element={<ProtectedRoute Component={TransferToFriend} />}
        />*/}
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/t&c" element={<TermsConditions />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <div>
        <ScrollToTop/>
       {/*accessToken && isShowNavbar && <Chatbot/>*/}
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
