import React, { useState } from "react";

const OperatorDetailsPopup = ({eachOperator,isShow,setIsShow }) => {

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>

      <div
        className={`modal fade ${isShow ? "show d-block modal-hide" : ""}`}
        id="operatorModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen-lg-down modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content detailsModal">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setIsShow(false)}
              />
            </div>
            <div className="modal-body d-flex mx-auto align-items-center  text-white">
              <div className="row transactionPopUp m-0">
                <div className="col-md-12 d-flex p-0 align-items-center mb-3">
                  <div className="titleWithIcon">
                    <i className="fa-solid fa-user operatorIcon"></i>
                  </div>
                  <div className="operatorHeading">
                    <p className="opDetail">Operator's detail info</p>
                  </div>
                </div>
                <div className="operatorDetailContainer">
                  <div className="row">
                    <div className="col-6 ">Operator ID</div>
                    <div className="col-6 text-start">{eachOperator?.operatorId}</div>
                    
                  </div>

                 
                 
                  <div className="row">
                    <div className="col-6 ">Deposit Method</div>
                    <div className="col-6 text-start termsConditon">
                      {eachOperator?.depositMethods && eachOperator?.depositMethods?.map((depostiMethod,index)=>(
                        <span className="depositMethods" key={index}>{`${depostiMethod} ` }</span>
                      ))}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">Min Deposit Amount</div>
                    <div className="col-6 text-start">{eachOperator?.minDeposit.toFixed(2)}</div>
                  </div>

                  <div className="row">
                    <div className="col-6 ">Deposit Processing Time</div>
                    <div className="col-6 text-start">{eachOperator?.depositProcessingIn}</div>
                  </div>
                  <div className="row">
                    <div className="col-6  termsConditon">
                      Max Daily Redeem Prizes Value
                    </div>
                    <div className="col-6 text-start">{eachOperator?.dailyRedeemMaxLimit.toFixed(2)}</div>
                  </div>
                  <div className="row">
                    <div className="col-6 ">Customer Supported By</div>
                    <div className="col-6 text-start">{eachOperator?.supportBy && eachOperator?.supportBy[0]}</div>
                  </div>
                  <div className="row">
                    <div className="col-6 ">Contact Phone Number</div>
                    <div className="col-6 text-start">
                    {`(${eachOperator?.supportContact.slice(0, 3)})${eachOperator?.supportContact.slice(3, 6)}-${eachOperator?.supportContact.slice(6)}`}
                  </div>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OperatorDetailsPopup;
