import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const ProtectedRoute = (props) => {
  const Comp = props.Component;
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = Cookies.get("accessToken");
    if (accessToken === undefined) {
      swal({
        title: "Please Sign In to access this page",
        icon: "warning",
        type: "warning",
        showCancelButton: false,
        confirmButtonClass: "",
        confirmButtonText: "Ok",
        closeOnConfirm: false,
      });
      return navigate("/");
    }
  },);

  return (
    <div>
      <Comp />
    </div>
  );
};

export default ProtectedRoute;
