import React, { useState } from "react";

const ShowCodePopup = ({ row }) => {
  const [isShow, setIsShow] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
  }; 
  return (
    <>
      <div className="playbtn d-flex justify-content-center align-items-center">
        <button
          type="button"
          className="btn deactivateUserBtn header-btn codeShowBtn modalBtnfont btnSidebarUpdate showCodeBtn"
          data-bs-toggle="modal"
          data-bs-target="#operatorModal"
          onClick={() => setIsShow(true)}
        >
          Show Code
        </button>
      </div>

      <div
        className={`modal fade ${isShow ? "show d-block modal-hide" : ""}`}
        id="operatorModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen-lg-down modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setIsShow(false)}
              />
            </div>
            <div className="modal-body d-flex mx-auto align-items-center">
             {/*<div className="row transactionPopUp m-0">
                <div className="col-md-12" >
                  <p className="text-center preWrap p-3">
                    Redeem was successfully created
                  </p>
                  <p className="operatorAlert text-center preWrap p-3">
                    Do not share this code to the operator before you receive
                    redeem
                  </p>
                  <p className="text-center preWrap p-3">
                    The Validation code for redeem is <br />{" "}
                    <span className="transactionPopTitleClr">{row?.redeem_code}</span>
                  </p> 
                  <p className="text-center preWrap p-3">
                    Operator ID: {row?.operator_id}
                  </p>
                  <p className="text-center preWrap p-3">Redeem ID: {row?.redeem_id}</p>
                  <p className="text-center preWrap p-3">
                    You can find the Validation Code at{" "}
                    <span className="transactionPopTitleClr">
                      Transaction Page
                    </span>
                  </p>
                </div>
              </div>*/ }
              <div className="row">
              <div className="col-12 mx-auto">
                <div className="showCodeDetails">
                 <p className="operatorAlert preWrap p-3">
                    Do not share this code to the operator before you receive
                    redeem
                  </p>
                  <div className="codeDetailsContainer">
                  <p className="text-center preWrap p-3">
                  Redeem was successfully created
                </p>
                <div className="row"> 
                <div className="col-6 text-start">Validation code for redeem</div>
                <div className="col-6"><span className="validationCode">{row?.redeem_code}</span></div>
                </div>
                <div className="row"> 
                <div className="col-6 text-start">Operator ID</div>
                <div className="col-6">{row?.operator_id}</div>
                </div>
                <div className="row"> 
                <div className="col-6 text-start">Redeem  ID</div>
                <div className="col-6"> {row?.redeem_id}</div>
                </div>
                <div className="row"> 
                <div className="col-6 text-start">Redeem  Amount</div>
                <div className="col-6"> {row?.amount?.toFixed(2)}</div>
                </div>
              
                  </div>
                  <p className="text-center preWrap p-4">
                  You can find the Validation Code at{" "}
                  <span className="transactionPopTitleClr">
                    Transaction Page
                  </span>
                  </p>
                </div>
              </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowCodePopup;
