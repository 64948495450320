import React from "react";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate( )
  return (
    <div className="privacyPolicy">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="privacy">
              <h2 className="privacyTitle">
                <span>
                  <i className="fa-solid fa-shield me-2"></i>
                </span>{" "}
                Privacy Policy
              </h2>{" "}
              <div className="descriptionText">
                This Privacy Policy applies to the website and all affiliated
                websites and mobile versions operated by LuckyReels. We value
                your privacy and are dedicated to protecting it. This Policy
                outlines the personal information we may collect from you as a
                user or that you may provide to us when using our services on
                the Site. It also explains how we collect, use, store, protect,
                and disclose your personal information. We encourage you to read
                this Policy carefully to understand our practices and how we
                handle your personal information. If you do not agree with this
                Policy, you have the option to leave the Site. By accessing or
                using the Site, you consent to this Policy and acknowledge our
                collection, use, disclosure, retention, and protection of your
                information as described in this Policy. We reserve the right to
                modify, amend, or revise this Policy at any time and in any
                manner. Your continued use of the Site after any changes to this
                Policy will be considered as your acceptance of the revised
                Policy. We advise you to periodically review this Policy and
                take note of the Last date posted mentioned above. Any revised
                or edited version of this Policy will override any prior
                versions immediately upon posting.
              </div>
              <div className="sectionCard">
                <h3>Age Restrictions</h3>
                <p>
                  To use the Services, individuals must be at least eighteen
                  (18) years old. We do not target, gather, or request
                  information from anyone under the age of eighteen (18). If you
                  are under eighteen (18), please refrain from providing any
                  information and exit the Site immediately. Any data collected
                  from or about individuals under eighteen (18) will be promptly
                  deleted upon discovery. If you suspect otherwise, please reach
                  out to us on the website under <span onClick={()=>navigate('/contactus')} className="contacUsSpan">Contact us</span>.
                </p>
                <p>
                  The types of information that we collect about you may
                  include:
                </p>
                <ol>
                  <li>
                    {" "}
                    Personal identification information: This includes your
                    name, alias, username, or any other unique personal
                    identifier that can be used to identify you.
                  </li>
                  <li>
                    Contact information: This includes your postal address,
                    billing address, shipping address, email address, and
                    telephone number.
                  </li>
                  <li>
                    Internet network activity information: This includes your IP
                    address and other information related to your online
                    activities, such as browsing history, search history, and
                    online interactions.
                  </li>
                  <li>
                    Geolocation data: This includes information about your
                    physical location, which can be determined through various
                    means such as GPS or Wi-Fi.
                  </li>
                  <li>
                    dentification information: This includes details from your
                    driver's license, passport, or any other identification
                    card.
                  </li>
                  <li>
                    {" "}
                    Financial information: This includes your credit card or
                    banking information, which may be collected for payment
                    purposes.
                  </li>
                  <li>
                    Personal property records: This includes information related
                    to your personal belongings or assets.
                  </li>
                  <li>
                    Employment information: This includes details about your
                    current or previous employment
                  </li>
                </ol>
                <p>
                  It's important to note that this policy does not apply to
                  personal information that has been de identified or is already
                  publicly available.
                </p>
              </div>
              <div className="sectionCard">
                <h3>Our Cookie Policy</h3>
                <p>
                  Personal information is collected directly from you as
                  individuals when you provide it to the organization. This can
                  occur during the account registration process, when
                  individuals provide their profile information, make purchases,
                  respond to surveys, or communicate with there operators
                  through various channels such as contact forms, phone, mail,
                  email, text, or social media platforms. Additionally, personal
                  information may be collected automatically through
                  technologies like cookies, web beacons, and analytics
                  services. It is important to clarify that this privacy policy
                  does not cover information collected offline or through other
                  means, or by any third party linked to or accessible through
                  the website.
                </p>
              </div>
              <div className="sectionCard">
                <h3>Involved Parties</h3>
                <p>
                  If you click a link on the site, you will be redirected to a
                  third-party website. However, it is important to note that the
                  website does not operate, control, or endorse these
                  third-party websites. Therefore, the content or privacy
                  practices of these websites are not the responsibility of the
                  site. The site also does not guarantee that these third-party
                  websites or even the site itself will function without errors
                  or interruptions, or that they are free from viruses or other
                  problems that may harm your computer.
                </p>
                <p>
                  t is recommended that you are aware when you leave the site
                  and read the privacy policies of any third-party website that
                  collects your personal information. This is to ensure that you
                  understand how your information will be used and protected on
                  these websites.
                </p>
                <p>
                  We have the ability to share publicly available and de
                  identified information with third parties without any
                  limitations. However, when it comes to your personal
                  information, we are more selective in who we disclose it to.
                  This includes sharing your personal information with our
                  subsidiaries, affiliates, contractors, service providers, and
                  other necessary third parties in order to provide you with our
                  services. In situations such as mergers, joint ventures, or
                  asset transfers, your personal information may be disclosed to
                  potential buyers or successors. We may also disclose your
                  personal information to law enforcement authorities,
                  government agencies, or private litigants in response to
                  lawful requests or to protect our interests. Additionally, we
                  may share your personal information with other parties if it
                  is necessary to identify and take legal action against
                  individuals who may be violating our policies, fulfill a legal
                  obligation, protect our legitimate interests, ensure the
                  safety of ourselves, our users, or the general public, or with
                  your explicit consent.
                </p>
              </div>
              <div className="sectionCard">
                <h3>Your Rights</h3>
                <p>
                  {" "}
                  You have the choice to delete or restrict any personal
                  information you have provided directly to us. Additionally,
                  you can request to permanently delete your account, which will
                  result in the deletion of the personal information you have
                  provided. However, we may retain your personal information for
                  other purposes outlined in our policies
                </p>
                <p>Regarding the personal information collected automatically, you can choose to refuse cookies
                by adjusting the settings on your browser. However, this may limit your access to certain parts of
                the website.</p>
                <p>As for the "Do Not Track" (DNT) preference, while we do not currently recognize or respond to
                DNT signals, we are monitoring industry developments to better understand and comply with
                DNT standards in the future.
                </p>
              </div>

              <div className="sectionCard">
              <h3>Information Collection and Use
              </h3>
              <p>We retain your personal information for as long as necessary to fulfill the purposes for which
              it was collected, unless a longer retention period is required or permitted by law. Once the
              purpose for retaining your personal information no longer exists, we will securely dispose of it in
              accordance with our data retention policies and applicable laws and regulations. Additionally, we
              may retain publicly available information and de identified information for legitimate business
              purposes indefinitely, without further notice to you or your consent.
              </p>
              <p>While the company has taken measures to secure your personal information, such as
              implementing firewalls and secure servers, they cannot guarantee complete security. The
              transmission of personal information over the internet is not completely secure, and there is a
              risk of unauthorized access or disclosure. It is important to be aware of the potential risks and
              take precautions when providing personal information online. The company is not responsible
              for any circumvention of privacy settings or security measures used by the website.</p>
              <p>The company may use your personal information for various purposes, including but not limited
              to providing access to the website and services, updating your account information,
              personalizing the services based on your preferences, notifying you about changes to the
              services and policies, fulfilling contractual obligations, monitoring and analyzing traffic and
              usage trends, ensuring the integrity and security of the services, improving the services and
              providing customer service, investigating and preventing unauthorized or prohibited uses of the
              services, and any other purpose with your consent.</p>
              </div>
              <div className="sectionCard">
              <h3>Disclaimer & Jurisdictions
              </h3>
              <p>In regards to your California privacy rights, we want to assure you that we do not disclose
              personal information to third parties for direct marketing purposes, and therefore, we do not fall
              under the guidelines of California Civil Code §1798.83 or the California Consumer Privacy Act.
              For residents of Nevada, we do not meet the requirements outlined in Nevada Revised Statute
              Chapter 603A. Although we do not currently sell your personal information, if we ever do in the future, you have the option to submit an opt-out request on luckyreels , and we will honor it
              accordingly.             
              </p>
              <p>Similarly, with respect to Virginia, our practices do not meet the triggers specified in Virginia's
              Consumer Data Protection Act.
              Lastly, we also do not meet the triggers outlined in the Colorado Privacy Act.
              We strive to prioritize the privacy and security of our users' personal information and will
              continue to comply with relevant privacy laws and regulations in each respective jurisdiction.
              </p>
              <p>If you are accessing our website from a country outside of our home jurisdiction, please be
              aware that your personal information may be transferred to, stored, and processed in our home
              jurisdiction or in the jurisdiction of third parties described in this policy. The data protection and
              privacy laws in our home jurisdiction or the jurisdiction of these third parties may not be as
              comprehensive or protective as those in your country. By accessing our website and providing
              your personal information, you consent to the transfer, storage, and processing of your
              information in our home jurisdiction or the jurisdiction of these third parties.</p>
              <p>This policy does not establish rights that can be enforced by individuals who are not parties
              to the agreement or contract, nor does it mandate the disclosure of personal information about
              users of the services to any external parties</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
