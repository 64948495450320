import { Modal } from "react-bootstrap-v5";
import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import {showMoreDetailsLastActivity} from '../../redux/counterSlice'

const MoreDetailsPopup = ({moreDetails}) => {
    const dispatch = useDispatch()
    const {isShowMoreDetails} = useSelector((state)=> state.counter)
    const handleClose = () => {
        dispatch(showMoreDetailsLastActivity(false));
      };
      function formatDate(inputDate) {
        const date = new Date(inputDate);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const day = String(date.getUTCDate()).padStart(2, "0");
        const hours = String(date.getUTCHours()).padStart(2, "0");
        const minutes = String(date.getUTCMinutes()).padStart(2, "0");
        const seconds = String(date.getUTCSeconds()).padStart(2, "0");
      
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }

     return (
        <>
          <Modal
            show={isShowMoreDetails}
            onHide={handleClose}
            size="xl"
            className="login-modal modalClosebtn  d-flex align-items-center justify-content-center"
          >
            <Modal.Header closeButton className="btn-close-white"></Modal.Header>
            <Modal.Body className="customFlexClass">
              <div className="row  m-0" style={{ width: "100%" }}>
                <div className="col-md-6 col-sm-12 mx-auto pt-4 pb-4">
                  <div className="row  m-0" style={{ width: "100%" }}>
                    <div className="col-sm-12 mx-auto text-white text-center">
                  <div className="moreDetailsContainer">
                  <h1>Transaction Detail Info</h1>
                  <p className="roundId">Round ID: {moreDetails?.transactionId?.split("_")?.join("") || 0}</p>
                  <p>{formatDate(moreDetails?.createdAt)}</p>
                  <p>Operator ID: {moreDetails?.wallet?.operatorId}</p> 
                  <p>Balance: {moreDetails?.afterBalance}</p>
                  <p>{moreDetails?.casinoGame?.name}</p>
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      );
}

export default MoreDetailsPopup




