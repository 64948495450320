import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OperatorDetailsPopup from "../UserProfilePopUps/OperatorDetailsPopup";
import DeleteOperatorPopup from "../UserProfilePopUps/DeleteOperatorPopup";
import SearchOperator from "../Operator/SearchOperator";
import Cookies from "js-cookie";
import axios from "axios";
import { compose } from "@reduxjs/toolkit";
import {  toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { myOperatorsList } from "../../redux/myOperatorsListSlice";
import { getMainWalletDetails } from "../../redux/mainWalletSlice";

const MyOperators = () => {
  const [isShowOper, setIsShowOper] = useState(false);
  const [myOperarsData, setMyOperarsData] = useState([]);
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState();
  const [isshowDetails, setIsShowDetails] = useState(false);
  const [operatorDetails, setOperatorDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [showDeltepopup, setDeletepopup] = useState(false)
  const [deleteOpertorId, setDeleteOperatorId] = useState("")
  const dispatch = useDispatch()
  /*********switch operator ********** */ 

  const switchOperatorRequest =async(id) => {
    // setToggle(!toggle); 
    const payload = {
      walletId: id,
    };
    const API_URL =  `${process.env.REACT_APP_BASE_URL}/my-operators/change-status`
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: payload,
    };
    try {
      const response = await axios(config);
      setToggle((prev)=> !prev);
    } catch (error) {
      const { response } = error;
      if(response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
        Cookies.remove("accessToken");
        navigate("/")
      }
      const message =  response?.data?.errors[0]?.description.split("_").join(" ")
      toast.error(message)
    }
   
  };

  // Delete Operator

  const handleShowDeleteOperator = (operatorId)=>{
    setDeletepopup(true)
    setDeleteOperatorId(operatorId)
  }

  const deleteOperator = async (operatorId) => {
   

    const payload = {
      operatorId: operatorId,
    };
    const API_URL =  `${process.env.REACT_APP_BASE_URL}/my-operators/delete`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "delete",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: payload,
    };
    try {
      const response = await axios(config);
      setToggle(!toggle); 
    } catch (error) {
      const { response } = error;
      const message =  response?.data?.errors[0]?.description?.split("_").join(" ") 
      toast.error(message) 
      
    }
  };
/******Operator Details****** */
const operatorDetailsReq = async (operatorId) => {
  
  const API_URL =  `${process.env.REACT_APP_BASE_URL}/my-operators/details?operatorId=${operatorId}`;
  const accessToken = Cookies.get("accessToken"); 
  const config = {
    method: "get",
    url: API_URL,
    headers: {
      type: "text",
      Authorization: accessToken,
    }
  };
  try {
    const response = await axios(config);
    if(response.status===200){
      setIsShowDetails(true);
    }
    
    setSelectedData(response?.data?.data)
  } catch (error) {
    const { response } = error;
  
  }
};

  /****My Operators****/

  const myOperatorList = async (operatorId) => {
    const API_URL =  `${process.env.REACT_APP_BASE_URL}/my-operators`;
    const accessToken = Cookies.get("accessToken"); 
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        type: "text",
        Authorization: accessToken,
      },
    };
    try {
      const response = await axios(config);

      setMyOperarsData(response?.data?.data, "operator details");
      setLoading(false);


    } catch (error) {
      const { response } = error; 
    }finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    
    const accessToken = Cookies.get("accessToken")
    if(accessToken){

      myOperatorList();
      dispatch(myOperatorsList())
      dispatch(getMainWalletDetails())
    }
    
  }, [toggle || false]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-8 mx-auto">
          <div className="tableContainer mt-3 mb-4 latestActivity lastActivityTable myOperatorTable">
            <div className="col-md-12 d-flex justify-content-center align-items-center px-3 themeColor">
              <div className="titleWithIcon">
                <i className="fa-solid fa-user-group transactionIcon"></i>
              </div>
              <div className="heading-text">
                <p className="mt-3 section-heading">My Operators</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row text-white">
        <div className="col-12 col-lg-8  mx-auto">
          <div className="row">
            {loading && (
              <div className="d-flex justify-content-center align-items-center trendingGameSpinner">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}

            {myOperarsData?.map((operator) => {
              return (
                <div className="col-12  col-md-6 mx-auto" key={operator?.id}>
                  <div className="myOperators">
                    <div>
                      <p className="opertorId">
                        Operator ID: {operator?.operator?.id}
                      </p>
                      <p className="operatorBalance">
                        Balance: ${operator?.amount.toFixed(2)}
                      </p>
                    </div>

                    <div className="actionsBtn">
                      <div
                        className="iconContainer"
                        onClick={() => {
                        
                          operatorDetailsReq(operator?.operator?.id)
                        }}
                      >
                        <i className="fa-solid fa-circle-info"></i>
                        <div className="tooltipContainer" >
                          <span className="tooltipDetails text-white">
                            Details
                          </span>
                        </div>
                      </div>
                      <button disabled={operator?.activeStatus ===1} className="iconContainer iconBtn" onClick={()=>switchOperatorRequest(operator.id)}>
                        <i className={`${operator?.activeStatus ==1 ? "fa-solid fa-user-check activeOperator switchOperator" : "fa-solid fa-user-slash switchOperator" }`}></i>
                        <div className="tooltipContainer">
                          <span className="tooltipDetails text-white">
                            {operator?.activeStatus === 1 ? "Active" : "Deactivated"}
                          </span>
                        </div>
                      </button>
                      <div
                        className="iconContainer"
                        onClick={() => handleShowDeleteOperator(operator?.operator?.id)}
                      >
                        <i className="fa-solid fa-trash"></i>
                        <div className="tooltipContainer">
                          <span className="tooltipDetails text-white">
                            Delete
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {myOperarsData.length < 4 && !loading && (
              <div className="col-12 col-md-6 mx-auto">
                <div className="myOperators addOperator">
                  <p>Add New Operator</p>
                  <div
                    className="addOperatorIcon"
                    onClick={() => setIsShowOper(true)}
                  >
                    <i className="fa-solid fa-plus"></i>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <OperatorDetailsPopup
        isShow={isshowDetails}
        setIsShow={setIsShowDetails}
        eachOperator={selectedData}
      />
      <SearchOperator isShowOper={isShowOper} setIsShowOper={setIsShowOper} />
      <DeleteOperatorPopup showDeltepopup={showDeltepopup} setDeletepopup={setDeletepopup} deleteOpertorId={deleteOpertorId} deleteOperator={deleteOperator}/>
    </div>
  );
};

export default MyOperators;
