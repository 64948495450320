import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editProfile } from "../../redux/editProfileSlice";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserDetails } from "../../redux/getUserDetailsSlice";
import moment from "moment";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};
const generateYears = (startYear, endYear) => {
  const years = [];

  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }

  return years;
};

const generateMonth = () => {
  return Array.from({ length: 12 }, (_, index) => index + 1);
};

const generateDays = (year, month) => {
  const daysInMonth = getDaysInMonth(year, month);

  return Array.from({ length: daysInMonth }, (_, index) => index + 1);
}; 

const EditProfile = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    country: { id: "", name: "" },
    month: "",
    day: "",
    year: "",
    gender: "",
  };
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();
  const [details, setDetails] = useState(initialValues);
  const [formError, setFormError] = useState({ initialValues });
  /* DOB start*/
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate()

  const [selectedYear, setSelectedYear] = useState(currentYear);

  const [selectedMonth, setSelectedMonth] = useState();

  const [selectedDay, setSelectedDay] = useState(1);
  const { userDetails } = useSelector((state) => state.userDetails);
  const [toggledetails, setToggleDeatils] = useState(false);
  const [isModified, setIsModified] = useState(false)
  /* handles for DOB */
  const handleYearChnage = (e) => {
    setIsModified(true)
    setSelectedYear(e.target.value);
    setDetails({ ...details, year: e.target.value });
    setFormError({ ...formError, year: "" });
  };

  const handleMonthChnage = (e) => {
    setIsModified(true)
    setSelectedMonth(e.target.value);
    setDetails({ ...details, month: e.target.value });
    setFormError({ ...formError, month: "" });
  };

  const handleDayChnage = (e) => {
    setIsModified(true)
    setSelectedDay(e.target.value);
    setDetails({ ...details, day: e.target.value });
    setFormError({ ...formError, day: "" });
  };

  useEffect(() => {
    async function apiCalling() {
      const dateOfBirth = userDetails.dateOfBirth;
      const parsedDate = moment(dateOfBirth);
      const year = parsedDate.format("YYYY");
      const month = parsedDate.format("M");
      const day = parsedDate.format("D");

      setDetails({
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        country: userDetails?.country?.id,
        gender: userDetails?.gender,
        dateOfBirth: moment(dateOfBirth).format("M/D/YYYY"),
      });
      setSelectedYear(year);
      setSelectedDay(day);
      setSelectedMonth(month);
    }
    apiCalling();
  }, [userDetails, toggledetails]);

  useEffect(() => {
    const accessToken = Cookies.get("accessToken");
    if(accessToken){

      dispatch(getUserDetails());
    } 
  }, [dispatch, toggledetails]);

  useEffect(() => {
    const days = generateDays(selectedYear, selectedMonth - 1); 

    if (selectedDay > days.length) {
      setSelectedDay(days[days.length - 1]);
    }
  }, [selectedYear, selectedMonth, details]);

  const years = generateYears(currentYear - 100, currentYear);

  const months = generateMonth();

  const days = generateDays(selectedYear, selectedMonth - 1);

  /*DOB End */
  const onBlurHandler = (event) => {
    const fieldName = event.target.name;
    const fieldValue = details[fieldName];
    let err = { ...formError };
    if (fieldValue === "") {
      err[fieldName] = "Required!";
    } else {
      delete err[fieldName];
    }
    setFormError({ ...err });
  }; 
  const validateForm = () => {
    let err = {};
    if (!details.firstName) {
      err.firstName = "Required!";
    } else if (details.firstName) {
      if (details.firstName.length < 3) {
        err.firstName = "First name should contain at least 3 character";
      } else if (details.firstName.length > 20) {
        err.firstName = "First name can't be more than 20 character";
      }
    }
    if (!details.lastName) {
      err.lastName = "Required!";
    } else if (details.lastName) {
      if (details.lastName.length < 3) {
        err.lastName = "Last name should contain at least 3 character";
      } else if (details.lastName.length > 20) {
        err.lastName = "Last name can't be more than 20 character";
      }
    }

    if (!details.country) {
      err.country = "Required!";
    }
   
    if(details.dateOfBirth=="Invalid date"){

   

    if (!details.month) {
      err.month = "Required!";
    }
    if (!details.day) {
      err.day = "Required!";
    }
    if (!details.year) {
      err.year = "Required!";
    }
    if (details.gender === null) {
      err.gender = "Required!";
    }
   }
   if (details.month=="") {
    err.month = "Required!";
  }
  if (details.day=="") {
    err.day = "Required!";
  }
  if (details.year=="") {
    err.year = "Required!";
  }
  if (details.gender === null || details.gender=="") {
    err.gender = "Required!";
  }


    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };

  const handleOnchange = (e) => {
    setIsModified(true)
    let regName = /^[a-zA-Z]*$/;

    const fieldName = e.target.name;
    let err = { ...formError };
    delete err[fieldName];
    setFormError({ ...err });

    if (fieldName === "firstName" || fieldName === "lastName") {
      if (!regName.test(e.target.value)) {
        return;
      }
    }
    setDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  
  const editProfileApiCall = async () => {
    if(!isModified) return
    const payload = {
      firstName: details.firstName,
      lastName: details.lastName,
      dateOfBirth: `${selectedMonth}/${selectedDay}/${selectedYear}`,
      gender: details.gender,
      country: details?.country,
    };

    const API_URL = `${process.env.REACT_APP_BASE_URL}/user/update`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: payload,
    };
    try {
      const response = await axios(config);
      toast.success("Profile Updated");
    
    } catch (error) {
      const { response } = error;
      if(response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
        Cookies.remove("accessToken");
        navigate("/")
      }

      let message = response?.data?.errors[0]?.description.split("_");
      let updatedMessage = message?.join(" ");
      toast.error(updatedMessage);
      setTimeout(()=>{
        navigate("/")
      },3000)
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = validateForm();  
    if (valid) {
    
      editProfileApiCall();
      setIsModified(false)
    }
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  const fetchCountryList = async () => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/region/country?pageNo=1&limit=10`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        type: "text",
        Authorization: accessToken,
      },
    };
    try {
      const response = await axios(config);
      setCountries(response?.data?.data?.countries?.rows);
    } catch (error) {
      const { response } = error;
    }
  };

  return (
    <> 
    
    <div className="deactivateUser">
      
      <div className="container">
        <div className="row">
          <div className="col-sm-10 col-md-8 col-lg-8 col-xl-10 mx-auto deactivateCard">
            <div className="col-md-12 d-flex justify-content-center align-items-center px-3 themeColor">
              <div className="titleWithIcon">
                <i className="fa-solid fa-user transactionIcon"></i>
              </div>
              <div className="heading-text">
                <p className="mt-3 section-heading">Edit Profile</p>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6 mb-4 mt-2">
                  <div className="user-input ">
                    <label
                      htmlFor="inputState"
                      className="form-label text-white mb-2"
                    >
                      Username*
                    </label>
                    <input
                      className="form-control user-input"
                      name="username"
                      type="text"
                      placeholder="Username"
                      aria-label="first-name"
                      value={userDetails?.username}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="col-sm-6 mb-4 mt-2">
                  <div className="user-input ">
                    <label
                      htmlFor="inputState"
                      className="form-label text-white mb-2 customPopUpLabel"
                    >
                      Country*
                    </label>
                    <select
                      id="inputState"
                      className="form-select editProfileCountry"
                      value={details?.country?.id}
                      name="country"
                      onChange={handleOnchange}
                      onBlur={onBlurHandler}
                    >
                    <option value="">Select Your Country</option>
                      {countries?.map((country) => (
                        <option
                          key={country?.id}
                          value={country?.id}
                          selected={country?.id == userDetails?.country?.id}
                        >
                          {country?.name}
                        </option>
                      ))}
                    </select>

                    <span className="errorMsg">{formError.country}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 mb-4">
                  <div className="user-input ">
                    <label
                      htmlFor="inputState"
                      className="form-label text-white mb-2 customPopUpLabel"
                    >
                      First Name*
                    </label>
                    <input
                      className="form-control user-input"
                      type="text"
                      placeholder="Enter First Name"
                      aria-label="first-name"
                      name="firstName"
                      maxLength={20}
                      value={details.firstName}
                      onChange={handleOnchange}
                      onBlur={onBlurHandler}
                    />
                    <span className="errorMsg">{formError.firstName}</span>
                  </div>
                </div>
                <div className="col-sm-6 mb-4">
                  <div className="row">
                    <label
                      htmlFor="inputState"
                      className="form-label text-white customPopUpLabel"
                    >
                      Date of Birth*
                    </label>
                    <div className="col-md-4 ">
                    <div className="user-input">
                      <select
                        value={selectedYear}
                        onChange={handleYearChnage}
                        id="inputState"
                        className="form-select editProfileCountry"
                      >
                        <option value="">Year</option>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                      <span className="errorMsg">{formError.year}</span>
                    </div>
                  </div>
                    <div className="col-md-5">
                      <div className="user-input ">
                        <select
                          value={selectedMonth}
                          onChange={handleMonthChnage}
                          id="inputState"
                          className="form-select editProfileCountry"
                          name="month"
                        >
                          <option value="">Month</option>
                          {months.map((month) => (
                            <option key={month} value={month}>
                              {month}
                            </option>
                          ))}
                        </select>
                        <span className="errorMsg">{formError.month}</span>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="user-input">
                        <select
                          value={selectedDay}
                          onChange={handleDayChnage}
                          id="inputState"
                          className="form-select editProfileCountry"
                        >
                          <option value="">Day</option>
                          {days.map((day) => (
                            <option key={day} value={day}>
                              {day}
                            </option>
                          ))}
                        </select>
                        <span className="errorMsg">{formError.day}</span>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <div className="user-input">
                    <label
                      htmlFor="inputState"
                      className="form-label text-white mb-2 customPopUpLabel"
                    >
                      Last Name*
                    </label>
                    <input
                      className="form-control user-input"
                      type="text"
                      placeholder="Enter Last Name"
                      aria-label="first-name"
                      name="lastName"
                      maxLength={20}
                      value={details.lastName}
                      onChange={handleOnchange}
                      onBlur={onBlurHandler}
                    />
                    <span className="errorMsg">{formError.lastName}</span>
                  </div>
                </div>
                <div className="col-sm-6 mb-3">
                  <div className="user-input ">
                    <label
                      htmlFor="inputState"
                      className="form-label text-white mb-2 customPopUpLabel"
                    >
                      Gender*
                    </label>
                    <select
                      id="inputState"
                      className="form-select editProfileCountry"
                      value={details.gender}
                      name="gender"
                      onChange={handleOnchange}
                      onBlur={onBlurHandler}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                    <span className="errorMsg">{formError.gender}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 mx-auto ">
                  <div className="d-grid gap-2 signin-btn mt-2">
                    <button className="btn btn-sign" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
    );
};

export default EditProfile;
