import React, { useState } from "react";
import Search from "../assets/images/images/operator/serachoperator.png";
import SearchOperator from "../components/Operator/SearchOperator";
import ApplyOperator from "../components/Operator/ApplyOperator";
import { useSelector } from "react-redux";

const Operators = () => {
  const [isShowOper, setIsShowOper] = useState(false);
  const [applyOper, setApplyOper] = useState(false);
  const { language } = useSelector((state) => state.counter);
  return (
    <>
      {/* find-operator */}
      <SearchOperator isShowOper={isShowOper} setIsShowOper={setIsShowOper} />

      {/* Apply Now */}
      <ApplyOperator applyOper={applyOper} setApplyOper={setApplyOper} />
      {/* Operator HTML starts here */}
      <div className="container">
        <div className="row operator-first-card p-0">
          <div className="col-sm-12 col-lg-6">
            <div className="left-operator p-2">
              <div>
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-start align-items-center px-3">
                    <div className="icon">
                      <img src={Search} className="mt-1" alt="find-operator" />
                    </div>
                    <div className="heading-text">
                      <p className="mt-3 section-heading">
                        {language === "spanish"
                          ? "Buscar operador"
                          : "Find Operator"}{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="content-text">
                      <p className="text-white">
                        {language === "spanish"
                          ? "Si es un cliente nuevo que busca un operador local para comprar créditos de juego o un cliente existente que busca agregar un nuevo operador, nuestro motor de búsqueda de operadores puede ayudarlo. Simplemente haga clic en el botón a continuación para encontrar un operador cerca de usted."
                          : " Whether you're a new customer in search of a local operator to buy game credits or an existing customer looking to add a new operator, our operator search engine can assist you. Simply click the button below to find an operator near you."}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="playbtn d-flex justify-content-center align-items-center">
                <button
                  type="button"
                  className="btn header-btn text-nowrap mt-4 mb-2 btnHeaderCustom"
                  data-bs-toggle="modal"
                  data-bs-target="#operatorModal"
                  onClick={() => setIsShowOper(true)}
                >
                  {language === "spanish" ? "Buscar operador" : "Find Operator"}
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-6">
            <div className="left-operator p-2">
              <div>
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-start align-items-center px-3">
                    <div className="icon">
                      <img src={Search} className="mt-1" alt="find-operator" />
                    </div>
                    <div className="heading-text">
                      <p className="mt-3 section-heading">
                        {" "}
                        {language === "spanish"
                          ? "Conviértase en operador"
                          : "Become Operator"}{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="content-text">
                      <p className="text-white">
                        {language === "spanish"
                          ? "¿Es usted operador o arrendatario de juegos de diversión, juegos arcade o juegos de habilidad? ¿Dirige un bar, una sala de juegos o una organización sin fines de lucro? ¡Nuestra plataforma proporciona la solución perfecta para usted y sus clientes! Ofrecemos una solución de software integral con una interfaz fácil de usar tanto para versiones de escritorio como móviles."
                          : "Are you an operator or leaseholder of amusement games, arcade games, or skill games? Do you run a bar, game room, or non-profit organization? Our platform provides the perfect solution for you and your customers! We offer a comprehensive software solution with a user-friendly interface for both desktop and mobile versions."}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="playbtn d-flex justify-content-center align-items-center">
                <button
                  type="button"
                  className="btn header-btn text-nowrap mt-4 mb-2 btnHeaderCustom"
                  data-bs-toggle="modal"
                  data-bs-target="#applyModal"
                  onClick={() => setApplyOper(true)}
                >
                  {language === "spanish" ? "Aplica ya" : "Apply Now"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Operators;
