
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "animate.css";
import Cookies from "js-cookie";
import axios from "axios";
import {  toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const ContactUs = () => {
  const [formError, setFormError] = useState({});
  const [phone, setPhone] = useState("");
  const navigate = useNavigate()

  const initialValues = {
    fullName: "",
    role: "",
    email: "",
    number: "",
    department: "",
    message: "",
  };
  const [details, setDetails] = useState(initialValues);
  const [phoneValid,setPhoneValid] = useState(false)

  const onBlurHandler = (event) => {
    const fieldName = event.target.name;
    const fieldValue = details[fieldName];
    let err = { ...formError };
    if (fieldValue === "") {
      err[fieldName] = "Required!";
    } else if (fieldName === "email") {
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!regex.test(fieldValue)) {
        err[fieldName] = "Email not valid!";
      } else {
        delete err[fieldName];
      }
    } else if (fieldName === "number" && fieldValue.length < 10) {
      err[fieldName] = "Invalid phone number!";
    } else {
      delete err[fieldName];
    }
    setFormError({ ...err });
  };

  const validateForm = (e) => {
    let fieldName = e.target.name;
    let fieldValue = details[fieldName];
    let err = {};

    if (details.fullName === "") {
      err.fullName = "Required!";
    }

    if (details.role === "") {
      err.role = "Required!";
    }

    if (details.email === "") {
      err.email = "Required!";
    } else if (details.email) {
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!regex.test(details.email)) {
        err.email = "Email not valid!";
      } else {
        delete err.email;
      }
    }
    if (details.number === "") {
      err.number = "Required!";
    } else if (!phoneValid) {
      err.number = "Invalid phone number";
    }

    if (details.department === "") {
      err.department = "Required!";
    }
    if (details.message === "") {
      err.message = "Required!";
    }
    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };

  const handleOnchange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.name;
    let err = { ...formError };
    delete err[fieldName];

    let regName = /^[a-zA-Z ]*$/;
    if (fieldName === "fullName") {
      if (!regName.test(e.target.value)) {
        return;
      }
    }

    if (fieldName === "number" && isNaN(e.target.value)) {
      return;
    }

    details["number"] = phone;

    setDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value.trimStart(),
    }));
    setFormError({ ...err });
  };

  const contactUsRequest = async () => {
    const payload = {
      name: details.fullName,
      role: details.role,
      email: details.email,
      phone: details.number,
      department: details.department,
      message: details.message,
    };
    const API_URL = `${process.env.REACT_APP_BASE_URL}/contact-us`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: payload,
    };
    try {
      const response = await axios(config);
      if (response.status === 200) {
        toast.success("Message  sent successfully");
      }
    } catch (error) {
      const response = error
      if(response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
        Cookies.remove("accessToken");
        navigate("/")
      }
      let errorMsg = error?.response?.data?.errors[0]?.description?.split("_")
        .join(" ");
      toast.error(errorMsg);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault(); 
    details["number"] = phone;
    
    let isValid = validateForm(e);
    if (isValid) {
      contactUsRequest();
      setDetails(initialValues);
      setPhone("");
    }
  };

  const handlePhoneChange = (value, country)=>{
    setPhone(value)
    setDetails({...details,number: value })
    setFormError({...formError, number:""})
    let validNum =  value.length - country.dialCode.length >= 10
    setPhoneValid(validNum) 
  }


  
  return (
    <div className="container contactUs">
      
      <div className="row m-0 p-0">
        <div className="col-md-12">
          <p className="contact-text text-white text-center">
            Contact Us Today
          </p>
        </div>
      </div>
      <div className="row animate__animated  animate__fadeInUp">
        <div className="col-md-8 mx-auto">
          <div className="contactUsDesc">
            <p className="contactTitle">
              Whether you have a question, comment, or just want to say a quick
              hello, We would love to hear from you.
            </p>
          </div>
        </div>
      </div>
      <div className="row m-0 p-0 animate__animated  animate__fadeInUp">
        <div className="col-md-10 col-lg-8 mx-auto mb-4">
          <form className="row" onSubmit={handleSubmit}>
            <div className="user-input mt-2 col-12 col-md-6 contactUsFields">
              <input
                className="form-control user-input inputTextColor "
                name="fullName"
                type="text"
                placeholder="Full Name"
                onChange={handleOnchange}
                onBlur={onBlurHandler}
                value={details.fullName}
                maxLength={35}
              />
              <span className="errorMsg">{formError.fullName}</span>
            </div>

            <div className="user-input mt-2 col-12 col-md-6 contactUsFields">
              {" "}
              <select
                id="inputState"
                className="form-select editProfileCountry inputTextColor"
                name="role"
                value={details.role}
                onChange={handleOnchange}
                onBlur={onBlurHandler}
              >
                <option value="">Role</option>
                <option value="vendor">Vendor</option>
                <option value="operator">Operator</option>
                <option value="customer">Customer</option>
              </select>
              <span className="errorMsg">{formError.role}</span>
            </div>
            <div className="user-input mt-2 col-md-6 col-12 contactUsFields">
              <input
                className="form-control user-input inputTextColor"
                name="email"
                type="text"
                placeholder="Email Address"
                onChange={handleOnchange}
                onBlur={onBlurHandler}
                value={details.email}
              />
              <span className="errorMsg">{formError.email}</span>
            </div>
            <div className="user-input mt-2 col-md-6 col-12 contactUsFields">
              <PhoneInput
                country={"us"}
                name="number"
                className="newPhoneInput"
                value={phone}
                onChange={handlePhoneChange}
              />
              <span className="errorMsg">{formError.number}</span>
            </div>
            <div className="user-input mt-2 col-12 contactUsFields">
              <select
                id="inputState"
                className="form-select editProfileCountry inputTextColor"
                name="department"
                value={details.department}
                onChange={handleOnchange}
                onBlur={onBlurHandler}
              >
                <option value=""> Choose Department</option>
                <option value="General Inquiries">General Inquiries </option>
                <option value="Tech Support">Tech Support</option>
                <option value="Customer Support">Customer Support</option>
              </select>
              <span className="errorMsg">{formError.department}</span>
            </div>
            <div className="user-input mt-2 col-12 contactUsFields">
              <textarea
                className="form-control text-area inputTextColor"
                name="message"
                rows={5}
                placeholder="Message"
                value={details.message}
                onChange={handleOnchange}
                onBlur={onBlurHandler}
              />
              <span className="errorMsg">{formError.message}</span>
            </div>
            <div className="user-input mt-2 col-12">
              <p className="contactTitle">
                By click on the "Send Message" you give consent to Lucky Reels
                to process your personal data to get in contact with you.{" "}
                <span>Learn more</span> on how Lucky Reels processes your
                personal data on the Privacy and cookies policy.
              </p>
            </div>
            <div className="gap-2 signin-btn text-center">
              <button className="btn btn-sign mb-5 w-50" type="submit">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
