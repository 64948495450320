import React, { useState, useEffect } from "react";
import Search from "../../assets/images/images/operator/serachoperator.png";
import Cookies from "js-cookie";
import axios from "axios";
import {toast } from "react-toastify";
import OperatorDetailsPopup from "../UserProfilePopUps/OperatorDetailsPopup";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const SearchOperator = ({ isShowOper, setIsShowOper }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [count, setCount] = useState(0);
  const [operatorListData, setOperatorListData] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [eachOperatorData, setEachOperatorData] = useState();
  const [operatorIdInput, setOperatorIdInput] = useState("");
  const [searchOperatorByRegison, setSearchOperatorByRegison] = useState();
  const navigate = useNavigate()



  useEffect(() => {
    
    const accessToken = Cookies.get("accessToken")
    if(accessToken){

      fetchCountryList();
      
    }
  }, []);

  const fetchCountryList = async () => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/region/country`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        type: "text",
        Authorization: accessToken,
      },
    };
    try {
      const response = await axios(config);
      setCountries(response?.data?.data?.countries);
    } catch (error) {
      if(error?.response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
        Cookies.remove("accessToken");
        navigate("/")
      }
      const { response } = error;
    }
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    setSearchOperatorByRegison(e.target.value);
    let countryValue = e.target.value;
    setSelectedState("");
    setSelectedCity("");
    setStates([]);
    setCities([]);
    fetchStateList(countryValue);
    setOperatorIdInput("");
  };

  const fetchStateList = async (countryValue) => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/region/state?countryId=${countryValue}`;

    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        type: "text",
        Authorization: accessToken,
      },
    };
    try {
      const response = await axios(config);
      setStates(response?.data?.data?.states?.rows);
    } catch (error) {
      const { response } = error;
    }
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    setSearchOperatorByRegison(e.target.value);
    let stateValue = e.target.value;
    setSelectedCity("");
    fetchCities(stateValue);
  };

  const fetchCities = async (stateValue) => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/region/city?stateId=${stateValue}`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        type: "text",
        Authorization: accessToken,
      },
    };
    try {
      const response = await axios(config);
      setCities(response?.data?.data?.cities?.rows);
    } catch (error) {
      const { response } = error;
    }
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
    setSearchOperatorByRegison(e.target.value);
  };

  const handleSearchOperatorById = (e)=>{
    if(isNaN(e.target.value)){
      return false
    }
    setOperatorIdInput(e.target.value)
  }

  const searchModalFunction = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 col-lg-12 d-flex justify-content-center align-items-start mx-auto">
              <div className="icon">
                <img src={Search} className="mt-1" alt="find-operator" />
              </div>
              <div className="heading-text">
                <p className="mt-3 section-heading">Find Operator</p>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="user-input mt-2">
              <label
                htmlFor="inputState"
                className="form-label text-white mt-3"
              >
                Search by Operator ID
              </label>
              <input
                className="form-control user-input"
                type="search"
                placeholder="Enter Operator ID"
                aria-label="Operator ID"
                value={operatorIdInput}
                maxLength={5}
                onChange={handleSearchOperatorById}
              />
            </div>
            <div className="password-input mt-2">
              <label
                htmlFor="inputState"
                className="form-label text-white mt-3"
              >
                Or You can try to find an operator by location
              </label>
              <select
                id="inputState"
                className="form-select selectCustomWidth"
                value={selectedCountry}
                onChange={(e) => handleCountryChange(e)}
              >
                <option value="">Select Country</option>
                {countries?.map((country) => (
                  <option value={country?.id} key={country?.id}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="password-input mt-2">
              <select
                id="inputState"
                className="form-select mt-3 selectCustomWidth"
                value={selectedState}
                onChange={handleStateChange}
              >
                <option value="">Select State</option>
                {states.map((state) => (
                  <option key={state?.id} value={state?.id}>
                    {state?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="password-input mt-2">
              <select
                id="inputState"
                className="form-select mt-3 selectCustomWidth"
                value={selectedCity}
                onChange={handleCityChange}
              >
                <option value="">Select City</option>
                {cities.map((city) => (
                  <option key={city?.id} value={city?.id}>
                    {city?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-grid gap-2 mt-4 signin-btn mb-5">
              <button
                className="btn btn-sign"
                type="submit"
                disabled={!(operatorIdInput || selectedCountry)}
              >
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const fetchOperatirList = async () => {
    setIsloading(true);
    const operatorIdUlr = `${process.env.REACT_APP_BASE_URL}/request/operator?pageNo=1&limit=13&operatorId=${operatorIdInput}`;
    const API_URL = `${process.env.REACT_APP_BASE_URL}/request/operator?pageNo=1&limit=13&search=${searchOperatorByRegison}`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: operatorIdInput ? operatorIdUlr : API_URL,
      headers: {
        type: "text",
        Authorization: accessToken,
      },
    };
    try {
      const response = await axios(config);
      setIsloading(false);
      if (response?.status === 200) {
        setCount(1);
        setOperatorListData(response?.data?.data?.rows);
      }
    } catch (error) {
      let errorMsg = error?.response?.data?.errors[0]?.description
        .split("_")
        .join(" ");
      swal({
        title: `${errorMsg}`,
        icon: "warning",
        type: "warning",
        showCancelButton: false,
        confirmButtonClass: "",
        confirmButtonText: "Ok",
        closeOnConfirm: false,
      }).then((willDelete) => {
        if (willDelete) {
          window.location.reload();
        }
      });
      setIsloading(false);
    }
  };

  const handleAddOperatorRequest = async (operatorId) => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/request`;
    const accessToken = Cookies.get("accessToken");
    const payload = {
      operatorId: operatorId,
    };
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        type: "text",
        Authorization: accessToken,
      },
      data: payload,
    };
    try {
      const response = await axios(config);
      if (response.status === 200) {
        toast.success("Request sent");
      }
    } catch (error) {
      const { response } = error;
      let errorMsg = response?.data?.errors[0]?.description
        .split("_")
        .join(" ");
      toast.error(errorMsg);
    }
  };

  const operatorDetails = async (operatorId) => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/my-operators/details?operatorId=${operatorId}`;
    const accessToken = Cookies.get("accessToken");

    const config = {
      method: "get",
      url: API_URL,
      headers: {
        type: "text",
        Authorization: accessToken,
      },
    };
    try {
      const response = await axios(config);

      setEachOperatorData(response?.data?.data, "operator details");
      setIsShow(true);
    } catch (error) {
      const { response } = error;
    }
  };

  const operatorList = () => {
    return (
      <>
        <div className="row text-white"> 
          <div className="col-md-12 d-flex justify-content-center align-items-center px-3 themeColor">
            <div className="titleWithIcon">
              <i className="fa-solid fa-user-group transactionIcon"></i>
            </div>
            <div className="heading-text">
              <p className="mt-3 section-heading">Operators</p>
            </div>
          </div>

          {operatorListData.length > 0 ? (
            operatorListData?.map((operator) => {
              return (
                <div className="col-12 col-md-6 mx-auto" key={operator?.id}>
                  <div className="operatorCardContainer ">
                    <p className="operatorId">Operator ID: {operator?.id}</p>
                    <p className="operatorCountry">
                      Country: {operator?.operatorLocations[0]?.country?.name}
                    </p>
                    <div className="buttonContainer">
                      <button
                        className="operatorBtn detailsBtn"
                        onClick={() => operatorDetails(operator.id)}
                      >
                        Details
                      </button>
                      <button
                        className="operatorBtn sendRequest"
                        onClick={() => handleAddOperatorRequest(operator.id)}
                        disabled={operator?.requestSent === 1}
                      >
                        {operator?.requestSent === 1
                          ? "Request Sent"
                          : "Send a Request"}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="operatorNotFound">
              <h1>No Operator Found</h1>
            </div>
          )}
        </div>

        <OperatorDetailsPopup
          eachOperator={eachOperatorData}
          isShow={isShow}
          setIsShow={setIsShow}
        />
      </>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchOperatirList();
  };

  {
    /*disabled={!selectedCountry || operatorIdInput} */
  }

  const handleOperatorClose = ()=>{
    setIsShowOper(false);
    setCount(0);  
    setSelectedCountry("")
    setSelectedCountry("")
    setSelectedCity("")
    setStates([])
    setCities([])
  }
 

  return (
    <div
      className={`modal fade ${isShowOper ? "show d-block modal-hide" : ""}`}
      id="operatorModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen-lg-down modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content customModalHeigh">
          <div className="modal-header border-0">
            {count === 1 && (
              <div className="text-white">
                <span onClick={() => setCount(0)}>
                  <i className="fa-solid fa-rotate-left"></i>
                </span>
              </div>
            )}
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleOperatorClose}
            />
          </div>
          <div className="modal-body d-flex justify-content-center">
            <div className="mx-auto">
              {isLoading ? (
                <div className="d-flex justify-content-center align-items-center trendingGameSpinner h-100">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : count === 0 ? (
                searchModalFunction()
              ) : (
                operatorList()
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchOperator;
