import React, { useEffect, useState } from "react";
import EmptyCart from "../../assets/images/images/prize_images/empty_cart.png";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { getMainWalletDetails } from "../../redux/mainWalletSlice";

const Cart = () => {
  const [selectedData, setSelectedData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Initial loading state
  const [isUpdating, setIsUpdating] = useState(false); // Updating state
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleIncrease = async (eachItem) => {
    setIsUpdating(true);
    const updatedData = {
      cartId: eachItem?.id,
      quantity: eachItem?.quantity + 1,
    };

    const API_URL = `${process.env.REACT_APP_BASE_URL}/cart/update`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "put",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: updatedData,
    };
    try {
      const response = await axios(config);
      setToggle(!toggle);
    } catch (error) {
      const { response } = error;
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDecrease = async (eachItem) => {
    setIsUpdating(true);
    const updatedData = {
      cartId: eachItem?.id,
      quantity: eachItem?.quantity > 1 ? eachItem?.quantity - 1 : 1,
    };

    const API_URL = `${process.env.REACT_APP_BASE_URL}/cart/update`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "put",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: updatedData,
    };
    try {
      const response = await axios(config);
      setToggle(!toggle);
    } catch (error) {
      if (
        response?.data?.errors[0]?.description ===
        "Either access token not passed or it is expired"
      ) {
        Cookies.remove("accessToken");
        navigate("/");
      }

      const { response } = error;
    } finally {
      setIsUpdating(false);
    }
  };

  const handleRemove = async (cartId) => {
    setIsUpdating(true);
    const payload = {
      cartId: cartId,
      quantity: 0,
    };

    const API_URL = `${process.env.REACT_APP_BASE_URL}/cart/update`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "put",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: payload,
    };
    try {
      const response = await axios(config);
      setToggle(!toggle);
    } catch (error) {
      const { response } = error;
    } finally {
      setIsUpdating(false);
    }
  };

  const handleRemoveAll = async () => {
    setIsUpdating(true);

    const API_URL = `${process.env.REACT_APP_BASE_URL}/cart/update`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "put",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
    };
    try {
      const response = await axios(config);
      if (response.status === 200) {
        toast.success("All items are removed successfully.");
      }
      setToggle(!toggle);
    } catch (error) {
      const { response } = error; 
      toast.error('Something went wrong, Please try after some time.');
    } finally {
      setIsUpdating(false);
    }
  };

  const getCartListItems = async () => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/cart?pageNo=1&limit=100`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
    };

    try {
      const response = await axios(config);
      setSelectedData(response?.data?.data?.cartItems?.rows);
      setTotalItems(response?.data?.data?.cartItems?.count);
      const totalQuantities = response?.data?.data?.cartItems?.rows?.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.quantity;
        },
        0
      );
      const totalPrices = response?.data?.data?.cartItems?.rows?.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.price * currentValue.quantity;
        },
        0
      );
      setTotalQuantity(totalQuantities);
      setTotalPrice(totalPrices);
    } catch (error) {
      const { response } = error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCartListItems();
    dispatch(getMainWalletDetails());
  }, [toggle]);

  const handleRedeemRequest = async () => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/inventory/add-redeem`;
    const accessToken = Cookies.get("accessToken");
    let selectedPrizeIds = selectedData?.map((prize) => prize.id).join(",");
    const payload = {
      cartIds: selectedPrizeIds,
    };
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: payload,
    };
    try {
      const response = await axios(config);
      setToggle(!toggle);
      toast.success("Your request has been sent.");
      console.log(response, "karan");
    } catch (error) {
      const { response } = error;
      // console.log(response?.data?.errors[0]?.description,"redem error")
      let errorMsg = response?.data?.errors[0]?.description
        .split("_")
        ?.join(" ");
      swal({
        title: errorMsg,
        text: "Oops! It seems you’ve selected more items than your available balance allows. Please remove some items, and remember that the redeemable amount cannot exceed your available balance.",
        icon: "warning",
        type: "warning",
        showCancelButton: false,
        confirmButtonClass: "",
        confirmButtonText: "Ok",
        closeOnConfirm: false,
      });
    }
  };

  return (
    <div className="text-white">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className={`redeemTitle d-flex  ${
                selectedData.length > 0
                  ? "customFlex"
                  : "justify-content-center"
              } `}
            >
              <p className="titleText" onClick={() => navigate("/redeem")}>
                <span className="me-3">
                  <i className="fa-solid fa-cart-shopping"></i>
                </span>{" "}
                Redeem Items
              </p>

         
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center trendingGameSpinner">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-md-8">
              {selectedData?.map((eachItem) => (
                <div
                  className="itemDetails cardDetails row gx-0"
                  key={eachItem?.id}
                >
                  <div className="itemsList p-0 col-md-5 col-lg-6 col-5">
                    <div className="itemImg">
                      <img
                        src={eachItem?.inventory?.imageUrl}
                        alt="item"
                        className="productImage"
                      />
                    </div>
                    <div className="description">
                      <p>{eachItem?.inventory?.name}</p>
                      <p>Price: {parseFloat(eachItem?.price)?.toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="quantify col-md-4 col-lg-3 col-4">
                    <button
                      onClick={() => handleDecrease(eachItem)}
                      disabled={isUpdating}
                    >
                      -
                    </button>
                    <span>{eachItem?.quantity}</span>
                    <button
                      onClick={() => handleIncrease(eachItem)}
                      disabled={isUpdating}
                    >
                      +
                    </button>
                  </div>
                  <div className="removeItem col-md-3 col-lg-3 col-3">
                    <button
                      className="removeBtn"
                      onClick={() => handleRemove(eachItem?.id)}
                      disabled={isUpdating}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>
            {selectedData.length !== 0 && (
              <div className="col-12 col-md-4">
                <div className="redeemItemsDetails">
                  <p className="redeemDetailstitle">Redeem Details</p>
                  <div className="priceDetails">
                    <div className="totalAmount">
                      <p>Total Number of Items: {totalQuantity}</p>
                      <p>Redeem Total : {totalPrice?.toFixed(2)}</p>
                    </div>
                    <div className="requBtn redeemButtonContainer w-100">
                      <button
                        className="sendRequest"
                        onClick={handleRedeemRequest}
                        disabled={isUpdating}
                      >
                        Send Request
                      </button>

                      <button
                        className="removeBtn"
                        onClick={handleRemoveAll}
                        disabled={isUpdating}
                      >
                        Remove All
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {selectedData.length === 0 && (
          <div className="no-item-selected">
            <img src={EmptyCart} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
