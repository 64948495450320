 

const TermsConditions = () => { 
  
    const handleAnchorClick = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }

    };
  return (
    <div className="termsConditon">
      <div className="container">
        <div className="row">
          <h1 className="mainTiltle">
            <span>
              <i className="fa-regular fa-pen-to-square me-2"></i>
            </span>{" "}
            Terms & Condtions
          </h1>
          <div className="col-lg-3 d-none d-lg-block">
            <div className="tncsidebar p-4">
              <a className="termQuestion navigationQues"  onClick={() => handleAnchorClick('1')}>
                <div className="me-2 questionNum">1</div>
                <div className="question">Accounts</div>
              </a>
              <a className="termQuestion navigationQues" onClick={() => handleAnchorClick('2')}>
                <div className="me-2 questionNum">2</div>
                <div className="question">Play for Prizes</div>
              </a>
              <a className="termQuestion navigationQues" onClick={() => handleAnchorClick('3')}>
                <div className="me-2 questionNum">3</div>
                <div className="question">Platform</div>
              </a>
              <a className="termQuestion navigationQues" onClick={() => handleAnchorClick('4')}>
                <div className="me-2 questionNum">4</div>
                <div className="question">Accountability & Laws</div>
              </a>
              <a className="termQuestion navigationQues" onClick={() => handleAnchorClick('5')}>
                <div className="me-2 questionNum">5</div>
                <div className="question">Dispute Resolution & Damages</div>
              </a>
              <a className="termQuestion navigationQues" onClick={() => handleAnchorClick('6')}>
                <div className="me-2 questionNum">6</div>
                <div className="question">Disclaimers</div>
              </a>
              <a className="termQuestion navigationQues" onClick={() => handleAnchorClick('7')}>
                <div className="me-2 questionNum">7</div>
                <div className="question">Indemnification</div>
              </a>
              <a className="termQuestion navigationQues" onClick={() => handleAnchorClick('8')}>
                <div className="me-2 questionNum">8</div>
                <div className="question">Intellectual Property
                </div>
              </a>
              <a className="termQuestion navigationQues" onClick={() => handleAnchorClick('9')}>
                <div className="me-2 questionNum">9</div>
                <div className="question">General Provisions</div>
              </a>
            
            
            </div>
          </div>
          <div className="col-md-12 col-lg-9 col-12 mx-auto text-white">
            <div className="textField p-4">
              <p className="maintitleDescription">
                www.LuckyReels.net (referred to as "we," "us," "our(s)," etc.)
                and its affiliated websites, including related mobile versions,
                collectively known as "LuckyReels." These Terms and Conditions
                establish a legally binding contract that delineates the
                relationship between you, the user (referred to as "you,"
                "your," "yours," etc.), and us in relation to the services we
                offer through LuckyReels (referred to as "Services"). The
                Services encompass a wide range of content, such as text,
                images, graphics, photographs, audio, video, buttons, icons,
                animations, data, messages, games, software, and other materials
                available on LuckyReels (referred to as "Materials"). By
                utilizing the Services, you agree to be bound by these Terms and
                Conditions, which outline the rights and responsibilities of
                both parties.
              </p>
              <p>
                When you visit LuckyReels , you're agreeing to abide by our
                website policies, which include these Terms and Conditions. It's
                important to understand that these Terms and Conditions may be
                subject to changes at any time. Make sure to check back every
                now and then to stay updated. Any changes we make will be
                effective as soon as they're posted.
              </p>
              <p>
                By accessing LuckyReels, you are certifying that you are at
                least 18 years of age, possess the legal capacity to enter into
                and agree to these Terms and Conditions, are utilizing the
                Services voluntarily and for personal enjoyment, will provide
                accurate and complete information, and will comply with the laws
                governing gambling and gaming activities in your jurisdiction.
                It is essential to ensure compliance and maintain transparency
                in our interactions. Thank you for your cooperation and
                understanding.
              </p>
              <p>
                LuckyReels does not offer gambling or betting services, and we
                do not endorse or encourage illegal gambling. Our platform is
                focused on providing entertaining online games that fall within
                the legal exceptions for amusement and recreational services. It
                is important to note that the legality of gambling varies by
                jurisdiction, and it is your responsibility to comply with the
                laws of your state, province, or country. We recommend
                consulting with local authorities or legal advisors to ensure
                compliance with applicable laws before engaging in any online
                gaming activities. Please understand that LuckyReels does not
                provide legal advice on gambling matters. Thank you for your
                understanding and cooperation in adhering to the relevant laws
                and regulations.When it comes to using this service, you're
                taking on all the responsibility and risk. LuckyReels doesn't
                support or endorse any illegal gambling activities. It is
                important to remember that the Services are meant for
                entertainment purposes only. We can't guarantee that the
                Services are legal in your specific area, so it's crucial to be
                aware of and comply with the laws of your jurisdiction.
              </p>
              <div className="detailsSection">
                <div className="answerTerms" id="1">
                  <h5 className="answerTermTitle">1. Accounts</h5>
                  <p className="termsDescription">
                    All individuals are eligible to register for a LuckyReels
                    account and can become a player, provided you meet the
                    requirements outlined in these Terms and Conditions and
                    adhere to them. As a player, you are entitled to enjoy one
                    ninety 90 minutes of free playtime per day. It is important
                    to provide accurate and complete information during
                    registration, and to promptly update any changes to ensure
                    the information remains accurate and complete. Failure to do
                    so may result in the nullification of your account . We
                    reserve the right to terminate your account at any time.
                    Please note that your account to LuckyReels is
                    non-transferable and cannot be assigned, sold, or shared
                    with others. Any unauthorized sharing or transfer of your
                    account will result in joint and several liability for any
                    applicable fees.
                  </p>
                  <p>
                    At www.LuckyReels.net, you have the option to play for free
                    without connecting with a local Operator. However, if you
                    wish to compete for prizes, it is necessary to connect your
                    account with a local operator following the instructions
                    provided on the LuckyReels website. It is your
                    responsibility to adhere to any rules, policies, or terms
                    stipulated by your local operator when participating for
                    prizes. Please be aware that we cannot be held liable for
                    any claims arising from your interaction with a local
                    operator.
                  </p>
                  <p>
                    To delete your account, go to the LuckyReels website main
                    page and you can go through the steps provided. Please note
                    that we reserve the right to suspend or delete your account
                    or any licenses at our discretion, for any reason.
                    Additionally, it's important to be aware that terminated
                    account information may be permanently deleted.
                  </p>
                </div>
                <div className="answerTerms" id="2">
                  <h5 className="answerTermTitle">2. Play for Prizes</h5>
                  <p className="termsDescription">
                    Players who connect with a local operator have the
                    opportunity to participate in 'Play for Prizes' and earn
                    credits. This credit can be redeemed for non-cash prizes
                    with a value of up to $5. It's important to note that the
                    credit won in 'Play for Prizes' games do not hold any cash
                    value and prizes cannot be transferred to others. It is a
                    fun way to earn rewards within the platform. If you have any
                    further questions, feel free to contact us through the
                    LuckyReels website main page. In order to utilize the Play
                    for Prizes feature, it is Important for you to know and
                    understand that game credits requests can only be made
                    directly from your operator. Each operator has specific
                    methods for disbursing game credits.
                  </p>
                  <p>
                    The prizes available through the Play for Prizes option can
                    be viewed in the Redeem tab within your Wallet. Please note
                    that the responsibility for coordinating the delivery or
                    pickup of prizes lies solely with your operators. To redeem
                    a prize, it is necessary to contact your local operator. By
                    playing and using the LuckyReels website you will be
                    accountable for acknowledging and agreeing that lucky reels
                    is not liable for the distribution of prizes won on the
                    platform, and by using the Play for Prizes feature, you
                    release LuckyReels website from any associated liability.
                    Lastly, it's important to note that no prizes are awarded to
                    “Play for Fun” credit.
                  </p>
                </div>
                <div className="answerTerms" id="3">
                  <h5 className="answerTermTitle">3. Platform</h5>
                  <p className="termsDescription">
                    By accessing Lucky Reels and using our services, please
                    understand that we are solely providing you with access to
                    the platform and use of the services we offer. It is your
                    responsibility to have your own access to the internet,
                    hardware, and software. Any fees incurred to access
                    LuckyReels or use the services are your responsibility as
                    well. As a user, you have the ability to access certain
                    public areas of LuckyReels and use the services and
                    materials available there free of charge. We grant you a
                    limited access and use only to those materials provided in
                    the free areas of Lucky Reels for private, non-commercial
                    purposes on a single computer or mobile device. Please note
                    that we reserve the right to limit the amount of materials
                    viewed or services available to you. It's important to
                    understand that your permission to access LuckyReels and use
                    the services and materials does not transfer ownership. You
                    are not permitted to copy or redistribute any materials, and
                    you must take measures to prevent unauthorized access, use,
                    or copying of the materials by others.
                  </p>
                </div>
                <div className="answerTerms" id="4">
                  <h5 className="answerTermTitle">4. Accountability & Laws
                  </h5>
                  <p className="termsDescription">
                    By accepting the terms and conditions, you are acknowledging
                    that your use of LuckyReels, the Services, and the Materials
                    is limited to the purposes explicitly allowed by the terms.
                    Any other use would require obtaining our prior written
                    consent. It's important to adhere to this restriction to
                    ensure compliance with the terms and protect the integrity
                    of the platform.
                  </p>
                  <p>
                    Furthermore, it is essential to comply with all applicable
                    laws and regulations while using the platform. This
                    includes, but is not limited to, laws and regulations
                    related to gambling, intellectual property rights,
                    obscenity, and privacy. It is your responsibility to
                    familiarize yourself with these laws and ensure that your
                    activities on the platform align with them
                  </p>
                  <p>
                    Additionally, it is crucial to respect any court orders that
                    may be applicable to your use of LuckyReels , the Services,
                    and the Materials. Failure to comply with court orders can
                    have legal consequences.
                  </p>
                  <p>
                    It is strictly prohibited to engage in any fraudulent or
                    disruptive behavior while using the platform. This includes
                    manipulating the platform or interfering with its operations
                    or security features. Such actions can negatively impact the
                    experience of other users and undermine the integrity of the
                    platform.Ultimately leading to legal action.
                  </p>
                  <p>
                    LuckyReels is committed to cooperating fully with law
                    enforcement authorities and complying with court orders. If
                    we receive a request or order to disclose the identity or
                    location of a user who has breached the Terms and
                    Conditions, we will do so in accordance with our privacy
                    policies, subpoena compliance policies, and applicable laws
                    and regulations.
                  </p>
                  <p>
                    In the event that your activity on LuckyReels results in us
                    incurring expenses, court costs, or legal fees due to a
                    subpoena, discovery request, production order, or court
                    order, we may request reimbursement for these expenses,
                    costs, or legal fees. This is to ensure that the burden of
                    such expenses is appropriately allocated.
                  </p>
                </div>
                <div className="answerTerms" id="5">
                  <h5 className="answerTermTitle">
                    5. Dispute Resolution & Damages
                  </h5>
                  <p className="termsDescription">
                    These Terms and Conditions dictate that all matters
                    pertaining to them shall be governed by the laws of Texas,
                    excluding any conflicts of law provisions. It further
                    specifies that any disputes must be exclusively resolved in
                    Hidalgo County, Texas, without any exceptions. Both parties
                    agree that any actions or proceedings arising from these
                    Terms and Conditions or any services or business
                    interactions covered by them shall be brought solely in
                    Hidalgo County, Texas. The parties also acknowledge that
                    this choice of venue and forum is mandatory and cannot be
                    altered, preventing litigation in any other jurisdiction. We
                    will further waive any right to assert the doctrine of forum
                    non-conveniens or similar doctrines, and agree to the
                    personal jurisdiction of the courts in Hidalgo county ,
                    Texas. Additionally, the parties consent to accept legal
                    documents through registered or certified mail, Federal
                    Express, or Priority Mail. Any final judgment rendered in an
                    action or proceeding will be conclusive and enforceable in
                    the courts located in Hidalgo County, Texas or other
                    jurisdictions as permitted by law, if necessary
                  </p>
                  <p>
                    Class Action Waiver,You renounce any right or capability to
                    initiate a class action or collective proceeding. Rights to
                    Injunctive Relief: Both parties recognize that legal
                    remedies may be insufficient to fully compensate an
                    aggrieved party in the event of a breach by the other party.
                    Therefore, an aggrieved party is entitled to seek injunctive
                    relief in addition to other available legal or equitable
                    remedies. Provisions with Stipulated Liquidated Damages:
                    Within these Terms and Conditions, we have specified
                    predetermined amounts of liquidated damages to be imposed as
                    penalties if you violate these particular provisions. You
                    explicitly agree to pay these amounts. By doing so, you
                    acknowledge that this sum is not a penalty, and that the
                    actual damages are uncertain and difficult to determine.
                    However, this amount reflects the parties' sincere attempt
                    to calculate appropriate compensation based on anticipated
                    actual damages.If it becomes necessary for us to engage the
                    services of a lawyer, investigator, collections agent, or
                    any other individual to recover any liquidated damages or
                    any other monetary amount from you, or if we need to involve
                    a lawyer to pursue injunctive relief against you, you also
                    acknowledge and accept the responsibility to reimburse us
                    for all the expenses incurred in collecting these damages or
                    seeking injunctive relief from you. You are aware that even
                    a small amount of damages may require significant legal
                    fees, travel expenses, and other costs that may far exceed
                    the actual or liquidated damages themselves. You hereby
                    agree to bear these fees and costs.
                  </p>
                </div>
                <div className="answerTerms" id="6">
                  <h5 className="answerTermTitle">6. Disclaimers</h5>
                  <p className="termsDescription">
                    LuckyReels and the Services are provided "as is" and "with
                    all faults," without any warranties. This means that we do
                    not make any express, statutory, or implied warranties
                    regarding the suitability, performance, accuracy, security,
                    or uninterrupted access of LuckyReels or the Services. We
                    also do not guarantee that your use of LuckyReels or the
                    Services will be error-free or that you will not experience
                    any content loss. Additionally, we do not provide warranties
                    for any goods or services obtained through the Services or
                    any transactions made through them. It is important to note
                    that these disclaimers apply to the fullest extent permitted
                    by applicable law, and no warranties extend beyond the terms
                    stated in these Terms and Conditions.
                  </p>
                  <p>
                    LuckyReels reserves the right to modify or remove any
                    information found on the Services without prior notice.
                    LuckyReels does not guarantee the updating of Materials or
                    the Services. When accessing LuckyReels and using the
                    Services, you do so at your own risk. LuckyReels cannot
                    guarantee that the platform or the Services will be free
                    from viruses, worms, Trojan horses, or other potentially
                    harmful code. LuckyReels does not assume any responsibility
                    or liability for your internet access or use of the
                    Services. Any Materials downloaded or obtained through
                    LuckyReels are done at your own discretion and you are
                    solely responsible for any resulting damage to your computer
                    system or loss of data
                  </p>
                  <p>
                    LuckyReels does not assume responsibility for any services
                    advertised or offered by third parties through their website
                    or any linked websites. We do not endorse or guarantee these
                    services and have no control over them. LuckyReels is not
                    involved in monitoring transactions between users and
                    third-party providers. As with any transaction, it is
                    important to exercise caution and use your best judgment. By
                    using third-party links on LuckyReels, you agree to hold
                    LuckyReels unaccountable from any damages or liability that
                    may arise. The use of confidential or private information by
                    sellers or third parties is not the responsibility of
                    LuckyReels . The terms and conditions of those third parties
                    govern your use of their links and services. LuckyReels
                    reserves the right to terminate any link at any time.
                  </p>
                  <p>
                    LuckyReels takes violations of the law very seriously. If
                    determined that you have engaged in any activities that
                    violate the law, they will immediately terminate your access
                    to their platform. LuckyReels also disclaims any liability
                    for damages that may arise from your participation in
                    illegal activities. By using our services, you agree to
                    defend, indemnify, and hold LuckyReels unacceptable from any
                    liability that may arise if you violate the law.
                    Additionally, you agree to defend and indemnify LuckyReels
                    if any third party is harmed by your illegal actions or if
                    LuckyReels is required to defend against any claims related
                    to your actions. It's important to always abide by the law
                    and use LuckyReels responsibly.
                  </p>
                </div>
                <div className="answerTerms" id="7">
                  <h5 className="answerTermTitle">7. Indemnification</h5>
                  <p className="termsDescription">
                    By accepting the terms and conditions, you agree to
                    indemnify, defend, and hold LuckyReels , its officers,
                    directors, shareholders, employees, independent contractors,
                    telecommunication providers, and agents harmless from any
                    and all claims, actions, losses, liabilities, expenses,
                    costs, or demands. This includes legal and accounting fees,
                    arising directly or indirectly from your actions or the
                    actions of another person under your authority. LuckyReels
                    will promptly notify you via email of any such claims or
                    suits, and they may choose to cooperate in the defense, at
                    your expense. While LuckyReels has the right to participate
                    in the defense, they are not obligated to do so. It's
                    important to be aware of these obligations outlined in the
                    Terms and Conditions
                  </p>
                  <p>
                    By Agreeing to the Terms and Conditions, you are essentially
                    discharging, acquitting, and releasing LuckyReels , its
                    parent company, agents, employees, officers, directors,
                    shareholders, attorneys, and affiliates from any and all
                    allegations, counts, charges, debts, causes of action, and
                    claims that may arise from your use of LuckyReels. This
                    includes claims related to negligence, gross negligence,
                    reckless conduct, alienation of affections, intentional
                    infliction of emotional distress, intentional interference
                    with contract or advantageous business relationship,
                    defamation, privacy, publicity, intellectual property
                    infringement, misrepresentation, financial loss not due to
                    LuckyReels fault, missed meetings, unmet expectations, false
                    identities, fraudulent acts by others, invasion of privacy,
                    release of personal information, failed transactions,
                    unavailability or inaccessibility of LuckyReels or its
                    functions, and even claims based on vicarious liability for
                    torts committed by individuals met on or through LuckyReels
                    , such as fraud, theft or misuse of personal information,
                    assault, battery, stalking, harassment, cyber-bullying,
                    rape, theft, cheating, perjury, manslaughter, or murder.
                  </p>
                  <p>
                    It is important to understand that this list is not
                    exhaustive, meaning it's not a complete and final list of
                    all possible claims. It is just meant to give you an idea of
                    the types of claims that are being released by agreeing to
                    the terms. The intention behind this release is to provide
                    LuckyReels with broad protection and to ensure that any
                    ambiguity in the agreement is interpreted in a way that
                    provides the broadest release of claims.
                  </p>
                  <p>
                    Both parties, meaning you and LuckyReels, acknowledge the
                    legally binding nature of this provision and understand the
                    rights that are being given up as a result.
                  </p>
                  <p>
                    LuckyReels does not accept liability or responsibility for
                    the following:
                  </p>
                  <ol>
                    <li>
                      {" "}
                      Any errors, mistakes, or inaccuracies in the materials
                      provided on their platform
                    </li>
                    <li>
                      Any personal injury or property damage that may occur as a
                      result of using their services.
                    </li>
                    <li>
                      . Unauthorized access to or alterations of user accounts,
                      transmissions, or data by third parties.
                    </li>
                    <li>
                      Interruptions or cessation of transmission to or from
                      their platform
                    </li>
                    <li>
                      {" "}
                      Any bugs, viruses, malware, Trojan horses, or similar
                      harmful elements that may be transmitted through their
                      platform.
                    </li>
                    <li>
                      Incompatibility issues between their platform and other
                      services, hardware, or software.
                    </li>
                    <li>
                      Delays or failures in initiating, conducting, or
                      completing any transmissions or transactions on their
                      platform.
                    </li>
                    <li>
                      Loss of credits, or prizes associated with their platform.
                    </li>
                    <li>
                      Any loss or damage incurred due to the use of the
                      materials provided on their platform.
                    </li>
                  </ol>
                </div>
                <div className="answerTerms" id="8">
                  <h5 className="answerTermTitle">8. Intellectual Property</h5>
                  <p className="termsDescription">
                    LuckyReels holds the trademarks and brand names of
                    LuckyReels and LuckyReels.net . We are committed to
                    vigorously protecting our intellectual property rights. We
                    acknowledge that other manufacturers may have their own
                    trademarks and service marks mentioned on their platform,
                    which are the exclusive property of those respective owners.
                    Public use of these trademarks and service marks without the
                    express written consent of the owners or holders is strictly
                    prohibited. LuckyReels asserts that our marks, logos,
                    domains, and trademarks cannot be used publicly without
                    obtaining their explicit written permission. Furthermore,
                    any usage that may cause confusion among consumers or
                    disparage and discredit LuckyReels is strictly prohibited.
                    It is essential to respect and uphold LuckyReels brand
                    integrity and intellectual property rights. At LuckyReels ,
                    we want to emphasize that the materials found on our
                    platform are our valuable intellectual property. We hold all
                    the rights, title, and interest in these materials.
                    Copyright law protects LuckyReels, our materials, and our
                    software.
                  </p>
                  <p>
                    It's important to understand that without our prior written
                    consent, these materials cannot be copied, distributed,
                    republished, modified, uploaded, posted, or transmitted in
                    any way. Any modification or use of the materials, unless
                    expressly provided in our Terms and Conditions, would be a
                    violation of our intellectual property rights.
                  </p>
                </div>
                <div className="answerTerms" id="9">
                  <h5 className="answerTermTitle">9. General Provisions</h5>
                  <p className="termsDescription">
                    Lucky reels, wants to make it clear that our platform and
                    services are intended solely for entertainment and
                    recreational purposes. We strive to provide a fun and
                    enjoyable experience for our users. However, it is essential
                    to understand that any attempt to use LuckyReels for illegal
                    or tortious activities is strictly prohibited.
                  </p>
                  <p>
                    We take the safety and integrity of our platform seriously.
                    If we become aware of any user engaging in or facilitating
                    such activities, we may take appropriate action, including
                    banning the user and reporting them to the relevant law
                    enforcement agency.
                  </p>
                  <p>
                    Please note that the information provided in this section
                    does not limit the scope of any releases or indemnification
                    mentioned elsewhere in our Terms and Conditions
                  </p>
                  <p>
                    Our primary goal is to ensure a safe and entertaining
                    environment for all our users. The Terms and Conditions,
                    along with any other legal notice or agreement published by
                    LuckyReels on our platform, constitute the entire agreement
                    between you and us regarding your use of LuckyReels. This
                    means that these terms override any previous terms,
                    understandings, or agreements between you and us.
                  </p>
                  <p>
                    It's important to note that a printed version of these Terms
                    and Conditions, as well as any notice provided in electronic
                    form, can be used as evidence in any legal proceedings
                    related to these terms. They hold the same weight and are
                    subject to the same conditions as other business documents
                    and records originally created and maintained in printed
                    form.
                  </p>
                  <p>
                    As a service provider, we may engage third-party service
                    providers to offer LuckyReels to our users. It's important
                    to note that when using their services, you'll need to agree
                    to and comply with their terms and conditions, privacy
                    policy, or any other policies they may have in place. In the
                    event of any conflict between the policies of these
                    third-party service providers and our own policies, our
                    policies will prevail and govern the situation. We strive to
                    ensure that our policies are in line with your best
                    interests and provide a consistent experience.
                  </p>
                  <p>
                    We have the ability to assign any rights or delegate any
                    responsibilities under these Terms and Conditions without
                    giving you prior notice. Car However, you are not allowed to
                    assign, delegate, or sublicense any of your rights or duties
                    without our written consent in advance. If you attempt to do
                    so without our consent, it will be considered void.
                  </p>
                  <p>
                    If any provision within these Terms and Conditions is
                    determined to be invalid, illegal, or unenforceable, the
                    remaining provisions will continue to be fully effective, as
                    long as the essential terms for each party remain valid,
                    binding, and enforceable. We strive to ensure that the core
                    elements of our agreement remain intact.
                  </p>
                  <p>
                    All the rights and remedies provided in these Terms and
                    Conditions are cumulative and not exclusive. This means that
                    if a party asserts a specific right or remedy, it doesn't
                    prevent them from asserting any other rights or seeking
                    additional remedies available to them. They can still pursue
                    other options under the law, in equity, by statute, under
                    any other agreement between the parties, or through any
                    other means.
                  </p>
                  <p>
                    These Terms and Conditions are designed to benefit and bind
                    not only the parties involved but also their respective
                    successors and assigns. However, it's important to note that
                    this section does not directly or indirectly address whether
                    a party can assign its rights or delegate its performance
                    under these Terms and Conditions. This means that the
                    specific conditions for assigning rights or delegating
                    performance are not covered in this section.
                  </p>
                  <p>
                    We are not responsible for any failure to perform if it's
                    due to unforeseen circumstances or causes beyond our
                    reasonable control. These may include Acts of God like fire,
                    flood, earthquakes, hurricanes, tropical storms, or other
                    natural disasters. It can also include pandemics, war, riot,
                    arson, embargoes, acts of civil or military authority, or
                    terrorism. Additionally, factors like fiber cuts, strikes,
                    shortages in transportation, facilities, fuel, energy,
                    labor, or materials can also impact our ability to perform.
                    Any failure of the telecommunications or information
                    services infrastructure, hacking, spam, data breach,
                    malware, or failure of a computer, server, network, or
                    software can also cause delays. We are not responsible for
                    unlawful acts committed by our employees, agents, or
                    contractors.
                  </p>
                  <p>
                    Any notice required to be given by us under these Terms and
                    Conditions can be provided via email to a valid email
                    address of the party to be notified, through a general
                    posting on LuckyReels. Customers can give notices to us by
                    contacting us on the LuckyReels main website under Contact
                    Us . Either party can change the designated address, email
                    address, or recipient for any notice by providing notice to
                    the other party.
                  </p>
                  <p>
                    LuckyReels does not offer a feature for sending or receiving
                    private or confidential electronic communications. Any
                    messages you send through LuckyReels are considered
                    accessible to the general public. So, it's not recommended
                    to use LuckyReels for transmitting any communication that
                    you want to keep strictly between you and the intended
                    recipient. It's important to note that the agents and
                    operators of LuckyReels may have access to and read all
                    messages entered into the platform, even if they're not the
                    intended recipients.
                  </p>
                  <p>
                    By using LuckyReels, you grant us permission to send you
                    emails containing notices, advertisements, and other
                    communications. This authorization will remain in effect
                    until you request to be removed from our email list. It's
                    important to note that even if you receive unsolicited email
                    correspondence from us or our affiliates, it does not fall
                    under the legal definition of spam.
                  </p>
                  <p>
                    By using LuckyReels, you get access and permission to use
                    the platforms , services, and materials. In return, you
                    agree to abide by all the provisions in these Terms and
                    Conditions. We believe that this arrangement is fair and
                    reasonable. You acknowledge that this consideration is
                    sufficient and is received when you view or download any
                    part of LuckyReels .
                  </p>
                  <p>
                    When you transmit any affirmation, assent, or agreement
                    through LuckyReels , you agree to be bound by our policy or
                    any terms on this site. In the future, when you click on an
                    "I agree," "I consent," or similar button, check box, or
                    entry field using your mouse, keystroke, or other computer
                    device, your agreement or consent will be legally binding
                    and enforceable. Your digital agreement is equally binding
                    as your handwritten signature
                  </p>
                  <p>
                    Periodically, we may make revisions to these Terms and
                    Conditions. We retain the unilateral right to do so, and you
                    acknowledge and agree to this. It is important to note that
                    any modifications or changes to these Terms and Conditions
                    are immediately in force and legally binding upon their
                    posting. The updated or edited version supersedes any prior
                    versions as soon as it is posted, and any previous versions
                    hold no legal effect unless specifically referenced and
                    maintained in effect.
                  </p>
                  <p>
                    We also make a commitment that if we make any changes to
                    these Terms and Conditions, we will update the "Last
                    Updated" date on the document. It is your responsibility to
                    revisit this web page regularly and utilize the "Refresh"
                    button on your browser to ensure you are accessing the most
                    recent version. Additionally, you are encouraged to take
                    note of the date of the last revision to these Terms and
                    Conditions.
                  </p>
                  <p>
                    If the "Last Updated" date remains unchanged from your last
                    review, you may presume that no alterations have been made.
                    However, if the "Last Updated" date has changed, it
                    indicates that modifications have been implemented. In such
                    cases, you are obligated to thoroughly review the revised
                    Terms and Conditions in their entirety and agree to their
                    terms. Failure to do so will require you to immediately
                    cease using any websites covered by these Terms and
                    Conditions. If you do not take the time to review the
                    amended Terms and Conditions as required, it is important to
                    understand that you are assuming responsibility for this
                    omission. By failing to review the updated terms, you are
                    effectively waiving your right to do so. It is essential to
                    be ware that we cannot be held responsible for any neglect
                    on your part to exercise your legal rights. We encourage you
                    to take the time to review any changes made to the Terms and
                    Conditions to ensure you are fully informed.
                  </p>
                  <p>
                    We have drafted these Terms and Conditions and our
                    associated website policies in the English and Spanish
                    language. By accessing and using our services, you are
                    acknowledging and agreeing to the English and Spanish
                    language version of these terms as they are published.
                    Please note that we cannot be held liable for any costs or
                    expenses incurred in translating these Terms and Conditions.
                    If you choose to translate them, you do so at your own risk,
                    as only the English And Spanish language version is legally
                    binding.
                  </p>
                  <p>
                    It is crucial to understand and acknowledge that the
                    software elements of the materials on LuckyReels may be
                    subject to regulation by governmental agencies. These
                    regulations prohibit the export or diversion of software and
                    other goods to certain countries and third parties. It is
                    strictly prohibited to engage in any activities that would
                    violate these laws and regulations. By using LuckyReels ,
                    you agree not to assist or participate in any such diversion
                    or violation of applicable laws. Furthermore, you agree that
                    you will not license or otherwise permit anyone who is not
                    approved to receive controlled commodities under the
                    applicable laws and regulations. It is important to abide by
                    these laws and regulations and ensure that none of the
                    materials are acquired, shipped, transferred, or
                    re-exported, directly or indirectly, to proscribed or
                    embargoed countries or their nationals, or be used for
                    proscribed activities.
                  </p>
                  <p>
                    These Terms and Conditions make it clear that there is no
                    partnership, employment, joint venture, or formal business
                    relationship between the parties. The rights and obligations
                    are only as expressly stated in the terms. It's important to
                    understand the limitations and boundaries set forth in these
                    terms.
                  </p>
                  <p>
                    When we talk about a law, it includes not only that law but
                    also any updated versions and the rules that go along with
                    it.
                  </p>
                  <p>
                    - When we mention a specific time period, saying "from" or
                    "commencing on" means it starts on that date. And when we
                    say "to," "until," or "ending on," it means it stops right
                    before that date.
                  </p>
                  <p>
                    - If we refer to a government agency, it also includes any
                    regulatory body that takes over its functions.
                  </p>
                  <p>
                    - When we say "A or B," it means either A, B, or both. And
                    when we say "A, B, or C," it means one or more of A, B, and
                    C. The same idea applies to longer lists.
                  </p>
                  <p>
                    - Lastly, when we use the term "including," it means we're
                    giving some examples, but there could be more.
                  </p>
                  <p>
                    Any waiver or action taken by us should not be interpreted
                    as a waiver of any subsequent default of the same provision
                    in these Terms and Conditions. In the event that a court of
                    competent jurisdiction declares any term, clause, or
                    provision of these Terms and Conditions as invalid or
                    unenforceable, it will not affect the validity or operation
                    of any other term, clause, or provision. The invalid term,
                    clause, or provision will be considered severed from these
                    Terms and Conditions.
                  </p>
                  <p>
                    The inclusion of headings in these Terms and Conditions is
                    solely for the purpose of convenience and reference. They do
                    not alter the meaning, construction, or effect of the Terms
                    and Conditions themselves. The headings are provided to help
                    users navigate the document more easily, but they do not
                    carry any legal weight or significance. The actual content
                    and provisions of the Terms and Conditions are what govern
                    the rights and obligations of the parties involved
                  </p>
                  <p>
                    It is important to understand that we cannot guarantee the
                    suitability or availability of LuckyReels or the Services in
                    other locations. If individuals choose to access LuckyReels
                    or the Services from territories where it may be illegal or
                    prohibited, they do so at their own discretion and are
                    solely responsible for ensuring compliance with local laws.
                    It's essential to recognize that these Terms and Conditions
                    do not imply that LuckyReels is subject to the laws of any
                    nation other than the United States. It's always advisable
                    to be aware of and abide by the laws of your specific
                    location.
                  </p>
                  <p>
                    In the event of a malfunction during gameplay, it may result
                    in the voiding of the play. Additionally, there are specific
                    limits in place for winnings and prize redemption. It is
                    important to acknowledge that LuckyReels retains the right
                    to adjust player’s game credit or main wallet balance
                    without prior notice. When adding an operator to play, it is
                    done at your own risk, and the request of play credits or
                    redemption of prizes is the sole responsibility of the
                    operator. It's crucial to understand that LuckyReels cannot
                    be held responsible for any lost credits, play credit
                    purchases, or prize redemptions
                  </p>
                  <p>
                    It's important to understand that the availability of
                    LuckyReels may vary depending on your location.LuckyReels
                    operates under the laws of the state, province, city,
                    country, or other legal entity where you reside or access
                    the website. If LuckyReels is prohibited or restricted by
                    law in your jurisdiction, then the website is considered
                    void in that area. If you open an account or use LuckyReels
                    in a prohibited jurisdiction, it would be a violation of
                    both the law and the Terms and Conditions, which could
                    result in the suspension or termination of your account
                    without notice. It's crucial to understand that LuckyReels
                    will not be held liable if the laws applicable to you
                    restrict or prohibit your participation. LuckyReels does not
                    make any implicit or explicit representations or warranties
                    regarding your legal right to participate in any services
                    offered on the platform. Additionally, no person affiliated
                    with LuckyReels has the authority to make such
                    representations or warranties. LuckyReels reserves the right
                    to restrict access to the platform in any jurisdiction
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
