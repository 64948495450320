import React, { useState, useEffect } from "react";
import Search from "../../assets/images/images/operator/serachoperator.png";
import PhoneInput from "react-phone-input-2";
import Cookies from "js-cookie";
import axios from "axios";
import {  toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ApplyOperator = ({ applyOper, setApplyOper }) => {
  const [formError, setFormError] = useState({});
  const [phone, setPhone] = useState("");

  const initialValues = {
    firstName: "",
    lastName: "",
    birthDate: "",
    phoneNumber: "",
    email: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    physicalAddress: "",
    additionalIfno: "",
  };
  const [operatorData, setOperatorData] = useState(initialValues); 
  const [phoneValid,setPhoneValid] = useState(false)
  const onBlurHandler = (event) => {
    const fieldName = event.target.name;
    const fieldValue = operatorData[fieldName];
    let err = { ...formError };
    if (fieldValue === "" && fieldName !== "additionalIfno") {
      err[fieldName] = "Required!";
    } else if (fieldName === "email") {
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!regex.test(fieldValue)) {
        err[fieldName] = "Email not valid!";
      } else {
        delete err[fieldName];
      }
    } else if (fieldName==="phoneNumber" ) {
      if(!phoneValid){
        err[fieldName] = "Invalid phone number!";

      }
    } else if (fieldName === "zipCode" && fieldValue.length < 5) {
      err[fieldName] = "Invalid Zipcode";
    } else {
      delete err[fieldName];
    }
    setFormError({ ...err });
  };
  const validateForm = (e) => {
    let err = {};
    if (operatorData.firstName === "") {
      err.firstName = "Required!";
    } else if (operatorData.firstName) {
      if (operatorData.firstName.length < 3) {
        err.firstName = "First name should contain at least 3 character.";
      } else if (operatorData.firstName.length > 20) {
        err.firstName = "First name can't be more than 20 character.";
      }
    }
    if (operatorData.lastName === "") {
      err.lastName = "Required!";
    } else if (operatorData.lastName) {
      if (operatorData.lastName.length < 3) {
        err.lastName = "Last name should contain at least 3 character.";
      } else if (operatorData.lastName.length > 20) {
        err.lastName = "Last name can't be more than 20 character.";
      }
    }
    if (operatorData.birthDate === "") {
      err.birthDate = "Required!";
    }
    if (operatorData.phoneNumber === "") {
      err.phoneNumber = "Required!";
    } else if (operatorData.phoneNumber) {
      if(!phoneValid){

        err.phoneNumber = "Invalid phone number";
      }
    }
    if (operatorData.email === "") {
      err.email = "Required!";
    }
    if (operatorData.country === "") {
      err.country = "Required!";
    }
    if (operatorData.state === "") {
      err.state = "Required!";
    }
    if (operatorData.city === "") {
      err.city = "Required!";
    }
    if (operatorData.zipCode === "") {
      err.zipCode = "Required!";
    } else if (operatorData.zipCode.length < 5) {
      err.zipCode = "Invalid zipcode!";
    }
    if (operatorData.physicalAddress === "") {
      err.physicalAddress = "Required!";
    }
    if(operatorData.email===""){
      err.email= "Required!";
      
    }else if(operatorData.email){
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!regex.test(operatorData.email)) { 
        err.email = "Email not valid!";
      } else {
        delete err["email"];
      }
    
    }

    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };
  const handleOnchange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.name.trimStart();
    let err = { ...formError };
    delete err[fieldName];

    let regName = /^[a-zA-Z]*$/;
    if (fieldName === "firstName" || fieldName === "lastName") {
      if (!regName.test(e.target.value)) {
        return;
      }
    }

    if (fieldName === "phoneNumber" && isNaN(e.target.value)) {
      return;
    }
    if (fieldName === "zipCode" && isNaN(e.target.value)) {
      return;
    }
    operatorData["country"] = selectedCountry;
    operatorData["state"] = selectedState;
    operatorData["city"] = selectedCity;
    setOperatorData((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value.trimStart(),
    }));
    setFormError({ ...err });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      becomOperatorRequest();
      setOperatorData(initialValues);
      setPhone("");
      setSelectedCountry("");
      setSelectedState("");
      setSelectedCity(""); 
    }
  };

  // date calendar set age 18 yrs above
  const [maxDate, setMaxDate] = useState("");
  useEffect(() => {
    let dtToday = new Date();
    let month = (dtToday.getMonth() + 1).toString().padStart(2, "0");
    let day = dtToday.getDate().toString().padStart(2, "0");
    let year = dtToday.getFullYear() - 18;
    setMaxDate(`${year}-${month}-${day}`);
  }, []);

  /****** Region List API ********** */
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  /** Store country, state and city data** */
  const [countryName, setCountryName] = useState("");
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const navigate = useNavigate()

  let countryValue = "";
  let stateValue = "";

  useEffect(() => {
    
    const accessToken = Cookies.get("accessToken")
    if(accessToken){

      fetchCountryList();
    }
  }, []);

  const fetchCountryList = async () => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/region/country`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        type: "text",
        Authorization: accessToken,
      },
    };
    try {
      const response = await axios(config); 
      setCountries(response?.data?.data?.countries);
    } catch (error) {
      if(error?.response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
        Cookies.remove("accessToken");
        navigate("/")
      }
      const { response } = error;
    }
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    setFormError({...formError, country:""})
    countryValue = e.target.value;
    operatorData.country = countryValue
    setSelectedState("");
    setSelectedCity("");
    setStates([]);
    setCities([]);
    fetchStateList();
  };

  const fetchStateList = async () => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/region/state?countryId=${countryValue}`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        type: "text",
        Authorization: accessToken,
      },
    };
    try {
      const response = await axios(config); 
      setStates(response?.data?.data?.states?.rows);
    } catch (error) {
      const { response } = error;
    }
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    setFormError({...formError, state:""})
    stateValue = e.target.value;
    operatorData.state = stateValue
    setSelectedCity("");
    fetchCities();
  };
  const handleCityChange = (e)=>{
    setSelectedCity(e.target.value)
    operatorData.city=e.target.value
    setFormError({...formError, city:""})

  }

  const fetchCities = async () => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/region/city?stateId=${stateValue}`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        type: "text",
        Authorization: accessToken,
      },
    };
    try {
      const response = await axios(config);
      setCities(response?.data?.data?.cities?.rows);
    } catch (error) {
      const { response } = error;
    }
  };

  const becomOperatorRequest = async () => {
    const selectedCountryObject = countries.find(
      (country) => country.id == Number(selectedCountry)
    );
    const selectedStateObject = states?.find(
      (state) => Number(state?.id) == Number(selectedState)
    );
    const selectedCityObject = cities?.find(
      (city) => Number(city?.id) == Number(selectedCity)
    );

    const payload = {
      firstName: operatorData.firstName,
      lastName: operatorData.lastName,
      email: operatorData.email,
      dob: operatorData.birthDate,
      phone: phone,
      countryName: selectedCountryObject?.name,
      stateName: selectedStateObject?.name,
      cityName: selectedCityObject?.name,
      zipCode: operatorData.zipCode,
      address: operatorData.physicalAddress,
      information: operatorData.additionalIfno,
    };
    const API_URL = `${process.env.REACT_APP_BASE_URL}/user/become-operator`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: payload,
    };
    try {
      const response = await axios(config);
      if (response.status === 200) {
        toast.success("Request sent successfully");
      }
    } catch (error) {
      let errorMsg = error?.response?.data?.errors[0]?.description
        .split("_")
        .join(" ");
      toast.error(errorMsg);
    }
  };
  const handlePhoneChange = (value, country)=>{
    setPhone(value)
    setOperatorData({...operatorData,phoneNumber: value })
    setFormError({...formError, phoneNumber:""})
    let validNum =  value.length - country.dialCode.length >= 10 
    setPhoneValid(validNum) 
  }

  const handleClosePopup = ()=>{
    setApplyOper(false)
    setFormError({})
    setOperatorData(initialValues)
    setSelectedCity("")
    setSelectedCountry("")
    setSelectedState("")
  }

  

  return (
    <div
      className={`modal fade ${applyOper ? "show d-block" : ""}`}
      id="applyModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      {" "} 
      <div className="modal-dialog modal-fullscreen-lg-down modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClosePopup}
            />
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-8 mx-auto">
                <div className="row">
                  <div className="col-md-12 col-lg-12 d-flex justify-content-center align-items-center mx-auto">
                    <div className="icon">
                      <img src={Search} className="mt-1" alt="find-operator" />
                    </div>
                    <div className="heading-text">
                      <p className="mt-3 section-heading">
                        Operator Application
                      </p>
                    </div>
                  </div>
                </div>
                <form onSubmit={handleSubmit} className="applyOperator">
                  <div className="row">
                    <div className="col-md-6 ">
                      <div className="user-input mt-2">
                        <label
                          htmlFor="inputState"
                          className="form-label text-white mt-3"
                        >
                          First Name*
                        </label>
                        <input
                          className="form-control user-input"
                          name="firstName"
                          type="text"
                          placeholder="Enter First Name"
                          aria-label="first-name"
                          maxLength={20}
                          onBlur={onBlurHandler}
                          onChange={handleOnchange}
                          value={operatorData.firstName}
                        />
                        <span className="errorMsg">{formError.firstName}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user-input mt-2">
                        <label
                          htmlFor="inputState"
                          className="form-label text-white mt-3"
                        >
                          Last Name*
                        </label>
                        <input
                          className="form-control user-input"
                          name="lastName"
                          maxLength={20}
                          type="text"
                          placeholder="Enter Last Name"
                          aria-label="Last-name"
                          onBlur={onBlurHandler}
                          onChange={handleOnchange}
                          value={operatorData.lastName}
                        />
                        <span className="errorMsg">{formError.lastName}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="user-input mt-2">
                        <label
                          htmlFor="inputState"
                          className="form-label text-white mt-3"
                        >
                          Date of Birth*
                        </label>
                        <input
                          className="form-control user-input"
                          name="birthDate"
                          type="date"
                          placeholder="mm/dd/yyyy"
                          aria-label="dob"
                          onChange={handleOnchange}
                          onBlur={onBlurHandler}
                          value={operatorData.birthDate}
                          id="operatorDob"
                          max={maxDate}
                        />
                        <span className="errorMsg">{formError.birthDate}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user-input mt-2">
                        <label
                          htmlFor="inputState"
                          className="form-label text-white mt-3"
                        >
                          Phone Number*
                        </label>
                        <PhoneInput
                          country={"us"}
                          name="number"
                          className="newPhoneInput"
                          value={phone}
                          onChange={handlePhoneChange}
                        />
                        <span className="errorMsg">
                          {formError.phoneNumber}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="user-input mt-2">
                        <label
                          htmlFor="inputState"
                          className="form-label text-white mt-3"
                        >
                          Email Address*
                        </label>
                        <input
                          className="form-control user-input"
                          name="email"
                          type="text"
                          placeholder="Enter Email Address"
                          aria-label="Enter Email Address"
                          maxLength={30}
                          onChange={handleOnchange}
                          onBlur={onBlurHandler}
                          value={operatorData.email}
                        />
                        <span className="errorMsg">{formError.email}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user-input mt-2">
                        <label
                          htmlFor="inputState"
                          className="form-label text-white mt-3"
                        >
                          Country*
                        </label>
                        <select
                          id="inputState"
                          className="form-select editProfileCountry"
                          value={selectedCountry}
                          name="country"
                          onChange={(e) => handleCountryChange(e)}
                        >
                          <option value="">Select Country</option>
                          {countries?.map((country) => (
                            <option value={country?.id} key={country?.id}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                        <span className="errorMsg">{formError.country}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="user-input mt-2">
                        <label
                          htmlFor="inputState"
                          className="form-label text-white mt-3"
                        >
                          State*
                        </label>{" "}
                        <select
                          id="inputState"
                          className="form-select editProfileCountry"
                          value={selectedState}
                          onChange={(e) => handleStateChange(e)}
                        >
                          <option value="">Select State</option>
                          {states.map((state) => (
                            <option key={state?.id} value={state?.id}>
                              {state?.name}
                            </option>
                          ))}
                        </select>
                        <span className="errorMsg">{formError.state}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user-input mt-2">
                        <label
                          htmlFor="inputState"
                          className="form-label text-white mt-3"
                        >
                          City*
                        </label>
                        <select
                          id="inputState"
                          className="form-select editProfileCountry"
                          name="city"
                          value={selectedCity}
                          onChange={(e) => handleCityChange(e)}
                        >
                          <option value="">Select City</option>
                          {cities.map((city) => (
                            <option key={city?.id} value={city?.id}>
                              {city?.name}
                            </option>
                          ))}
                        </select>
                        <span className="errorMsg">{formError.city}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="user-input mt-2">
                        <label
                          htmlFor="inputState"
                          className="form-label text-white mt-3"
                        >
                          Zipcode*
                        </label>
                        <input
                          className="form-control user-input"
                          name="zipCode"
                          type="text"
                          placeholder="Enter Zipcode"
                          aria-label="Zipcode"
                          maxLength={6}
                          onChange={handleOnchange}
                          onBlur={onBlurHandler}
                          value={operatorData.zipCode}
                        />
                        <span className="errorMsg">{formError.zipCode}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user-input mt-2">
                        <label
                          htmlFor="inputState"
                          className="form-label text-white mt-3"
                        >
                          Physical Address*
                        </label>
                        <input
                          className="form-control user-input"
                          name="physicalAddress"
                          type="text"
                          placeholder="Enter Physical Address"
                          aria-label="add"
                          onChange={handleOnchange}
                          onBlur={onBlurHandler}
                          value={operatorData.physicalAddress}
                        />
                        <span className="errorMsg">
                          {formError.physicalAddress}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="user-input mt-2">
                        <label
                          htmlFor="inputState"
                          className="form-label text-white mt-3"
                        >
                          Please feel free to add any additional information{" "}
                        </label>
                        <textarea
                          className="form-control text-area"
                          name="additionalIfno"
                          rows={3}
                          onChange={handleOnchange}
                          onBlur={onBlurHandler}
                          style={{ resize: "none" }}
                          value={operatorData.additionalIfno}
                        />
                        <span className="errorMsg">
                          {formError.additionalIfno}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-grid gap-2 mt-4 signin-btn ">
                    <button className="btn btn-sign" type="submit">
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyOperator;
