import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component"; 
import MoreDetailsPopup from "../UserProfilePopUps/MoreDetailsPopup";
import { showMoreDetailsLastActivity } from "../../redux/counterSlice";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const LastActivity = () => {
  const [selectedActivity, setSelectedActivity] = useState({});
  const [lastActivityData, setLastActivityData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  const [totalPost, setTotalPost] = useState(10);
  const [postFrom, setPostFrom] = useState(1);
  const [postTo, setPostTo] = useState(10);

  const navigate = useNavigate();

  const handleMoreInfo = (details) => {
    setSelectedActivity(details);
    dispatch(showMoreDetailsLastActivity(true));
  };

  const columns = [
    {
      name: "Round ID	",
      minWidth: "161px",
      selector: (row) => row?.transactionId?.split("_")?.join(""),
    },
    {
      name: "Date",
      selector: (row) => {
        const timestamp = row.createdAt;
        const date = new Date(timestamp);

        const formattedDate = date.toLocaleDateString();
        return formattedDate;
      },
    },
    {
      name: "Time",
      selector: (row) => {
        const timestamp = row.createdAt;
        const date = new Date(timestamp);

        const formattedTime = date.toLocaleTimeString();
        return formattedTime;
      },
    },
    {
      name: "Operator",
      selector: (row) => row?.wallet?.operatorId,
    },
    {
      name: "Game",
      minWidth: "137px",
      selector: (row) => row?.casinoGame?.name,
    },
    {
      name: "Bet",
      selector: (row) => <div>{row?.casinoBet?.amount?.toFixed(2)}</div>,
    },
    {
      name: "Type",
      selector: (row) => {
        return (
          (row.actionType === "casino-win" && (
            <div style={{ color: "green" }}>Win</div>
          )) ||
          (row.actionType === "casino-loss" && (
            <div style={{ color: "red" }}>Loss</div>
          )) ||
          (row.actionType === "casino-bet" && (
            <div style={{ color: "#bc9a6a" }}>Bet</div>
          ))
        );
      },
    },
    {
      name: "Result",
      selector: (row) => (
        <div style={{ color: `${row?.amount > 0 ? "green" : "red"}` }}>
          {row?.amount?.toFixed(2)}
        </div>
      ),
    },
    {
      name: "Balance",
      selector: (row) => <div>{row?.afterBalance?.toFixed(2)}</div>,
    },
    {
      name: "More Info",
      selector: (row) => (
        <div
          className="moreInfo headet-btn"
          onClick={() => handleMoreInfo(row)}
        >
          <i className="fa-solid fa-eye"></i>
        </div>
      ),
    },
  ];

  const tableCustomStyles = {
    headRow: {
      style: {
        color: "#fff",
        backgroundColor: "#292521;",
        fontSize: "18px",
      },
    },
    rows: {
      style: {
        color: "#fff",
        backgroundColor: "#292521;",
        minHeight: "60px",
        fontSize: "16px",
        whiteSpace: "unset",
      },
    },
    pagination: {
      style: {
        backgroundColor: "#292521;",
        color: "white",
      },
    },
  };

  const getLastActivityData = async () => {
    setIsLoading(true);
    const API_URL = `${process.env.REACT_APP_BASE_URL}/casino/transactions?limit=${postPerPage}&pageNo=${currentPageNum}`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
    };

    try {
      const response = await axios(config); 
      setLastActivityData(response?.data?.data?.casinoTransaction?.rows);
      setIsLoading(false);
      setTotalPost(Number(response?.data?.data?.casinoTransaction?.count));
    } catch (error) {
      if (
        error?.response?.data?.errors[0]?.description ===
        "Either access token not passed or it is expired"
      ) {
        Cookies.remove("accessToken");
        navigate("/");
      }

      const { response } = error;
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const accessToken = Cookies.get("accessToken");
    if (accessToken) {
      getLastActivityData();
    }
  }, [currentPageNum, postPerPage]);
  const setNextPage = () => {
    if (currentPageNum < Math.ceil(totalPost / postPerPage)) {
      setCurrentPageNum((prev) => prev + 1);
      setPostFrom(postFrom + postPerPage);
      setPostTo((prev) => prev + postPerPage);
    }
  };
  const setPrevPage = () => {
    if (currentPageNum > 1) {
      setCurrentPageNum((prev) => prev - 1);
      setPostFrom((prev) => prev - 10);
      setPostTo((prev) => prev - 10);
    }
  };
  const handlePostPerPage = (e) => {
    const value = parseInt(e.target.value);
    setPostPerPage(value);
    setCurrentPageNum(1);
    setPostFrom(1);
    setPostTo(value);
  };

  const gotoFirstPage = () => {
    setCurrentPageNum(1);
    setPostFrom(1);
    setPostTo(10);
  };

  const gotoLastPage = () => {
    setCurrentPageNum(Math.ceil(totalPost / postPerPage));
    setPostTo(totalPost);

    let endPagePost = totalPost - postPerPage;
    let startFrom = totalPost - endPagePost;
    setPostFrom(startFrom * (Math.ceil(totalPost / postPerPage) - 1) || 1);
  };

  return (
    <div className="container bg_img">
      <div className="row">
        <div className="col-lg-12 myOperatorBr">
          <div className="tableContainer mt-3 latestActivity lastActivityTable lastActivity">
            <div className="col-md-12 d-flex justify-content-center align-items-center px-3 themeColor">
              <div className="titleWithIcon">
                <i className="fa-solid fa-layer-group transactionIcon"></i>
              </div>
              <div className="heading-text">
                <p className="mt-3 section-heading">Last Activity</p>
              </div>
            </div>

            <div className="data-table dataTableTextAlign customDataTableClass">
              {isLoading && (
                <div className="d-flex justify-content-center align-items-center trendingGameSpinner lastActivityTabCus">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}

              {!isLoading && (
                <DataTable
                  columns={columns}
                  data={lastActivityData}
                  customStyles={tableCustomStyles}
                  fixedHeader
                />
              )}
              {!isLoading && totalPost >0 && (
                <div className="paginationMainContainer">
                  <div className="text-white rowPerPage">
                    Row Per Page :{" "}
                    <select
                      className="ms-3 ps-2 pe-2 pt-1 pb-1 paginationSelect"
                      value={postPerPage}
                      onChange={(e) => handlePostPerPage(e)}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                    </select>
                  </div>

                  <div className="text-white rowPerPage">
                    {postFrom}-{postTo} of {totalPost}
                  </div>
                  <div className="navIconContainer">
                    <span onClick={() => gotoFirstPage()}>
                      |<i className="fa-solid fa-chevron-left"></i>
                    </span>
                    <span onClick={setPrevPage}>
                      <i className="fa-solid fa-chevron-left"></i>
                    </span>
                    <span onClick={setNextPage}>
                      <i className="fa-solid fa-chevron-right"></i>
                    </span>
                    <span onClick={() => gotoLastPage()}>
                      <i className="fa-solid fa-chevron-right"></i>|
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <MoreDetailsPopup moreDetails={selectedActivity} />
    </div>
  );
};

export default LastActivity;
