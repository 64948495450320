import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import Banner from "../assets/images/images/banner/banner.jpg"; 
import { useDispatch, useSelector } from "react-redux";
import { toggleNavbar } from "../redux/counterSlice";
import BrokenImg from "../assets/images/images/broken image/no_img.png";
import Cookies from "js-cookie";
import axios from "axios";
import swal from "sweetalert";
import { Modal } from "react-bootstrap-v5";
import { getMainWalletDetails } from "../redux/mainWalletSlice";
import GameCredit from '../components/GameCredit/GameCredit' 
import GameCreditSidebar from "../components/GameCredit/GameCreditSidebar";
import { useNavigate } from "react-router-dom";

const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  speed: 1000,
  autoplay: true,
};

const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
};

const HomePage = () => {
  const [searchInput, setSearchinput] = useState("");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postCount, setPostCount] = useState(0);
 const navigate = useNavigate()
  const [postPerPage, setPostPerPage] = useState(100);

  const [gameUrl, setGameUrl] = useState();

  const [showIframe, setShowIfram] = useState(false);
  const [gameListData, setGameListData] = useState([]);
  const [isgameLaunch, setIsGameLaunch] = useState(false);
  const [showAddCreditModal, setAddShowCreditModal] = useState(false);
  const { mainWalletDetails } = useSelector((state) => state.mainWallet);
  const [availabeCredit, setAvailableCredit] = useState(0);

  const [selectedAmount, setSelectedAmount] = useState(0);
  const [launchGameID, setLaunchGameId] = useState();


  const { showCredit } = useSelector((state) => state.counter);

  const { launchGameUrl } = useSelector((state) => state.gameData);

  

  const getGameListData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/casino/all-games?pageNo=${
          searchInput ? 1 : currentPage
        }&limit=${200}&search=${searchInput}`
      );
      const newData = response?.data.data.categoryGames.rows;
      setPostCount(response?.data?.data?.categoryGames?.count);

      setGameListData((prevData) => {
        if (searchInput || currentPage === 1) {
          // If search input is present or it's the first page, replace the data
          return newData;
        } else {
          // Otherwise, concatenate new data with existing data, filtering out duplicates
          return prevData.concat(
            newData.filter(
              (item) =>
                !prevData.some(
                  (prevItem) => prevItem.casinoGameId === item.casinoGameId
                )
            )
          );
        }
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 200 &&
        !isLoading &&
        postPerPage < postCount
      ) {
        setCurrentPage((prevPage) => prevPage + 1);
        setPostPerPage((prevPost) => prevPost + 100);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading, postPerPage]);

  useEffect(() => {
    getGameListData();
  }, [currentPage, searchInput]);

  const imageOnError = (event) => {
    event.currentTarget.src = BrokenImg;
  };

  const handleLaunchGame = async (gameId) => {
    setIsGameLaunch(true);
    let data = {
      gameId,
      demo: true,
    };
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/casino/launch-game`,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: data,
    };
    try {
      const response = await axios(config);
      setShowIfram(true);
      dispatch(toggleNavbar(false));
      if (response?.data?.data?.staus === 200) {
      }
      setGameUrl(response?.data?.data?.data?.URL);
      setTimeout(() => {
        setIsGameLaunch(false);
      }, 800);

      return response.data;
    } catch (error) {
      setIsGameLaunch(false);
      swal({
        title: "Please Sign In!",
        icon: "warning",
        type: "warning",
        showCancelButton: false,
        confirmButtonClass: "",
        confirmButtonText: "Ok",
        closeOnConfirm: false,
      }).then((willDelete) => {
        if (willDelete) {
          Cookies.remove("accessToken");
          window.location.reload();
        }
      });
    }
  };

  const handleLaunchPrizeGame = async (gameId) => {
    setIsGameLaunch(true);
    let data = {
      gameId: gameId !== null ? gameId : launchGameID,
      demo: false,
      amount:
        mainWalletDetails?.gameWallets?.find((eachGame) => eachGame.gameId == gameId)?.amount ||
        selectedAmount,
    };
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/casino/launch-game`,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: data,
    };
    try {
      const response = await axios(config);
      dispatch(toggleNavbar(false)) 
      if(response?.data?.data?.status===200){
        setShowIfram(true);

        setGameUrl(response?.data?.data?.data?.URL);
      }
      if(response?.data?.data?.status===400 && response?.data?.data?.message!=="Request failed with status code 403"){
        swal({
          title: "Insufficient Balance",
          text: "Your session has expired due to insufficient balance. Please log in and add credit to continue playing the game.",
          icon: "warning",
          type: "warning",
          showCancelButton: false,
          confirmButtonClass: "",
          confirmButtonText: "Ok",
          closeOnConfirm: false,
        }).then((willDelete) => {
          if (willDelete) {
            Cookies.remove("accessToken");
            window.location.reload(); 
          }
        });
      }
      if(response?.data?.data?.status===400 && response?.data?.data?.message==="Request failed with status code 403"){
        swal({
          title: "Something went wrong",
          text: "Please try again later after sometime.",
          icon: "warning",
          type: "warning",
          showCancelButton: false,
          confirmButtonClass: "",
          confirmButtonText: "Ok",
          closeOnConfirm: false,
        })
      }



      setTimeout(() => {
        setIsGameLaunch(false);
      }, 800);

      return response.data;
    } catch (error) {
      setIsGameLaunch(false); 

      swal({
        title: "Please Sign In!",
        icon: "warning",
        type: "warning",
        showCancelButton: false,
        confirmButtonClass: "",
        confirmButtonText: "Ok",
        closeOnConfirm: false,
      }).then((willDelete) => {
        if (willDelete) {
          Cookies.remove("accessToken");
          window.location.reload(); 
        }
      });
    }
  };
  

  const handleCloseGame = () => {
    setShowIfram(false);
    setGameUrl("");
    dispatch(toggleNavbar(true));
  };

  useEffect(() => {
    const accessToken = Cookies.get("accessToken"); 
    if (accessToken) {
      dispatch(getMainWalletDetails()); 
    }
  }, [showIframe,showAddCreditModal]);

  const handleSearch = debounce((value) => {
    value = value.trimStart();
    if (value) {
      setSearchinput(value);
    } else {
      setSearchinput("");
      setGameListData([]);
    }
  }, 500);

  const handleClose = () => {
    setAddShowCreditModal(false);
    setSelectedAmount(0);
  };

  const handleSelectAmount = (value) => {
    const temp_sum = value + selectedAmount;
    if (temp_sum <= availabeCredit) {
      setSelectedAmount((prev) => prev + value);
    } else {
      swal({
        title: "Selected amount can't be more than available balance.",
        icon: "warning",
        type: "warning",
        showCancelButton: false,
        confirmButtonClass: "",
        confirmButtonText: "Ok",
        closeOnConfirm: false,
      });
    }
  };

  const handleConfirm = () => {
    setAddShowCreditModal(false);
    handleLaunchPrizeGame(launchGameID);
    setSelectedAmount(0);
  };

  const handleAddCreditModal = (gameId) => {
    let accessToken = Cookies.get("accessToken");

    if (accessToken) {
      setLaunchGameId(gameId);
      setAddShowCreditModal(true);
    } else {
      swal({
        title: "Please Sign In!",
        icon: "warning",
        type: "warning",
        showCancelButton: false,
        confirmButtonClass: "",
        confirmButtonText: "Ok",
        closeOnConfirm: false,
      }).then((willDelete) => {
        if (willDelete) {
          Cookies.remove("accessToken");
          window.location.reload();
        }
      });
    }
  };

  const getMailWalletBalance = async () => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/user/get-wallets`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
    };

    try {
      const response = await axios(config);
      setAvailableCredit(response?.data?.data?.wallets?.amount);
    } catch (error) {}
  };

  useEffect(() => {
    const accessToken = Cookies.get("accessToken");
    if (accessToken) {
      getMailWalletBalance();
    }
  }, [showIframe]);

  return (
    <>
    
      {showIframe && (
        <div className="container gobackBtn">
          {isgameLaunch && (
            <div className="gameLaunchLoader">
              <div className="d-flex justify-content-center align-items-center trendingGameSpinner">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          )}
          <div className="row position-relative">
            <div className="set_goBackBtn">
              <button
                onClick={handleCloseGame}
                className="goBackBtn btn desktopBackBtn"
              >
                Go Back
              </button>
              <button
                onClick={handleCloseGame}
                className="goBackBtn mobileBackBtn"
              >
                <span>
                  <i className="bi bi-arrow-return-left "></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className={`${
          showIframe === true ? "d-block" : "d-none"
        } "wrapper custom-margin-top-mob"`}
      >
        <div
          className={`${
            window.innerWidth < 992 ? "container-fluid" : "container"
          } innerBackgroundColor iframeDiv`}
        >
          <div className="iframeCustomContainer">
            <iframe
              src={gameUrl}
              className="iframeInner"
              scrolling="no"
            ></iframe>
          </div>
        </div>
      </div>
      <div
        className={`${
          showIframe === true ? "d-none" : "d-block"
        } wrapper custom-margin-top-mob`}
      >
        <div className="container innerBackgroundColor pb-3">
          <div className="hero-banner">
            <div className="main-img">
              <Slider {...settings}>
                <div className="sliderCard">
                 {/* <img src={Banner} alt="banner" className="sliderImg" />*/}
                  <div className="sliderOverlay">
                 
                  <div className="sliderBtnContainer" style={{color:"white"}}>  
                  <h3 className="mainTitle">ARE YOU A VENDOR OR<br/> OPERATOR ?</h3>
                  <p className="descriptions">We offer you the best solution.</p>
                <div className="facilitiesContainer">
                <div className="facilityCard">
                  <span className="facilityIcon">
                  <i className="fa-solid fa-headset"></i>
                  </span>
                  <p className="facilityDescription">24/7 OPERATION & CUSTOMER SUPPORT</p>
                </div>
                <div className="facilityCard">
                <span className="facilityIcon">
                <i className="fa-solid fa-hand-holding-dollar"></i>
                </span>
                <p className="facilityDescription">MINIMUM OPERATING COST</p>
              </div>
              <div className="facilityCard">
              <span className="facilityIcon">
              <i className="fa-solid fa-list-check"></i>
              </span>
              <p className="facilityDescription">FULL CONTROL AT ANYWHERE AT ANYTIME</p>
            </div>
            <div className="facilityCard">
            <span className="facilityIcon">
            <i className="fa-solid fa-users"></i>
            </span>
            <p className="facilityDescription">UNLIMITED ACTIVE PLAYERS</p>
          </div>
                
                </div>
                <div className="facilityButtonContainer">
                <button className="facitlityBtn applyNow" onClick={()=>navigate("/operators")}>Apply now</button>
                <button className="facitlityBtn learnMore">Learn more</button>
                </div>
                  </div>
                  </div> 
                </div>
                <div className="sliderCard">
                {/* <img src={Banner} alt="banner" className="sliderImg" />*/}
                 <div className="sliderOverlay">
                
                 <div className="sliderBtnContainer" style={{color:"white"}}>  
                 <h3 className="mainTitle">ARE YOU A VENDOR OR <br/> OPERATOR?</h3>
                 <p className="descriptions">We offer you the best solution.</p>
               <div className="facilitiesContainer">
               <div className="facilityCard">
                 <span className="facilityIcon">
                 <i className="fa-solid fa-headset"></i>
                 </span>
                 <p className="facilityDescription">24/7 OPERATION & CUSTOMER SUPPORT</p>
               </div>
               <div className="facilityCard">
               <span className="facilityIcon">
               <i className="fa-solid fa-hand-holding-dollar"></i>
               </span>
               <p className="facilityDescription">MINIMUM OPERATING COST</p>
             </div>
             <div className="facilityCard">
             <span className="facilityIcon">
             <i className="fa-solid fa-list-check"></i>
             </span>
             <p className="facilityDescription">FULL CONTROL AT ANYWHERE AT ANYTIME</p>
           </div>
           <div className="facilityCard">
           <span className="facilityIcon">
           <i className="fa-solid fa-users"></i>
           </span>
           <p className="facilityDescription">UNLIMITED ACTIVE PLAYERS</p>
         </div>
               
               </div>
               <div className="facilityButtonContainer">
               <button className="facitlityBtn applyNow" onClick={()=>navigate("/operators")}>Apply now</button>
               <button className="facitlityBtn learnMore">Learn more</button>
               </div>
                 </div>
                 </div> 
               </div>
              
              </Slider>
            </div>
          </div>
          <div className="trendigGames-header">
            <div className="searchbarContainer">
              <span>
                <i className="fa-solid fa-magnifying-glass"> </i>
              </span>
              <input
                type="text"
                placeholder="Search Game..."
                className="searchInputField"
                onKeyDown={(e) => {
                  if (e.key === " " && e.target.selectionStart === 0) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div> 
          </div>
          <div className="row m-0 p-0">
            <div className="trend-games">
              <div className="col-md-12 game-section">
                <div className="row cards-section m-0 customGameCard">
                  {gameListData?.map((eachItem) => {
                    if (
                      window.innerWidth <= 768 &&
                      eachItem?.devices?.isMobile === true
                    ) {
                      return (
                        <div
                          className="col-4 col-md-3 col-lg-2 col-xl-2 overlay-container"
                          key={eachItem?.casinoGameId}
                        >
                          <div className="gamesposter">
                            <img
                              src={eachItem.thumbnailUrl !==null ? eachItem?.thumbnailUrl: BrokenImg}
                              className="game-card"
                              alt="game-banner"
                              width="100%"
                              height="100%"
                              onError={imageOnError}
                            />

                            <div className="overlay overlay-fade">
                              <div className="gameTitle">{eachItem.name}</div>
                              <div className="play-now" >
                                <button
                                  type="button"
                                  className="btn play-btn text-nowrap mt-4 gameCardBtn"
                                  onClick={() => {
                                    const gameWallet =
                                      mainWalletDetails?.gameWallets?.find(
                                        (v) =>
                                          v?.gameId == eachItem?.casinoGameId
                                      ); 
                                    if (gameWallet) {
                                      handleLaunchPrizeGame(
                                        eachItem?.casinoGameId
                                      );
                                    } else {
                                      handleAddCreditModal(
                                        eachItem?.casinoGameId
                                      );
                                    }
                                  }}
                                >
                                  Play For Prizes
                                </button>
                              </div>
                              <div className="text">
                                <button
                                  type="button"
                                  className="btn header-btn text-nowrap mt-4 playDemoBtn gameCardBtn"
                                  onClick={() =>
                                    handleLaunchGame(eachItem?.casinoGameId)
                                  }
                                  disabled={!eachItem.demo}
                                >
                                  Play For Fun
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    } else if (
                      window.innerWidth > 768 &&
                      eachItem?.devices?.isDesktop === true
                    ) {
                      return (
                        <div
                          className="col-4 col-md-3 col-lg-2 col-xl-2 overlay-container"
                          key={eachItem.casinoGameId}
                        >
                          <div className="gamesposter">
                            <img
                               src={eachItem.thumbnailUrl !==null ? eachItem?.thumbnailUrl: BrokenImg}
                              className="game-card"
                              alt="game-banner"
                              width="100%"
                              height="100%"
                              onError={imageOnError}
                            />

                            <div className="overlay overlay-fade">
                              <div className="gameTitle">{eachItem.name}</div>
                              <div className="play-now" >
                                <button
                                  type="button"
                                  className="btn play-btn text-nowrap mt-4 gameCardBtn"
                                  // onClick= { ()=>handleAddCreditModal(eachItem.casinoGameId)}
                                  onClick={() => {
                                    const gameWallet =
                                      mainWalletDetails?.gameWallets?.find(
                                        (v) =>
                                          v?.gameId == eachItem?.casinoGameId
                                      ); 
                                    if (gameWallet) {
                                      handleLaunchPrizeGame(
                                        eachItem?.casinoGameId
                                      );
                                    } else {
                                      handleAddCreditModal(
                                        eachItem?.casinoGameId
                                      );
                                    }
                                  }}
                                >
                                  Play For Prizes
                                </button>
                              </div>
                              <div className="text">
                                <button
                                  type="button"
                                  className="btn header-btn text-nowrap mt-4 playDemoBtn gameCardBtn"
                                  href={launchGameUrl}
                                  onClick={() =>
                                    handleLaunchGame(eachItem.casinoGameId)
                                  }
                                  disabled={!eachItem?.demo}
                                >
                                  Play For Fun
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}

                  {isLoading && (
                    <div className="d-flex justify-content-center align-items-center trendingGameSpinner">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 p-0">
          <div className="row">
            <div className="col-md-12 copyright">
              <p className="copyright-text">
                © Copyright 2023, All Rights Reserved{" "}
                <span className="brand-name">LuckyReels</span>
              </p>
            </div>
          </div>
        </div>
      
      </div>
      <Modal
        show={showAddCreditModal}
        onHide={handleClose}
        size="xl"
        className="login-modal modalClosebtn d-flex align-items-center"
      >
        <Modal.Header closeButton className="btn-close-white"></Modal.Header>
        <Modal.Body className="customFlexClass addCreditPopUp row  m-0">
          <div className="col-md-7 mx-auto ">
            <div className="creditTitle mb-3">
              {" "}
              <span className="creditIcon">
                <i className="fa-regular fa-money-bill-1"></i>
              </span>{" "}
              Add Credit
            </div>

            <div className="availableCredits mb-5 text-center">
              Available Balance: <span>{availabeCredit?.toFixed(2) || 0}</span>
            </div>

            <div className="selectAmount w-100 mb-5">
              {selectedAmount === 0
                ? "Select Amount"
                : selectedAmount?.toFixed(2)}
            </div>
            <div className="amountButton mb-5">
              <button
                className="amountBtn"
                onClick={() => handleSelectAmount(1)}
              >
                +1.00
              </button>
              <button
                className="amountBtn"
                onClick={() => handleSelectAmount(5)}
              >
                +5.00
              </button>
              <button
                className="amountBtn"
                onClick={() => handleSelectAmount(10)}
              >
                +10.00
              </button>
              <button
                className="amountBtn"
                onClick={() => handleSelectAmount(20)}
              >
                +20.00
              </button>
              <button
                className="amountBtn"
                onClick={() => handleSelectAmount(50)}
              >
                +50.00
              </button>
              <button
                className="amountBtn allBtn"
                onClick={() => handleSelectAmount(availabeCredit)}
              >
                All
              </button>
            </div>
            <div className="totalAmount mb-3">
              Total: <span>{selectedAmount?.toFixed(2)}</span>
            </div>
            <div className=" signin-btn text-center">
              <button className="btn btn-sign w-100" onClick={handleConfirm} disabled={selectedAmount===0}>
                Confirm
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div>
      <GameCredit handleLaunchPrizeGame={handleLaunchPrizeGame}/>
     {showCredit && <GameCreditSidebar handleLaunchPrizeGame={handleLaunchPrizeGame}/>}
      </div>
    </>
  );
};

export default HomePage;
