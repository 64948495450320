import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import { getUserData } from "../../../redux/signUpSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import {setShowSingupPop} from "../../../redux/counterSlice"

export function SignVerification({ setStep, setShowfirst, seThowSecond }) {
  const [firstStep, setFirstStep] = useState(true);
  const [showSecondStep, setShowSecondStep] = useState(false);
  const [showThirdstep, setShowThirdStep] = useState(false);
  const [phone, setPhone] = useState("");
  const { singupApiResponse } = useSelector((state) => state.singUpData);
  const [phoneValid,setPhoneValid] = useState(false)
  const navigate = useNavigate()
  const initialValues = {
    username: "",
    number: "",
    codeConfirmation: false,
    ageConfirm: false,
  };
  const [signupFormData, setSingupFormData] = useState(initialValues);
  const [formError, setFormError] = useState({ initialValues });
  const onBlurHandler = (event) => {
    const fieldName = event.target.name;
    const fieldValue = signupFormData[fieldName];
    let err = { ...formError };
    if (fieldValue === "") {
      err[fieldName] = "Required!";
    } else if (fieldName === "username") {
      if (fieldValue.length < 6) {
        err[fieldName] = "Username should contain atleast 6 character";
      }
    } else if (fieldName === "password") {
      let regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;
      if (!regex.test(fieldValue)) {
        err[fieldName] = "Password not valid!";
      } else {
        delete err[fieldName];
      }
    } else if (fieldName === "number") {
      if (!phoneValid) {
        err[fieldName] = "Invalid phone number";
      }
    } else if (fieldName === "codeConfirmation") {
      if (fieldValue === false) {
        err[fieldName] = "Required!";
      } else {
        delete err[fieldName];
      }
    } else if (fieldName === "ageConfirm") {
      if (fieldValue === false) {
        err[fieldName] = "Required!";
      } else {
        delete err[fieldName];
      }
    } else {
      delete err[fieldName];
    }
    setFormError({ ...err });
  }; 

  const handleOnchange = (e) => {
    
    const fieldName = e.target.name;
    let err = { ...formError };
    delete err[fieldName];
    setFormError({ ...err });
    if (fieldName === "number" && isNaN(e.target.value)) {
      return;
    }
    if (fieldName === "username" && (e.target.value.includes("@") || e.target.value.includes(" "))) {
      return ;
    }
    
    setSingupFormData((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }));
  };
  const validateForm = () => {
    let err = {};
    if (signupFormData.username === "") {
      err.username = "Required!";
    }
    if (signupFormData.number === "") {
      err.number = "Required!";
    } else if (!phoneValid) {
      
      err.number = "Invalid phone number";
    }
    if (signupFormData.codeConfirmation === false) {
      err.codeConfirmation = "Required!";
    }
    if (signupFormData.ageConfirm === false) {
      err.ageConfirm = "Required!";
    }
    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };
  const dispatch = useDispatch();
  
  const handleFirstStep = () => {
    dispatch(getUserData(signupFormData));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const valid = validateForm();
    if (valid) {
      setFirstStep(false);
      handleFirstStep();
      setShowfirst(false);
      seThowSecond(true); 
    
    }
  };

  const handleTnC = ()=>{
    navigate("/t&c")
    dispatch(setShowSingupPop(false))
  }

  const handlePhoneChange = (value,country) =>{
    setSingupFormData({...signupFormData,number: value})
    
    let validNum =  value.length - country.dialCode.length >= 10
    setPhoneValid(validNum)
    
  }

  return (
    <div className="row signupScreen popClr">
      <div className="col-12 col-md-6 col-lg-8 customSingInSignup singUpFormPopUp">
        {firstStep &&  (
          <div>
            <div className="welcome mt-3">
              <p className="welcome-text">
                <span>
                  <i className="fa-solid fa-file-lines signIcon"></i>
                </span>{" "}
                <span className="text-white">Sign Up</span>
              </p>
            </div>
            <div className="sign-text">
              <p className="sign-in formFontWeigth">
                Please create a username and confirm your phone number to get
                started with sign up
              </p>
            </div>
            <form onSubmit={handleSubmit} className="singUpPopup">
              <div className="user-input mt-2 mb-4">
                <input
                  className="form-control user-input"
                  type="text"
                  placeholder="Username"
                  aria-label="Username"
                  value={signupFormData.username}
                  name="username"
                  onBlur={onBlurHandler}
                  onChange={handleOnchange}
                  maxLength={20}
                />
                <span className="errorMsg">{formError.username}</span>
              </div>

              <div className="user-input mt-2">
                <div className="password-input phoneNumberInput flagdropDwon">
                  <PhoneInput
                    country={"us"}
                    name="number"
                    className="newPhoneInput"
                    value={phone}
                    onChange={handlePhoneChange}
                    onBlur={onBlurHandler}
                  />
                </div>
                <span className="errorMsg">{formError.number}</span>
              </div>
              <div className="mt-4 form-check">
                <div className="col  mt-3">
                  <div className="rememberme">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="checkbox1"
                      checked={signupFormData.codeConfirmation}
                      onBlur={onBlurHandler}
                      onChange={handleOnchange}
                      name="codeConfirmation"
                    />
                    <label
                      className="form-check-label label-text formFontWeigth"
                      htmlFor="checkbox1"
                    >
                      I agree to receive a verification code via SMS.
                    </label>
                  </div>
                </div>
              </div>
              <span className="errorMsg">{formError.codeConfirmation}</span>
              <div className="form-check">
                <div className="col  mt-3">
                  <div className="rememberme mt-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="checkbox2"
                      checked={signupFormData.ageConfirm}
                      onBlur={onBlurHandler}
                      onChange={handleOnchange}
                      name="ageConfirm"
                    />
                    <label
                      className="form-check-label label-text formFontWeigth"
                      htmlFor="checkbox2"
                    >
                      I confirm that I am at least 21 years of age and I accept
                      the{" "}
                      <span className="brand-name singUpTirms" onClick={handleTnC}>
                        Terms &amp; Conditions.
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <span className="errorMsg">{formError.ageConfirm}</span>

              <div className="d-grid gap-2 signin-btn">
                {!showSecondStep && (
                  <button
                    className="btn btn-sign"
                    data-bs-target="#signupsecondModal"
                    data-bs-toggle="modal"
                    type="submit"
                  >
                    Set Password
                  </button>
                )}
              </div>
            </form>
          </div>
        )}

        {showThirdstep && (
          <ThirdStep
            setShowSecondStep={setShowSecondStep}
            setShowThirdStep={setShowThirdStep}
          />
        )}

        {showSecondStep && singupApiResponse?.status === 200 && <SecondStep />}
      </div>
    </div>
  );
}
