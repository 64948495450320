import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <div className='notFound'>
    <div className='container'>
    <div className='row'>
      <div className='col-12'>
      <div className='details'>
      <h1 className='message mb-3'>Page Not Found</h1>
      <h1 className='errorCode mb-3'>404</h1>
      <button className='goTohomeBtn' onClick={()=> navigate("/")}>Back To Home</button>
      </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default NotFound
