import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {  toast } from "react-toastify";

const ChangePassword = () => {
  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };
  const [currentPassword, setCurrentPassword] = useState(false);
  const [newPassowrd, setNewPassword] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState(false);
  const navigate = useNavigate()

  const [details, setDetails] = useState(initialValues);
  const [formError, setFormError] = useState({ initialValues });

  const onBlurHandler = (event) => {
    const fieldName = event.target.name;
    const fieldValue = details[fieldName];
    let err = { ...formError };

    if (fieldValue === "") {
      err[fieldName] = "Required!";
    } else if (
      fieldName === "newPassword" ||
      fieldName === "confirmNewPassword"
    ) {
      if (fieldValue.length < 8) {
        err[fieldName] =
          "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, and be at least 8 characters long!";
      }
    } else if (fieldName === "newNum") {
      if (isNaN(fieldValue)) {
        err[fieldName] = "Invalid Number";
      }
    } else if (fieldName === "checkboxAgree") {
      if (fieldValue === false) {
        err[fieldName] = "Required!";
      } else {
        delete err[fieldName];
      }
    } else {
      delete err[fieldName];
    }
    setFormError({ ...err });
  };
  const validateForm = () => {
    let err = {};
    if (details.currentPassword === "") {
      err.currentPassword = "Required!";
    }
    if (details.newPassword === "") {
      err.newPassword = "Required!";
    } else {
      if (!isValidPassword(details.newPassword)) {
        err.newPassword =
          "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, and be at least 8 characters long!";
      }
    }
    if (details.confirmNewPassword === "") {
      err.confirmNewPassword = "Required!";
    } else {
      if (details.newPassword !== details.confirmNewPassword) {
        err.confirmNewPassword = "Password not matched!";
      } else if (!isValidPassword(details.newPassword)) {
        err.confirmNewPassword =
          "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, and be at least 8 characters long!";
      }
    }

    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
    return passwordRegex.test(password);
  };
  const handleChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    let err = { ...formError };
    delete err[fieldName];

    if (
      fieldName === "currentPassword" ||
      fieldName === "newPassword" ||
      fieldName === "confirmNewPassword"
    ) {
      if (fieldValue.length > 16) {
        return false;
      }
    }

    setDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setFormError({ ...err });
  };

  const handlePasswordChangeApiCall = async () => {
    const payload = {
      password: details?.currentPassword,
      newPassword: details?.newPassword,
    };
    const API_URL = `${process.env.REACT_APP_BASE_URL}/user/change-password`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: payload,
    };
    try {
      const response = await axios(config);
      if (response?.data?.data?.passwordUpdated) {
        toast.success("Password updated");
        setDetails(initialValues);
      }
    } catch (error) {
      const { response } = error;
      if(response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
        Cookies.remove("accessToken");
        navigate("/")
      }

      let message = response?.data?.errors[0]?.description.split("_");
      let updatedMessage = message.join(" ");
      toast.error(updatedMessage);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const valid = validateForm();
    if (valid) {
      handlePasswordChangeApiCall();
    }
  };

  return (
    <div className="deactivateUser"> 
      <div className="container">
        <div className="row">
          <div className="col-sm-10 col-md-8 col-lg-8 col-xl-6 mx-auto deactivateCard">
            <div className="col-md-12 d-flex justify-content-center align-items-center px-3 themeColor">
              <div className="titleWithIcon">
                <i className="fa-solid fa-user-pen transactionIcon"></i>
              </div>
              <div className="heading-text">
                <p className="mt-3 section-heading">Change Password</p>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 mt-2 mb-3">
                  <div className="user-input ">
                    <label
                      htmlFor="inputState"
                      className="form-label text-white mb-2"
                    >
                      Current Password*
                    </label>
                    <input
                      className="form-control user-input"
                      type={`${currentPassword === true ? "text" : "password"}`}
                      placeholder="Enter Current Password"
                      aria-label="first-name"
                      name="currentPassword"
                      value={details.currentPassword}
                      onChange={handleChange}
                      onBlur={onBlurHandler}
                      autoComplete="off"
                    />
                    <i
                      className={`${
                        currentPassword ? "bi bi-eye" : "bi bi-eye-slash"
                      }  toggle-password`}
                      onClick={() => setCurrentPassword(!currentPassword)}
                    />

                    <span className="errorMsg">
                      {formError.currentPassword}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-2 mb-3">
                  <div className="user-input ">
                    <label
                      htmlFor="inputState"
                      className="form-label text-white mb-2 customPopUpLabel"
                    >
                      New Password*
                    </label>
                    <input
                      className="form-control user-input"
                      name="newPassword"
                      type={`${newPassowrd === true ? "text" : "password"}`}
                      placeholder="Enter New Password"
                      aria-label="first-name"
                      autoComplete="off"
                      value={details.newPassword}
                      onChange={handleChange}
                      onBlur={onBlurHandler}
                    />
                    <i
                      className={`${
                        newPassowrd ? "bi bi-eye" : "bi bi-eye-slash"
                      }  toggle-password`}
                      onClick={() => setNewPassword(!newPassowrd)}
                    />

                    <span className="errorMsg">{formError.newPassword}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-3 mt-2">
                  <div className="user-input">
                    <label
                      htmlFor="inputState"
                      className="form-label text-white mb-2 customPopUpLabel"
                    >
                      Confirm New Password*
                    </label>
                    <input
                      className="form-control user-input"
                      name="confirmNewPassword"
                      type={`${
                        confirmNewPassword === true ? "text" : "password"
                      }`}
                      placeholder="Confirm New Password"
                      aria-label="first-name"
                      autoComplete="off"
                      value={details.confirmNewPassword}
                      onChange={handleChange}
                      onBlur={onBlurHandler}
                    />
                    <i
                      className={`${
                        confirmNewPassword ? "bi bi-eye" : "bi bi-eye-slash"
                      }  toggle-password`}
                      onClick={() => setConfirmNewPassword(!confirmNewPassword)}
                    />

                    <span className="errorMsg">
                      {formError.confirmNewPassword}
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-grid gap-2 signin-btn mt-2">
                <button className="btn btn-sign" type="submit">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
