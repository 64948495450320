import React from "react";

const Faq = () => {
  return (
    <div className="faq">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="accordion">
              <h1 className="mb-3">
               
                Frequently Asked Questions
                <span className="faqTitleIcon ms-2">
                <i className="fa-solid fa-question"></i>
              </span>{" "}
              </h1>
              <div className="accordion-item">
                <input type="checkbox" id="accordion1" />
                <label htmlFor="accordion1" className="accordion-item-title">
                  <span className="icon" />
                  How to restore username ?
                </label>
                <div className="accordion-item-desc">
                  <p>
                    1. Visit the main website and locate the "Forgot password?"
                    button located under the Sign-in and Password entry fields.
                    Please refer to the provided screenshot.
                  </p>
                  <p> 2. After clicking the button, a window will open.</p>
                  <p>
                    {" "}
                    3. Enter your account phone number and check the box
                    confirming your agreement to receive a Verification Code via
                    text message. Then, click on "Get code".
                  </p>
                  <p>
                    {" "}
                    4. Once you receive the text message with the verification
                    code, input code into the designated field and click
                    "Verify".Please refer to the provided screenshot.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <input type="checkbox" id="accordion2" />
                <label htmlFor="accordion2" className="accordion-item-title">
                  <span className="icon" />
                  How to restore password?
                </label>
                <div className="accordion-item-desc">
                  <p>
                    1. Visit the main website and locate the "Forgot Username?"
                    button located under the Sign-in and Password entry fields.
                    Please refer to the provided screenshot.
                  </p>
                  <p>
                    2. After clicking the button, a window will open, as
                    depicted in the attached screenshot.
                  </p>
                  <p>
                    3. Enter your account phone number and check the box
                    confirming your agreement to receive a Verification Code via
                    text message. Then, click on "Get code".
                  </p>
                  <p>
                    4. Once you receive the text message with the verification
                    code, input it into the designated field and click "Verify".
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <input type="checkbox" id="accordion3" />
                <label htmlFor="accordion3" className="accordion-item-title">
                  <span className="icon" />
                  Don't have access to the phone number connected to the
                  account. What should I do?
                </label>
                <div className="accordion-item-desc">
                  <p>
                    Visit the main website and click on the "Username tab"
                    located on the menu. Click on “Change your phone number”.
                    Please refer to the provided screenshot.
                  </p>
                  <p>Or</p>
                  <p>
                    {" "}
                    If you no longer have access to your account username,
                    password, and phone number, you can visit the main website
                    and click on the "Contact Us" button located on the menu.
                    This will allow you to reach out to your support team for
                    further assistance. Please refer to the provided screenshot.
                  </p>
                </div>
              </div>
              <div className="accordion-item">
                <input type="checkbox" id="accordion4" />
                <label htmlFor="accordion4" className="accordion-item-title">
                  <span className="icon" />
                  How can I become an operator?
                </label>
                <div className="accordion-item-desc">
                  Visit the main website and click on the "Operators" tab
                  located on the menu. This will allow you to fill out an
                  “Operators Application”. Please refer to the provided
                  screensho
                </div>
              </div>
              <div className="accordion-item">
                <input type="checkbox" id="accordion5" />
                <label htmlFor="accordion5" className="accordion-item-title">
                  <span className="icon" />
                  How do I get an operator?
                </label>
                <div className="accordion-item-desc">
                  Visit the main website and click on the "Operators" tab
                  located on the menu. This will direct you to “Find Operator ”.
                  Please refer to the provided screenshot.
                </div>
              </div>
              <div className="accordion-item">
                <input type="checkbox" id="accordion6" />
                <label htmlFor="accordion6" className="accordion-item-title">
                  <span className="icon" />
                  My operator does not answer. How do I play?
                </label>
                <div className="accordion-item-desc">
                  <p>
                    If an operator constantly fails to respond, we suggest
                    considering an alternative local option. Visit the main
                    website and click on the "Contact Us" button located on the
                    menu. This will allow you to reach out to your support team
                    for further assistance. Please refer to the provided
                    screenshot.
                  </p>
                  <p>
                    In case the operator doesn't answer after a deposit or
                    redeem request, please, reach out to your Support Team by
                    visiting the main website and click on the “Contact Us”
                    button and they will gladly assist you with a solution.
                    Please refer to the provided screenshot.
                  </p>
                </div>
              </div>

              <div className="accordion-item">
                <input type="checkbox" id="accordion7" />
                <label htmlFor="accordion7" className="accordion-item-title">
                  <span className="icon" />
                  How do I switch between my connected operators ?
                </label>
                <div className="accordion-item-desc">
                  Visit the main website and click on the "My Operator" tab
                  located on the menu. Click on the “My Operators ”button.
                  Please refer to the provided screenshot
                </div>
              </div>
              <div className="accordion-item">
                <input type="checkbox" id="accordion8" />
                <label htmlFor="accordion8" className="accordion-item-title">
                  <span className="icon" />
                  What deposit methods are available
                </label>
                <div className="accordion-item-desc">
                  it is important to know that game credit requests can only be
                  made directly from your operator. Each operator has specific
                  methods for disbursing game credits.
                </div>
              </div>
              <div className="accordion-item">
                <input type="checkbox" id="accordion9" />
                <label htmlFor="accordion9" className="accordion-item-title">
                  <span className="icon" />
                  Where do I find my validation code to redeem ?
                </label>
                <div className="accordion-item-desc">
                  After you've submitted a Redeem request, a window will appear
                  displaying important details such as your Validation code and
                  other details. To access your Validation Code at a later time,
                  simply visit the main website and select the "Transactions"
                  button located on the menu . On this page, you will find the
                  "Show Code" buttons under “Status”. Please refer to the
                  provided screensho
                </div>
              </div>
              <div className="accordion-item">
                <input type="checkbox" id="accordion10" />
                <label htmlFor="accordion10" className="accordion-item-title">
                  <span className="icon" />
                  What is the max win value ?
                </label>
                <div className="accordion-item-desc">
                  Visit the main website and click on the "Contact Us" button
                  located on the menu. This will allow you to reach out to your
                  support team for further assistance. Please refer to the
                  provided screenshot
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
