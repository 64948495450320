import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";

export const singUp = createAsyncThunk(
  "signup",
  async (requestData, { rejectWithValue }) => {
    const API_URL =  `${process.env.REACT_APP_BASE_URL}/user/signup`;
    try {
      const response = await axios.post(API_URL, requestData);

      if (response.status === 200) {
        swal({
          title: "Registered Successfully!",
          icon: "success",
          type: "success",
          showCancelButton: false,
          confirmButtonClass: "",
          confirmButtonText: "Ok",
          closeOnConfirm: false,
        }).then((willDelete) => {
          if (willDelete) {
            // window.location.reload();
          }
        });
      } 
      return response
    } catch (error) {
      swal({
        title: "User already exist!",
        icon: "warning",
        type: "warning",
        showCancelButton: false,
        confirmButtonClass: "",
        confirmButtonText: "Ok",
        closeOnConfirm: false,
      }).then((willDelete) => {
        if (willDelete) {
          // window.location.reload();
        }
      });
      return rejectWithValue(error);
    }
  }
);

const singUpSlice = createSlice({
  name: "singUp",
  initialState: {
    userDetails: [],
    singupApiResponse :[],
    loading: false,
    error: false
    
  },
  reducers: {
    getUserData: (state, action) => {
      state.userDetails = [...state.userDetails, action.payload];
    },
    apiResponseData: (state, action)=>{
      state.singupApiResponse = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(singUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(singUp.fulfilled, (state, action) => {
        state.loading = false;
        state.singupApiResponse = action.payload
      })
      .addCase(singUp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { getUserData,apiResponseData } = singUpSlice.actions;

export default singUpSlice.reducer;
