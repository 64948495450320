import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ShowCodePopup from "./ShowCodePopup";
import { Modal } from "react-bootstrap-v5"; 
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const TransactionPopup = ({ transaction, setTransaction }) => {
  const [data, setData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()

  const [currentPageNum, setCurrentPageNum] = useState(1)
  const [postPerPage, setPostPerPage] = useState(10)
  const [totalPost, setTotalPost] = useState(10)
  const [postFrom, setPostFrom] = useState(1)
  const [postTo, setPostTo] = useState(10)


  const handleClose = () => setTransaction(false);

  const handleShowCode = (btnId) => {
    let newData = data.map((eachTransaction) => {
      if (eachTransaction.id === btnId) {
        return { ...eachTransaction, status: "Done" };
      }
      return eachTransaction;
    });
    setData(newData);
  };
  const columns = [
    {
      name: "Transaction ID	",
      minWidth: "161px",
      selector: (row) => row?.id,
    },
    {
      name: "Date",
      selector: (row) => {
        const timestamp = row.createdAt;
        const date = new Date(timestamp);

        const formattedDate = date.toLocaleDateString();
        return formattedDate
      }
    },
    {
      name: "Time",
      selector: (row) => {
        const timestamp = row.createdAt;
        const date = new Date(timestamp);

        const formattedTime = date.toLocaleTimeString();
        return formattedTime;
      }
    },
    {
      name: "Operator ID",
      selector: (row) => row.operator_id,
    },
    {
      name: "Username",
      selector: (row) => row.user_name,
    },
    {
      name: "Action",
      selector: (row) => "Transfer to game",
    },
    {
      name: "Amount",
      selector: (row) => `${row.amount.toFixed(2)}`,
    },
    {
      name: "Status",
      button: true,
      cell: (params) =>
        params.status === "pending" ? (
          <ShowCodePopup row={params} handleShowCode={handleShowCode} />
        ) : (

          <div style={{ color: params.status === "completed" ? "green" : (params.status === "pending" ? "#bc9a6a" : "red") }}>
          {params.status}
        </div>



        ),
    },
  ];

  const tableCustomStyles = {
    headRow: {
      style: {
        color: "#fff",
        backgroundColor: "#292521",
        fontSize: "18px",
      },
    },
    rows: {
      style: {
        color: "#fff",
        backgroundColor: "#292521",
        minHeight: "70px",
        fontSize: "16px",
        whiteSpace: "unset",
      },
    },
    pagination: {
      style: {
        backgroundColor: "#292521",
        color: "white",
      },
    },
  };

  const getTransactionData = async () => {
    setIsLoading(true);
    const API_URL = `${process.env.REACT_APP_BASE_URL}/user/get-transactions?pageNo=${currentPageNum}&limit=${postPerPage}`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
    };

    try {
      const response = await axios(config); 
      setTransactionData(response?.data?.data?.transactions?.rows); 
      setTotalPost(Number(response?.data?.data?.transactions?.count));

      setIsLoading(false);
    } catch (error) {
      if(error?.response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
        Cookies.remove("accessToken");
        navigate("/")
      }
      setIsLoading(false);
      const { response } = error;
    }
  };
  useEffect(() => {
    
    
    const accessToken = Cookies.get("accessToken")
    if(accessToken){
          getTransactionData();
    }
  }, [currentPageNum, postPerPage,transaction]);

  const setNextPage = () => {
    if (currentPageNum < Math.ceil(totalPost / postPerPage)) {
      setCurrentPageNum((prev) => prev + 1);
      setPostFrom(postFrom + postPerPage);
      setPostTo((prev) => prev + postPerPage);
    }
  };
  const setPrevPage = () => {
    if (currentPageNum > 1) {
      setCurrentPageNum((prev) => prev - 1);
      setPostFrom((prev) => prev - 10);
      setPostTo((prev) => prev - 10);
    }
  };
  const handlePostPerPage = (e) => {
    const value = parseInt(e.target.value);
    setPostPerPage(value);
    setCurrentPageNum(1);
    setPostFrom(1);
    setPostTo(value);
  };

  const gotoFirstPage = () => {
    setCurrentPageNum(1);
    setPostFrom(1);
    setPostTo(10);
  };

  const gotoLastPage = () => {
    setCurrentPageNum(Math.ceil(totalPost / postPerPage));
    setPostTo(totalPost);

    let endPagePost = totalPost - postPerPage;
    let startFrom = totalPost - endPagePost;
    setPostFrom(startFrom * (Math.ceil(totalPost / postPerPage) - 1) || 1);
  };

  return (
    <>
      <Modal
        show={transaction}
        onHide={handleClose}
        size="xl"
        className="login-modal modalClosebtn"
      >
        <Modal.Header closeButton className="btn-close-white"></Modal.Header>
        <Modal.Body className="transactionNewPopup defaultPadding">
          <div className="row m-0">
            <div className="col-md-12 d-flex justify-content-center align-items-center px-3 themeColor">
              <div className="titleWithIcon">
                <i className="fa-solid fa-money-check-dollar transactionIcon"></i>
              </div>
              <div className="heading-text">
                <p className="mt-3 section-heading">Transactions</p>
              </div>
            </div>
          </div>
          <div className="data-table dataTableTextAlign customTransactionDataTableClass">
          {isLoading && (
            <div className="d-flex justify-content-center align-items-center trendingGameSpinner">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
            {!isLoading && <DataTable
              columns={columns}
              data={transactionData}
              customStyles={tableCustomStyles}
              fixedHeader
              // pagination
            />}
            {!isLoading && totalPost >0 && (
              <div className="paginationMainContainer">
                <div className="text-white rowPerPage">
                  Row Per Page :{" "}
                  <select
                    className="ms-3 ps-2 pe-2 pt-1 pb-1 paginationSelect"
                    value={postPerPage}
                    onChange={(e) => handlePostPerPage(e)}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                  </select>
                </div>

                <div className="text-white rowPerPage">
                  {postFrom}-{postTo} of {totalPost}
                </div>
                <div className="navIconContainer">
                  <span onClick={() => gotoFirstPage()}>
                    |<i className="fa-solid fa-chevron-left"></i>
                  </span>
                  <span onClick={setPrevPage}>
                    <i className="fa-solid fa-chevron-left"></i>
                  </span>
                  <span onClick={setNextPage}>
                    <i className="fa-solid fa-chevron-right"></i>
                  </span>
                  <span onClick={() => gotoLastPage()}>
                    <i className="fa-solid fa-chevron-right"></i>|
                  </span>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransactionPopup;
