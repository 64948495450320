import React, { useEffect, useState } from "react"; 
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import {  toast } from "react-toastify";
import swal from "sweetalert"; 

const Redeem = () => { 
  const [selectedPrize, setSelectedPrize] = useState([]); 
  const [itemList, setItemList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [totalItem, setTotalItem] = useState(itemList.length);

  const [isLoading, setIsLoading] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState();
  const [cartItemsCount, setCartItemsCount] = useState(0)
  const [toggle, setToggle] = useState(false) 
  const [disableAddtoCart, setDisableAddToCart] = useState(false) 

  useEffect(() => {
    setCheckedBoxes(Array(totalItem).fill(false));
  }, [totalItem]);

  const getCartListItems = async()=>{
    setIsLoading(true)
      const API_URL =  `${process.env.REACT_APP_BASE_URL}/cart?pageNo=1&limit=100`;
      const accessToken = Cookies.get("accessToken");
      const config = {
        method: "get",
        url: API_URL,
        headers: {
          Authorization: accessToken,
          type: "text",
        }, 
      };
    
      try {
        const response = await axios(config);
        setCartItemsCount(response?.data?.data?.cartItems?.count) 
        setIsLoading(false)
         
        // setTotalItems()
      } catch (error) {
        const {response} = error 
        if(response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
          Cookies.remove("accessToken");
          navigate("/")
        }
        setIsLoading(false)
      }
    }

  useEffect(()=>{
    getCartListItems()
  },[toggle])

  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const { mainWalletDetails } = useSelector((state) => state.mainWallet);
  const amount = mainWalletDetails?.amount; 


  const handleCheckboxChange = (eachItem, index) => {
    const updatedCheckedBoxes = [...checkedBoxes];
    updatedCheckedBoxes[index] = !updatedCheckedBoxes[index];
    setCheckedBoxes(updatedCheckedBoxes);
  
    if (updatedCheckedBoxes[index]) {
      // If the item is being selected, add its ID to the selectedPrize array
      setSelectedPrize((prev) => [...prev, eachItem]);
    } else {
      // If the item is being deselected, remove its ID from the selectedPrize array
      setSelectedPrize((prev) => prev.filter((id) => id !== eachItem));
    }
  } 

 
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = () => {
    const number = parseInt(inputValue);
    setInputValue("")

    if (
      Number.isInteger(number) &&
      number % 5 === 0 &&
      number <= amount // Added validation check
    ) {
      const totalCheckboxes = checkedBoxes.length;
      const checkboxesToSelect = Math.min(number / 5, totalCheckboxes);

      const randomIndexes = [];
      while (randomIndexes.length < checkboxesToSelect) {
        const randomIndex = Math.floor(Math.random() * totalCheckboxes);
        if (!randomIndexes.includes(randomIndex)) {
          randomIndexes.push(randomIndex);
        }
      }

      const updatedCheckedBoxes = Array(totalCheckboxes).fill(false);
      randomIndexes.forEach((index) => {
        updatedCheckedBoxes[index] = true;
      });

      setCheckedBoxes(updatedCheckedBoxes);
      // Log the selected items data
      const selectedItems = itemList?.filter(
        (item, index) => updatedCheckedBoxes[index]
      );
      setSelectedPrize(selectedItems);
    } else if(inputValue >mainWalletDetails?.amount){
      // Handle cases where the entered number is not valid
 

        swal({
          title: "Redeem value cannot exceed available balance.",
          icon: "warning",
          type: "warning",
          showCancelButton: false,
          confirmButtonClass: "",
          confirmButtonText: "Ok",
          closeOnConfirm: false,
        }).then((willDelete) => {
          if (willDelete) {
            window.location.reload();
          }
        });
      }
    else if(inputValue%5!==0){
      swal({
        title: "Please enter an amount in multiples of 5. Each item is worth 5 points.",
        icon: "warning",
        type: "warning",
        showCancelButton: false,
        confirmButtonClass: "",
        confirmButtonText: "Ok",
        closeOnConfirm: false,
      }).then((willDelete) => {
        if (willDelete) {
          window.location.reload();
        }
      });
    }
    
  };

  const selectedItemCount = checkedBoxes?.filter(
    (isChecked) => isChecked
  ).length || 0 ;
   
  const searchData = itemList?.filter((eachItem) =>
    eachItem?.name?.toLowerCase().includes(searchInput?.toLocaleLowerCase())
  ); 
  const handleAddToCard = async () => {  
    setDisableAddToCart(true)

    let selectedPrizeIds = selectedPrize?.map((eachItem)=> eachItem.id).join(",");   
    const payload = {
      inventoryId: selectedPrizeIds,
    };

    const API_URL = `${process.env.REACT_APP_BASE_URL}/cart/add`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: payload,
    };
    try {
      const response = await axios(config);  
      toast.success("Items added in the cart");
      setToggle(!toggle) 
      setCheckedBoxes(Array(totalItem).fill(false))  
      setSelectedPrize([]) 
    } catch (error) {
      const { response } = error; 
    }finally{
      setDisableAddToCart(false)
    }
  };

  // API Req to get the redeem Prize list data
  useEffect(() => {
    getRedeemPrizeList();
  }, []);

  const getRedeemPrizeList = async () => {
    const API_URL = `${process.env.REACT_APP_BASE_URL}/inventory?pageNo=1&limit=100`;
    const accessToken = Cookies.get("accessToken");
    setIsLoading(true);
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
    };

    try {
      const response = await axios(config); 
      setItemList(response?.data?.data?.inventories?.rows);
      setTotalItem(response?.data?.data?.inventories?.count);
      setIsLoading(false);
    } catch (error) {
      const { response } = error;
      setIsLoading(false);
    }
  };
 

  return (
    <div> 
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="topSection mb-3 mt-3">
              <div className="myPoints">My Wallet </div>
              <p className="availableBalance">Available Balance</p>
              <div className="points">
                {mainWalletDetails?.amount?.toFixed(2) ||0.0.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-xl-5 col-lg-4 col-md-4 d-flex align-items-center">
            <div className="searchContainer ps-0">
              <span>
                <i className="fa-solid fa-magnifying-glass text-light pe-2">
                  {" "}
                </i>
              </span>
              <input
                type="text"
                placeholder="Search..."
                value={searchInput} 
                onChange={(e) => setSearchInput(e.target.value.trimStart())}
              />
            </div>
          </div>
          <div className=" col-xl-7 col-lg-8 col-md-12 col-md-8">
            <div className="redeemValue row">
              <div
                className="col-lg-9 col-md-8 col-sm-12 col-8 pe-0 ps-0 mb-sm-0"
                style={{ display: "flex" }}
              >
                <input
                  type="text"
                  className="form-control valueinputredeem"
                  style={{ background: "transparent" }}
                  placeholder="Redeem points..."
                  maxLength={6}
                  value={inputValue}
                  onChange={handleInputChange}
                />
                <button
                  className="selecPrizeBtn prizeBtn ms-2"
                  onClick={handleButtonClick}
                >
                  Select My Prizes Randomly
                </button>
              </div>
              <div className="col-lg-3 col-md-4  col-4 p-0">
                <div className="d-flex align-items-center justify-content-end ">
                  <button
                    className={`selecPrizeBtn prizeBtn ${
                      selectedItemCount === 0 ? "disableAddToCart" : ""
                    } bageCard`}
                    onClick={handleAddToCard}
                    disabled={selectedItemCount === 0 || disableAddtoCart}
                  >
                    Add To Cart
                    <div className="cartBagde">{selectedItemCount}
                    </div>
                  </button>
            
                  <div
                    className="randomPrizeCount"
                    onClick={() => navigate("/cart")}
                  >
                    <span>
                      <i className="fa-solid fa-cart-shopping cartIcon"></i>
                    </span>
                    <span className="itemCount">{cartItemsCount}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isLoading === true ? (
          <div className="d-flex justify-content-center align-items-center trendingGameSpinner">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="row">
            {searchData?.map((eachItem, index) => (
              <div className="col-lg-3 col-md-4 col-sm-12" key={index}>
                <div className="prizeCard mb-4">
                  <div className="imgContainer">
                    <img
                      src={eachItem?.imageUrl}
                      className="itemImage"
                      alt="prize"
                    />
                  </div>
                  <div className="title">{eachItem?.name}</div>
                  <div className="inputContainer">
                    <input
                      type="checkbox"
                      checked={checkedBoxes[index]}
                      onChange={() => handleCheckboxChange(eachItem, index)}
                     
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Redeem;
