import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap-v5";
import Cookies from "js-cookie";
import axios from "axios";
import { io } from "socket.io-client";
import { useDispatch } from "react-redux"; 


const SOCKET_NAMESPACES = {
  PRIVATE: "/private",
};

const SOCKET_LISTENERS = {
  NOTIFICATION: "notification",
  WALLET: "wallet",
};

const SOCKET_EMITTERS = {
  USER_UNREAD_NOTIFICATION_COUNT: `${SOCKET_NAMESPACES.PRIVATE}/unreadNotification`,
};

const socketInit = () => {
  const API_BASE_URL = process.env.REACT_APP_BASE_URL; // Base URL from environment variables
  // Initialize socket connection
  const accessToken = Cookies.get("accessToken");
  const socket = io("https://api.luckyreels.net" + SOCKET_NAMESPACES.PRIVATE, {
    transports: ["websocket"],
    auth: {
      accessToken: accessToken,
    },
    path: "/api/socket",
    timeout: 1000,
  });
  return socket;
};

const NotificationsPopup = ({ notifications, setNotifications }) => {
  const handleClose = () => setNotifications(false);
  const [notificationsData, setNotificationsData] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [currentPageNum, setCurrentPageNum] = useState(1)
  const [postPerPage, setPostPerPage] = useState(10)
  const [totalPost, setTotalPost] = useState(10)
  const [postFrom, setPostFrom] = useState(1)
  const [postTo, setPostTo] = useState(10)
  const dispatch = useDispatch()

  const columns = [
    {
      name: "Message", 
      selector: (row) => row.message,
      style: {minWidth:"600px"}
    },
    {
      name: "Date", 
      selector: (row) => {
        const timestamp = row.created_at;
        const date = new Date(timestamp);
        
        const formattedDate = date.toLocaleDateString();
        return formattedDate
       
      },
      style: {minWidth:"100px"}
    },
    {
      name: "Time",
      selector: (row) => {
        const timestamp = row.created_at;
        const date = new Date(timestamp);
        

        const formattedTime = date.toLocaleTimeString();
        return formattedTime
      },
      style: {minWidth:"100px"}
    },
    {
      name: "Operator",
    
      selector: (row) => row?.adminUser?.username,
      style: {  minWidth: "100px",}
    },
  ];

  const tableCustomStyles = {
    headRow: {
      style: {
        color: "#fff",
        backgroundColor: "#292521",
        fontSize: "18px",
      },
    },
    rows: {
      style: {
        color: "#fff",
        backgroundColor: "#292521",
        minHeight: "70px",
        fontSize: "16px",
        whiteSpace: "unset",
      },
    },
    pagination: {
      style: {
        backgroundColor: "#292521",
        color: "white",
      },
    },
  };

  const getNotificationsData = async () => {
    setIsLoading(true);
    const API_URL = `${process.env.REACT_APP_BASE_URL}/notifications?limit=${postPerPage}&pageNo=${currentPageNum}`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
    };

    try {
      const response = await axios(config);
      setNotificationsData(response?.data?.data?.notifications?.rows);
      setTotalPost(response?.data?.data?.notifications?.count)

      setIsLoading(false);
    } catch (error) {

      setIsLoading(false);
      const { response } = error;
      if(response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
        Cookies.remove("accessToken");
      }
    }
  };
 
  useEffect(() => {
    
    const accessToken = Cookies.get("accessToken")
    if(accessToken){

      getNotificationsData();
    }
  }, [currentPageNum,postPerPage]);
  
  const setNextPage = ()=>{
    if(currentPageNum < Math.ceil(totalPost/postPerPage)){
      setCurrentPageNum(prev => prev+1)
      setPostFrom(postFrom+postPerPage)
      setPostTo(postPerPage+10)
    }
  }
  const setPrevPage = ()=>{
    if(currentPageNum>1){
      setCurrentPageNum(prev => prev-1)
      setPostFrom(prev=> prev-10)
      setPostTo(prev=> prev-10)
      
     
    }
  }
  const handlePostPerPage = (e)=>{
    setPostPerPage(e.target.value)
    setPostFrom(1)
    setPostTo(e.target.value)
    setCurrentPageNum(1)
  }

const gotoFirstPage = ()=>{
setCurrentPageNum(1)
setPostFrom(1)
setPostTo(10)
}

const gotoLastPage = ()=>{
  setCurrentPageNum(Math.ceil(totalPost/postPerPage))
  setPostTo(totalPost)


let endPagePost = totalPost - postPerPage 
let startFrom = totalPost - endPagePost 
setPostFrom(startFrom*(Math.ceil(totalPost/postPerPage)-1) || 1)
}


  return (
    <>
      {" "}
      <Modal
        show={notifications}
        onHide={handleClose}
        size="xl"
        className="login-modal modalClosebtn"
      >
        <Modal.Header closeButton className="btn-close-white"></Modal.Header>
        <Modal.Body className="lastActivityPopup notificationsPopup">
          <div className="row m-0">
            <div className="col-md-12 col-lg-12 ps-2 pe-2 lastActivityTable tableBgClr ">
              <div className="heading-text tableBgClr text-center">
                <div className="d-flex justify-content-center align-items-center mb-3">
                  <div className="titleWithIcon">
                    <i className="fa-solid fa-bell transactionIcon"></i>
                  </div>
                  <p className=" section-heading customPopupsHeading transactionTitle">
                    Notifications
                  </p>
                </div>
              </div>
            </div>
            <div className="data-table dataTableTextAlign ">
            {
              isLoading && <div className="d-flex justify-content-center align-items-center trendingGameSpinner">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            }
            {!isLoading && <DataTable
              columns={columns}
              data={notificationsData}
              customStyles={tableCustomStyles}
              fixedHeader
            />
            }
            {!isLoading && <div className="paginationMainContainer">
            <div className="text-white rowPerPage">
              Row Per Page :{" "}
              <select className="ms-3 ps-2 pe-2 pt-1 pb-1 paginationSelect" value={postPerPage} onChange={(e)=>handlePostPerPage(e)}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
              </select>
              
            </div>
            <div className="text-white rowPerPage">{postFrom}-{Math.min(totalPost, postTo)} of {totalPost}</div>
            <div className="navIconContainer">
              <span onClick={()=>gotoFirstPage()}>
                |<i className="fa-solid fa-chevron-left"></i>
              </span>
              <span onClick={setPrevPage}>
                <i className="fa-solid fa-chevron-left"></i>
              </span>
              <span onClick={setNextPage}>
                <i className="fa-solid fa-chevron-right"></i>
              </span>
              <span onClick={()=>gotoLastPage()}>
                <i className="fa-solid fa-chevron-right"></i>|
              </span>
            </div>
          </div>}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NotificationsPopup;
