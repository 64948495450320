import React, { useState } from "react";

const DeleteOperatorPopup = ({
  deleteOperator,
  showDeltepopup,
  setDeletepopup,
  deleteOpertorId,
}) => {
  const [isShow, setIsShow] = useState(true);

  const handleDelteOperator = (operatorsId) => {
    deleteOperator(operatorsId);
    setDeletepopup(false);
  };

  return (
    <>
      <div
        className={`modal fade ${
          showDeltepopup ? "show d-block modal-hide" : ""
        }`}
        id="operatorModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen-lg-down modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content modalCustomHeight">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setDeletepopup(false)}
              />
            </div>
            <div className="modal-body d-flex mx-auto align-items-center text-white p-0">
              <div className="row m-0">
                <div className="col-12">
                  <div className="deleteOperator termsConditon">
                    {" "}
                    Deleting the operator from your list of operators will
                    prevent you from adding him in the future for 10 days
                  </div>
                </div>
                <div className="row m-0">
                  <div className="col-12 text-center">
                    <div className="deleteOperator termsConditon p-4">
                      <span className="deleteOprAlert">Important!</span> All
                      remaing credits under deleted operator will be lost
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <div className="p-4">
                      <button
                        className="btn btn-secondary me-3"
                        onClick={() => setDeletepopup(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelteOperator(deleteOpertorId)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteOperatorPopup;
