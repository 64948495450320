import ReactFlagsSelect from "react-flags-select";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalPanel from "..";
import { modalKey } from "../../../redux/counterSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useSelector } from "react-redux";
import {setShowSingupPop} from "../../../redux/counterSlice"

const Signup = ({setStep, }) => {
  
      const dispatch = useDispatch();
      const [show, setShow] = useState(false); 
      const {showModal} = useSelector((state)=> state.counter)
      const handleClose = () => dispatch(setShowSingupPop(false));
      const handleShow = () => dispatch(setShowSingupPop(true));
      const {showSingupPopup} = useSelector((state)=> state.counter)
      return (
        <>
          <Button
            variant=""
            className="btn header-btn sign text-nowrap headerBtn updatedTextColor ipadHeaderBtn"
            onClick={() => {
              handleShow();
              dispatch(modalKey("profile")); 
            }}
          >
            Join Now
          </Button>
         {showModal && <Modal
            backdrop="static"
            show={showSingupPopup}
            onHide={handleClose}
            size="xl"
            className="login-modal d-flex align-items-center justify-content-center"
          >
            <Modal.Header closeButton className="btn-close-white"></Modal.Header>
            <Modal.Body>
              <ModalPanel setStep={setStep} />
            </Modal.Body>
          </Modal>}


        </>
      );
};
export default Signup;
