import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  toast } from "react-toastify";
import DeactivateProfileAlert from "../UserProfilePopUps/DeactivateProfileAlert";
import { showDeactivateAlert } from "../../redux/counterSlice";
import { useLocation, useNavigate } from "react-router-dom";
const Deativate = () => {
  const initialValues = {
    password: "",
  };
  const [details, setDetails] = useState(initialValues);
  const [formError, setFormError] = useState({ initialValues });
  const [password, setPassword] = useState(false);
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.userDetails);
  const navigate = useNavigate()  
  const onBlurHandler = (event) => {
    const fieldName = event.target.name;
    const fieldValue = details[fieldName];
    let err = { ...formError };
    if (fieldValue === "") {
      err[fieldName] = "Required!";
    } else {
      delete err[fieldName];
    }
    setFormError({ ...err });
  };

  const validateForm = () => {
    let err = {};
    if (details.password === "") {
      err.password = "Required!";
    }
    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };
  const handleChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    let err = { ...formError };
    delete err[fieldName];
    setFormError({ ...err });

    if (fieldName === "password") {
      if (fieldValue.length > 16) {
        return;
      }
    }
    setDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const deactivateProfileRequest = async () => {
    const payload = {
      phoneCode: userDetails?.phoneCode,
      phone: userDetails?.phone,
      password: details.password,
    };
    const API_URL = `${process.env.REACT_APP_BASE_URL}/user/active-deactive-user`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "post",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
      data: payload,
    };
    try {
      const response = await axios(config);
      if(userDetails?.isActive=== true){
        toast.success("Profle Deactivated");
        Cookies.remove("accessToken");
        window.location.reload();
      }
      else if(userDetails?.isActive=== false){
        toast.success("Profle Activated"); 
        setTimeout(()=>{
          navigate("/")
          // window.location.reload()
        },400)
      }
       
      
    } catch (error) {
      const { response } = error;
      if(response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
        Cookies.remove("accessToken");
        navigate("/")
      }
      let message = response?.data?.errors[0]?.description.split("_").join(" ");
      toast.error(message);
    }
  };

  const handleSubmit = () => {
    deactivateProfileRequest();
    setDetails(initialValues)


  };

  const handleShowAlert = () => {

    const valid = validateForm();
    if (valid && userDetails?.isActive === true) {
      dispatch(showDeactivateAlert(true));
    }
    else if(valid && userDetails?.isActive === false){
      deactivateProfileRequest(userDetails?.isActive);
    }
  };
  
  

  return (
    <div className="deactivateUser"> 
      <div className="container">
        <div className="row">
          <div className="col-sm-10 col-md-8 col-lg-8 col-xl-6 mx-auto deactivateCard">
            <div className="col-md-12 d-flex justify-content-center align-items-center px-3 themeColor">
              <div className="titleWithIcon">
              {userDetails?.isActive === true ?<i className="fa-solid fa-user-slash transactionIcon"></i>: <i className="fa-solid fa-user transactionIcon"></i> }
                
              </div>
              <div className="heading-text">
                <p className="mt-3 section-heading">{userDetails?.isActive === true ?  "Deactivate Profile" : "Activate Profile"}</p>
              </div>
            </div>
            <form>
              <div className="row">
                <div className="col-md-12 ">
                  <div className="user-input mt-2">
                    <input
                      className="form-control user-input"
                      name="password"
                      type={`${password === true ? "text" : "password"}`}
                      placeholder="Enter Password"
                      aria-label="first-name"
                      value={details.password}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={onBlurHandler}
                    />
                    <i
                      className={`${
                        password ? "bi bi-eye" : "bi bi-eye-slash"
                      }  toggle-password`}
                      onClick={() => setPassword(!password)}
                    />
                  </div>
                  <span className="errorMsg">{formError.password}</span>
                </div>
              </div>
              <div className="d-grid gap-2 signin-btn">
                <button
                  className="btn btn-sign"
                  type="button"
                  onClick={handleShowAlert}
                >
                {userDetails?.isActive === true ?  "Deactivate" : "Activate"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <DeactivateProfileAlert handleSubmit={handleSubmit} />
    </div>
  );
};

export default Deativate;
