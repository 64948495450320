import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import axios from "axios";
import { getMainWalletDetails } from "../../redux/mainWalletSlice";
import {setShowGameCreditModal} from '../../redux/counterSlice' 

const GameCredit = ({handleLaunchPrizeGame}) => {
    const [modalShow, setModalShow] = useState(false);
    const [toggle, setToggle] = useState(false);
    const { mainWalletDetails } = useSelector((state) => state.mainWallet);
    const dispatch = useDispatch();
    const {showGameCreditPopUp} = useSelector((state)=> state.counter)

    // Fetch main wallet details whenever toggle changes
    
    useEffect(() => {
        const accessToken = Cookies.get("accessToken") 
        if (accessToken) {
        
            dispatch(getMainWalletDetails());
        }
    }, [toggle, dispatch]);

    const handleShow = () => setModalShow(true);
    const handleClose = () => {
      dispatch(setShowGameCreditModal(false))
    };


    const collectAllCredit = async () => {
        const API_URL = `${process.env.REACT_APP_BASE_URL}/user/collect-amount`;
        const accessToken = Cookies.get("accessToken");
        const config = {
            method: "get",
            url: API_URL,
            headers: {
                Authorization: accessToken,
                type: "text",
            },
        };

        try {
            const response = await axios(config);
            setToggle(!toggle); // Trigger a state change
        } catch (error) {
            console.error("Failed to collect all credits:", error);
            // Consider adding user-facing error handling here
        }
    };

    const collectIndividualCredit = async (walletId) => {
        const API_URL = `${process.env.REACT_APP_BASE_URL}/user/collect-amount?gameWalletId=${walletId}`;
        const accessToken = Cookies.get("accessToken");
        const config = {
            method: "get",
            url: API_URL,
            headers: {
                Authorization: accessToken,
                type: "text",
            },
        };

        try {
            const response = await axios(config);
            setToggle(!toggle); // Trigger a state change
        } catch (error) {
            console.error("Failed to collect individual credit:", error);
            // Consider adding user-facing error handling here
        }
    };


    const handleGameLaunch = (gameId)=>{ 
        handleLaunchPrizeGame(gameId)
      dispatch(setShowGameCreditModal(false))
    }


return (
        <>
            {/* Button to trigger the modal */}
          {/*  <Button variant="" onClick={handleShow} className="btn header-btn-gameCredit text-nowrap mx-lg-2">
                Game Credit : {mainWalletDetails?.gameCredits?.toFixed(2) || (0.0).toFixed(2)}
</Button>*/}

            {/* Modal content integrated into GameCredit */}
            <Modal
                show={showGameCreditPopUp}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="btn-close-white"></Modal.Header>
                <Modal.Body className="customCollectCredit">
                    <div className="d-flex justify-content-center">
                        {mainWalletDetails?.gameWallets?.length === 0 ? (
                            <h2 className="text-white">You have collected all credit</h2>
                        ) : (
                            <button className="btn playBtn btn1_bg" onClick={collectAllCredit}>
                                Collect All
                            </button>
                        )}
                    </div>
                    {mainWalletDetails?.gameWallets?.map((eachGame) => (
                        <div className="mainContainer" key={eachGame.id}>
                            <div className="nameAndAmount">
                                <div className="gameName">{eachGame?.casinoGame?.name}</div>
                                <div className="creditAmount">{eachGame?.amount?.toFixed(2)}</div>
                            </div>
                            <div className="creditBtnContainer">
                                <Button variant="primary" className="playBtn btn1_bg playCreditBtn"  onClick={()=>handleGameLaunch(eachGame?.gameId)}>
                                    Play
                                </Button>
                                <Button
                                    variant="secondary"
                                    className="playBtn btn2_bg"
                                    onClick={() => collectIndividualCredit(eachGame?.id)}
                                >
                                    Collect
                                </Button>
                            </div>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default GameCredit;