import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Notification from "../assets/images/icons/notificationsicon.png";
import axios from "axios";
import Cookies from "js-cookie";
import { io } from "socket.io-client";
import { useNavigate } from "react-router-dom"; 
import { useDispatch } from "react-redux";
import { setNotificationsCount } from "../redux/notificationsCountSlice";

const SOCKET_NAMESPACES = {
  PRIVATE: "/private",
};

const SOCKET_LISTENERS = {
  NOTIFICATION: "notification",
  WALLET: "wallet",
};

const SOCKET_EMITTERS = {
  USER_UNREAD_NOTIFICATION_COUNT: `${SOCKET_NAMESPACES.PRIVATE}/unreadNotification`,
};

const socketInit = () => {
  const API_BASE_URL = process.env.REACT_APP_BASE_URL; // Base URL from environment variables
  // Initialize socket connection 
 

  const accessToken = Cookies.get("accessToken");
  let result = API_BASE_URL.includes("dev-api")?"https://dev-api.luckyreels.net":"https://api.luckyreels.net" 

  const socket = io(result + SOCKET_NAMESPACES.PRIVATE, {
    transports: ["websocket"],
    autoConnect: false,
    auth: {
      accessToken: accessToken,
    },
    path: "/api/socket",
    timeout: 1000,
  });
  return socket;
};

const Notifications = () => {
  const [notificationsData, setNotificationsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  const [totalPost, setTotalPost] = useState(10);
  const [postFrom, setPostFrom] = useState(1);
  const [postTo, setPostTo] = useState(10);
  const navigate = useNavigate()
  const [newNotifications, setNewNotifications]=useState(0)
const dispatch = useDispatch()
  useEffect(() => {
    const socket = socketInit(); 
    socket.emit(SOCKET_EMITTERS.USER_UNREAD_NOTIFICATION_COUNT, {}, (data) =>{  
      setNewNotifications(data?.data?.notificationsCount)  
    }
      ); 

    socket.on(SOCKET_LISTENERS.WALLET, (data) => console.log(""));
    dispatch(setNotificationsCount(0))

    return () => {
    
      dispatch(setNotificationsCount(0))
    };
  },[]);


  const getNotificationsData = async () => {
    setIsLoading(true);
    const API_URL = `${process.env.REACT_APP_BASE_URL}/notifications?limit=${postPerPage}&pageNo=${currentPageNum}`;
    const accessToken = Cookies.get("accessToken");
    const config = {
      method: "get",
      url: API_URL,
      headers: {
        Authorization: accessToken,
        type: "text",
      },
    };

    try {
      const response = await axios(config);
      setNotificationsData(response?.data?.data?.notifications?.rows);
      setTotalPost(response?.data?.data?.notifications?.count);

      setIsLoading(false);
    } catch (error) {
      if(error?.response?.data?.errors[0]?.description==="Either access token not passed or it is expired"){
        Cookies.remove("accessToken");
        navigate("/")
      }


      setIsLoading(false);
      const { response } = error;
    }
  };

  useEffect(() => {
    const accessToken = Cookies.get("accessToken");
    if (accessToken) {
      getNotificationsData();
    }
  }, [currentPageNum, postPerPage,newNotifications]);

  // Columns definition for the DataTable
  const columns = [
    {
      name: "Message",
      minWidth: "600px",
      selector: (row) => row.message,
    },
    {
      name: "Date",
      minWidth: "100px",
      selector: (row) => {
        const timestamp = row.created_at;
        const date = new Date(timestamp);
        return date.toLocaleDateString();
      },
    },
    {
      name: "Time",
      minWidth: "100px",
      selector: (row) => {
        const timestamp = row.created_at;
        const date = new Date(timestamp);
        return date.toLocaleTimeString();
      },
    },
    {
      name: "Operator",
      minWidth: "100px",
      selector: (row) => row?.adminUser?.username,
    },
  ];

  // Custom styles for DataTable
  const tableCustomStyles = {
    headRow: {
      style: {
        color: "#fff",
        backgroundColor: "#292521",
        fontSize: "18px",
      },
    },
    rows: {
      style: {
        color: "#fff",
        backgroundColor: "#292521",
        minHeight: "60px",
        fontSize: "16px",
        whiteSpace: "unset",
      },
    },
    pagination: {
      style: {
        backgroundColor: "#292521",
        color: "white",
      },
    },
  };

  // Pagination functions

  const setNextPage = () => {
    if (currentPageNum < Math.ceil(totalPost / postPerPage)) {
      setCurrentPageNum((prev) => prev + 1);
      setPostFrom(postFrom + postPerPage);
      setPostTo(Math.min(postTo + postPerPage, totalPost));
    }
  };

  const setPrevPage = () => {
    if (currentPageNum > 1) {
      setCurrentPageNum((prev) => prev - 1);
      setPostFrom(Math.max(postFrom - postPerPage, 1));
      setPostTo(postFrom - postPerPage);
    }
  };

  const handlePostPerPage = (e) => {
    const value = parseInt(e.target.value);
    setPostPerPage(value);
    setCurrentPageNum(1);
    setPostFrom(1);
    setPostTo(value);
  };

  const gotoFirstPage = () => {
    setCurrentPageNum(1);
    setPostFrom(1);
    setPostTo(postPerPage);
  };

  const gotoLastPage = () => {
    const lastPageNum = Math.ceil(totalPost / postPerPage);
    setCurrentPageNum(lastPageNum);
    setPostTo(totalPost);
    setPostFrom(totalPost - postPerPage * (lastPageNum - 1));
  };

  return (
    <div className="container p-0">
      <div className="row mt-5 m-0 p-0 notificationsPage">
        <div className="col-11 col-sm-12 col-lg-12 notification notifications-bg mx-auto">
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center align-items-center px-3 themeColor">
              <div className="icon">
                <img src={Notification} className="mt-1" alt="find-operator" />
              </div>
              <div className="heading-text">
                <p className="mt-3 section-heading">Notifications</p>
              </div>
            </div>
          </div>

          <div className="data-table notification">
            {isLoading && (
              <div className="d-flex justify-content-center align-items-center trendingGameSpinner">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {!isLoading && (
              <DataTable
                columns={columns}
                data={notificationsData}
                customStyles={tableCustomStyles}
                fixedHeader
              />
            )}
            {!isLoading && totalPost >0 && (
              <div className="paginationMainContainer">
                <div className="text-white rowPerPage">
                  Row Per Page:
                  <select
                    className="ms-3 ps-2 pe-2 pt-1 pb-1 paginationSelect"
                    value={postPerPage}
                    onChange={handlePostPerPage}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                  </select>
                </div>
                <div className="text-white rowPerPage">
                  {postFrom}-{Math.min(totalPost, postTo)} of {totalPost}
                </div>
                <div className="navIconContainer">
                  <span onClick={gotoFirstPage}>
                    |<i className="fa-solid fa-chevron-left"></i>
                  </span>
                  <span onClick={setPrevPage}>
                    <i className="fa-solid fa-chevron-left"></i>
                  </span>
                  <span onClick={setNextPage}>
                    <i className="fa-solid fa-chevron-right"></i>
                  </span>
                  <span onClick={gotoLastPage}>
                    <i className="fa-solid fa-chevron-right"></i>|
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
